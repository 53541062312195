import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Typography as MuiTypography,
} from "@mui/material/";
import { useTranslation } from "react-i18next";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { StyleApp } from "app/shared/ui/ui.styles";

export const InfoDialog = ({ params, setParams }) => {
  const { t } = useTranslation();

  const handleClose = () => {
    setParams({ ...params, show: false });
  };

  return (
    <MuiDialog
      open={params.show}
      onClose={() => setParams({ ...params, show: false })}
      scroll={"paper"}
      aria-labelledby="info-dialog-title"
      aria-describedby="info-dialog-description"
      fullWidth={true}
    >
      <MuiDialogTitle
        id="info-dialog-title"
        style={StyleApp.links}
        sx={{ fontWeight: 500, fontSize: "18.75px" }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: sanitize(
              t("forms.infoDialog.title", { fieldName: params.fieldLabel }),
              {
                ALLOWED_TAGS: AllowedTags,
              }
            ),
          }}
        />
      </MuiDialogTitle>
      <MuiDialogContent dividers={true}>
        <MuiTypography variant="body2" sx={StyleApp.links}>
          <span
            dangerouslySetInnerHTML={{
              __html: sanitize(params.fieldContent, {
                ALLOWED_TAGS: AllowedTags,
              }),
            }}
          />
        </MuiTypography>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton
          onClick={handleClose}
          color="primary"
          sx={{ fontSize: "17px" }}
        >
          {t("forms.infoDialog.button.close")}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};
