import { useState, useEffect } from "react";
import {
  Typography as MuiTypography,
  Grid as MuiGrid,
  Box as MuiBox,
  Link as MuiLink,
} from "@mui/material";
import { ErrorOutline as ErrorOutlineIcon } from "@mui/icons-material";
import { getString } from "app/shared/utils";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";

const align = {
  verticalAlign: "middle",
  "& a": {
    textDecoration: "none",
    color: "#007c92",
    cursor: "pointer",
  },
};

export const ErrorList = (props) => {
  return <Errors {...props} classes={align} formFlatMap={props.formFlatMap} />;
};

const Errors = ({
  label,
  errors,
  labelMap,
  classes,
  formFlatMap,
  formspec,
}) => {
  const [targetField, setTargetField] = useState();
  useEffect(() => {
    if (targetField) {
      focusToField(targetField);
    }
    return () => {
      setTargetField();
    };
  }, [targetField]);

  const list = [];
  for (const [key] of Object.entries(formFlatMap)) {
    if (errors[key]) {
      list.push({
        fullText: errors[key],
        fieldName: labelMap[key],
        key,
        id: key,
      });
    }
  }
  return (
    !list.length || (
      <MuiGrid item xs={12}>
        <MuiBox mt={3}>
          <MuiBox mb={2}>
            <ErrorOutlineIcon
              sx={{ fontSize: "22.5px", verticalAlign: "middle" }}
              color="error"
            />
            <MuiTypography
              variant="body2"
              component="span"
              sx={align}
              gutterBottom
              dangerouslySetInnerHTML={{
                __html: sanitize(label, {
                  ALLOWED_TAGS: AllowedTags,
                }),
              }}
            />
          </MuiBox>
          <MuiGrid container direction="column">
            {list.map((item, index) => (
              <MuiTypography variant="body2" key={index}>
                <MuiLink
                  underline="hover"
                  component="span"
                  onClick={() => setTargetField(item.id)}
                  sx={{ cursor: "pointer" }}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitize(
                        getFieldLabel(
                          item.fieldName,
                          formFlatMap,
                          {
                            formSpec: formspec,
                          },
                          ""
                        ),
                        {
                          ALLOWED_TAGS: AllowedTags,
                        }
                      ).replace(
                        /<a\b[^>]*>(.*?)<\/a>/gi,
                        '<a style="color: #007c92; cursor: pointer">$1</a>'
                      ),
                    }}
                  />
                </MuiLink>
                <MuiTypography variant="body2" component="span" sx={align}>
                  {` ${item.fullText
                    .replace(item.key, "")
                    .replace(item.fieldName, "")}`}
                </MuiTypography>
              </MuiTypography>
            ))}
          </MuiGrid>
        </MuiBox>
      </MuiGrid>
    )
  );
};

const focusToField = (id) => {
  try {
    const field = document.querySelector(`#${id}`);
    window.location.href = `#field-${id}`;
    field?.focus();
    field?.blur();
    field?.focus();
    field?.select();
  } catch (err) {
    console.log("error list", err);
  }
};

const getFieldLabel = (text, fields, otherValues, wrapValuesWith) => {
  const matches = text?.match(/\$\{.+?\}/g) || [];
  return matches.reduce((prevText, match) => {
    const key = match.slice(2, -1);
    return prevText.replace(
      match,
      getString(fields, key, otherValues, wrapValuesWith).replace(/\$/g, "$$$$")
    );
  }, text);
};
