import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableBody as MuiTableBody,
} from "@mui/material";
import { FormSummaryRow } from "app/forms/FormSummaryRow";

export const FormSummary = (props) => {
  return (
    <MuiTableContainer>
      <MuiTable aria-label="form summary">
        <MuiTableBody>
          <FormSummaryRow {...props} />
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};
