import { Section } from "app/shared/ui";
import {
  TableContainer as MuiTableContainer,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
  Box as MuiBox,
  Grid as MuiGrid,
  Tooltip as MuiTooltip,
} from "@mui/material";
import isJSON from "is-json";
import { FormStatus } from "app/shared/constants";
import { FormSummary } from "app/forms/FormSummary";
import { History as HistoryIcon } from "@mui/icons-material";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";

const StyleApp = {
  summaryRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
  summaryCell: {
    border: 0,
  },
  summaryLink: {
    "& a": {
      textDecoration: "none",
      color: "#007c92",
      cursor: "pointer",
    },
  },
};

export const FormSummaryView = ({ summaryList, ...rest }) => {
  const { getFieldObject, allFields, t, setScrollDialog } = rest;

  return (
    <MuiTableContainer sx={{ marginLeft: "8px", marginRight: "-8px" }}>
      <MuiTable aria-label="form summary">
        <MuiTableBody>
          {summaryList.map((item, index) => {
            const iconSize = Boolean(item?.updated) ? "large" : "small";
            const iconColor = Boolean(item?.updated) ? "error" : "inherit";
            const links = Boolean(item?._links?.self?.href)
              ? item?._links?.self?.href
              : "";
            const attributes =
              item.field_attributes && isJSON(item.field_attributes)
                ? JSON.parse(item.field_attributes)
                : null;
            const fieldType = attributes?.field_type;
            const fieldGroup = attributes?.field_group;
            const showEmptyFields = attributes?.showEmptyFields;

            if (fieldType === "section") {
              return (
                <MuiBox key={index} component="tr">
                  <MuiGrid component="td">
                    <MuiBox mt={2} mb={2} sx={{ width: "calc(100% + 7px)" }}>
                      <MuiGrid item xs={12}>
                        <Section label={item.field_label} {...rest} />
                      </MuiGrid>
                    </MuiBox>
                  </MuiGrid>
                </MuiBox>
              );
            }

            if (Array.isArray(item.field_value) && Array.isArray(fieldGroup)) {
              const fields = fieldGroup.map((item) =>
                getFieldObject(item, allFields)
              );
              return item.field_value.map((fieldItem, index) => (
                <MuiTableRow key={index}>
                  <MuiTableCell width="288" colSpan="2">
                    <MuiBox sx={{ border: "1px solid grey" }} aria-colspan="2">
                      <FormSummary
                        fields={fields}
                        formValues={{
                          ...fieldItem.values,
                          action_id: fieldItem.id,
                          action_status: t(FormStatus[fieldItem.status]),
                        }}
                        formFields={allFields}
                        showEmptyFields={showEmptyFields}
                      />
                    </MuiBox>
                  </MuiTableCell>
                </MuiTableRow>
              ));
            }

            return item.field_value ? (
              <MuiTableRow key={index} sx={StyleApp.summaryRow}>
                <MuiTableCell width="288" sx={StyleApp.summaryCell}>
                  <MuiTypography variant="body2" sx={StyleApp.summaryLink}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: sanitize(item.field_label, {
                          ALLOWED_TAGS: AllowedTags,
                        }),
                      }}
                    />
                  </MuiTypography>
                </MuiTableCell>
                <MuiTableCell sx={StyleApp.summaryCell}>
                  <MuiTypography
                    variant="body1"
                    sx={{ lineHeight: "25.5px", fontWeight: 600 }}
                    whiteSpace="pre-line"
                  >
                    {getFormattedValue(item.field_value, fieldType)}
                  </MuiTypography>
                </MuiTableCell>
                <MuiTableCell sx={StyleApp.summaryCell}>
                  <MuiTypography variant="body1">
                    {Boolean(links) ? (
                      <MuiTooltip title={t("globals.fieldHistory.tooltip")}>
                        <HistoryIcon
                          fontSize={iconSize}
                          color={iconColor}
                          onClick={() =>
                            setScrollDialog({
                              show: true,
                              fieldLabel: item.field_label,
                              links: links,
                              fieldType,
                            })
                          }
                          sx={{
                            marginLeft: "5px",
                            cursor: "pointer",
                          }}
                        />
                      </MuiTooltip>
                    ) : (
                      <span style={{ width: "1.5em" }}></span>
                    )}
                  </MuiTypography>
                </MuiTableCell>
              </MuiTableRow>
            ) : null;
          })}
        </MuiTableBody>
      </MuiTable>
    </MuiTableContainer>
  );
};

const getFormattedValue = (inputValue, fieldType) => {
  if (fieldType === "percent") return `${parseFloat(inputValue)}%`;
  else if (fieldType === "money") {
    return `${Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(String(inputValue))
      .replace(/(\.|,)00$/g, "")}`;
  }
  return inputValue;
};
