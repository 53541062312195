import { useEffect, useState } from "react";
import {
  Button as MuiButton,
  TextField as MuiTextField,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Backdrop as MuiBackdrop,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  paperFullWidth,
  backdrop,
  title,
} from "app/file-attachments/RenameAttachment.styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { updateFileAttachment } from "app/file-attachments/fileAttachmentsService";

export const RenameFileAttachment = (props) => {
  const {
    open: showDialog,
    setShowDialog,
    fileType,
    fileName,
    actionId,
    documentId,
    clearAlert,
    setAlert,
    setRefresh,
  } = props;
  const { t } = useTranslation();
  const [, setOpen] = useState(showDialog);
  const [documentName, setDocumentName] = useState(fileName);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setShowDialog({ fileType, fileName, open: false });
  };

  useEffect(() => {
    setDocumentName(getFileName(fileName));
  }, [fileName, showDialog]);

  const formData = {
    filename: documentName,
  };

  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validationSchema={renameFileAttachmentsValidation}
    >
      {({ setFieldValue, isSubmitting, isValid }) => {
        return (
          <form>
            <MuiDialog
              open={showDialog}
              onClose={handleClose}
              sx={paperFullWidth}
            >
              <MuiDialogTitle sx={title}>
                {t("fileAttachments.renameDialog.title", { fileType })}
              </MuiDialogTitle>
              <MuiDialogContent sx={{ padding: "8px 24px" }}>
                {loading ? (
                  <MuiBackdrop open={loading} sx={backdrop} invisible={true}>
                    <MuiCircularProgress />
                  </MuiBackdrop>
                ) : (
                  <MuiTextField
                    autoFocus
                    margin="dense"
                    name="filename"
                    label="Filename"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={documentName}
                    onChange={(event) => {
                      setDocumentName(event.target.value);
                      setFieldValue("filename", event.target.value);
                    }}
                    sx={{
                      marginTop: "13px",
                      "& label, .MuiInputBase-root": { fontSize: "15px" },
                    }}
                  />
                )}
              </MuiDialogContent>
              <MuiDialogActions>
                <MuiButton
                  onClick={handleClose}
                  color="primary"
                  sx={{ fontSize: "17px" }}
                >
                  {t("fileAttachments.renameDialog.actionButtons.cancel.label")}
                </MuiButton>
                <MuiButton
                  color="primary"
                  onClick={() => {
                    updateFileAttachment({
                      actionId,
                      documentId,
                      dataToUpdate: {
                        fileName: documentName,
                      },
                      setLoading,
                      clearAlert,
                      setAlert,
                      handleClose,
                      setRefresh,
                      t,
                      oldFileName: fileName,
                    });
                  }}
                  disabled={!isValid || isSubmitting}
                  sx={{ fontSize: "17px" }}
                >
                  {t("fileAttachments.renameDialog.actionButtons.rename.label")}
                </MuiButton>
              </MuiDialogActions>
            </MuiDialog>
          </form>
        );
      }}
    </Formik>
  );
};

const renameFileAttachmentsValidation = Yup.object().shape({
  filename: Yup.string().required(),
});

const getFileName = (filename) => {
  if (!filename) return "";
  // Splitting out the name with `.`
  const nameString = filename.split(".");
  // Popping out the last element which would be file extension
  nameString.pop();
  // joining with `.` and setting it to state\
  return nameString.join(".");
};
