import { useState, useEffect } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import {
  DatePicker as MuiDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import {
  Tooltip as MuiTooltip,
  FormHelperText as MuiFormHelperText,
  Typography as MuiTypography,
  Grid as MuiGrid,
} from "@mui/material";
import { parseISO, isValid, format } from "date-fns";
import { StyleApp } from "app/shared/ui/ui.styles";
import { useTranslation } from "react-i18next";
import { History as HistoryIcon, Info as InfoIcon } from "@mui/icons-material";
import {
  shouldRenderElement,
  addFieldValidation,
  getCalculatedExp,
} from "app/shared/utils";
import isJSON from "is-json";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { useDirtyFlag } from "app/portal/DirtyFlagProvider";
import { Theme } from "common";

let clearTimeout;

export const DatePicker = (props) => {
  const {
    label,
    name,
    required,
    readOnly,
    setScrollDialog,
    updated,
    links,
    fieldAttr,
    formik,
    formFlatMap,
    globalValueObj,
    helpertext,
    element,
    setInfoDialog,
    setRender,
    render,
    validation,
    yup,
    duplicateIds,
  } = props;

  const [inputValue, setInputValue] = useState(formFlatMap?.[name] || null);
  const [inputEvent, setInputEvent] = useState();
  const [exp, setExp] = useState("");

  const [fieldRenderedExp, setFieldRenderedExp] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const [fieldHelper, setFieldHelper] = useState(helpertext);
  const [touched, setTouched] = useState(false);
  const [fieldValidation, setFieldValidation] = useState(false);

  const { setIsDirty } = useDirtyFlag();

  const {
    type: validationType,
    test: validationScheme,
    message: validationMessage,
  } = isJSON(validation) ? JSON.parse(validation) : "";

  const { defaultValue = "" } = isJSON(fieldAttr) ? JSON.parse(fieldAttr) : {};

  const { t } = useTranslation();

  const iconSize = Boolean(updated) ? "37.5px" : "18.75px";
  const iconColor = Boolean(updated) ? "error" : "inherit";

  const ERROR =
    (!readOnly && touched && Boolean(formik.errors[name])) || fieldValidation;

  const FIELD_TEXT_COLOR =
    !Boolean(formik.errors[name]) && !fieldValidation
      ? Theme.palette.text.primary
      : undefined;

  const HELPER_TEXT_COLOR =
    (!readOnly && touched && Boolean(formik.errors[name])) || fieldValidation
      ? "error"
      : Theme.palette.text.secondary;

  const fieldAttributes = fieldAttr ? JSON.parse(fieldAttr) : "";

  const getLabel = (isRequired) => {
    const requiredStr = isRequired ? `*` : "";
    return `${label} ${requiredStr}`;
  };

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation
    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_editable
      )
    );
  }, [
    element.field_id,
    element.field_rendered,
    element.field_editable,
    globalValueObj,
    formFlatMap,
    formik,
    element.field_value_type,
    render,
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp); // SYNC and ASYNC

      addFieldValidation({
        name,
        evaledExp,
        required,
        touched,
        helpertext,
        validationType,
        validationScheme,
        validationMessage,
        t,
        formik,
        setFieldValidation,
        setFieldHelper,
        formFlatMap,
        globalValueObj,
        yup,
        element,
        duplicateIds,
      });

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRenderedExp, name, render]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(exp);
      const fieldDefaultValue =
        shouldRender && String(formFlatMap?.[name]).trim() === ""
          ? String(defaultValue)
          : "";
      let fieldValue = formFlatMap?.[name]; // setting default to the value of formFlatMap
      if (shouldRender) {
        if (exp) {
          fieldValue = evaledExp ? evaledExp : formFlatMap?.[name];
        } else if (fieldDefaultValue) {
          fieldValue = fieldDefaultValue;
        }
      }

      if (exp || String(fieldValue)) {
        globalValueObj[name] = fieldValue;
        formik.values[name] = fieldValue;
        formFlatMap[name] = fieldValue;
        setInputValue(fieldValue);
      }
    } catch (err) {
      console.log(name, err);
    }
  }, [
    exp,
    name,
    formik.values,
    formFlatMap,
    globalValueObj,
    readOnly,
    setRender,
    defaultValue,
    shouldRender,
  ]);

  useEffect(() => {
    if (formik && element) {
      const expression = getCalculatedExp(
        element.field_calculation,
        {
          ...globalValueObj,
          ...formik.values,
          ...formFlatMap,
        },
        element.field_value_type
      );
      setExp(expression);
    }
  }, [formik, element, globalValueObj, formik.values, formFlatMap]);

  useEffect(() => {
    if (inputEvent || isValid(new Date(inputEvent))) {
      formik.values[name] = inputValue;
      globalValueObj[name] = inputValue;
      formFlatMap[name] = inputValue;

      if (clearTimeout) window.clearTimeout(clearTimeout);
      clearTimeout = window.setTimeout(() => {
        setRender((value) => value + 1);
      }, 300);

      if (inputValue && !isValid(new Date(inputValue))) {
        formik.errors[
          name
        ] = `${element.field_label} is an invalid date format.`;
      } else delete formik.errors[name];
    } else {
      const value = formFlatMap?.[name];
      setInputValue(value);
      formik.values[name] = value;
      globalValueObj[name] = value;
      formFlatMap[name] = value;

      if (inputValue && !isValid(new Date(inputValue))) {
        formik.errors[
          name
        ] = `${element.field_label} is an invalid date format.`;
      } else delete formik.errors[name];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputValue,
    inputEvent,
    formFlatMap,
    formik.errors,
    formik.values,
    globalValueObj,
    name,
  ]);

  useEffect(() => {
    setRender((value) => value + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required, inputEvent]);

  if (!shouldRender) return null;

  return (
    <MuiGrid item xs={12} id={`field-${name}`}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDatePicker
            label={getLabel(required)}
            id="date-picker-dialog"
            error={ERROR}
            format={
              fieldAttributes?.dateFormat
                ? fieldAttributes.dateFormat
                : "yyyy-MM-dd"
            }
            value={inputValue ? parseISO(inputValue) : null}
            onChange={(date, value) => {
              if (isValid(date)) {
                const formattedDate = format(date, "yyyy-MM-dd");
                setInputValue(formattedDate || defaultValue);
                setInputEvent(date);
                setIsDirty(true);
              } else {
                setInputValue(date || defaultValue);
                setInputEvent(date);
              }
            }}
            invalidDateMessage={validation && ""}
            minDate={
              fieldAttributes?.minDate && !readOnly
                ? // eslint-disable-next-line no-eval
                  eval(fieldAttributes.minDate)
                : undefined
            }
            minDateMessage={validation && ""}
            maxDate={
              fieldAttributes?.maxDate && !readOnly
                ? // eslint-disable-next-line no-eval
                  eval(fieldAttributes.maxDate)
                : undefined
            }
            maxDateMessage={validation && ""}
            onBlur={() => {
              setTouched(true);
              return required && setRender((value) => value + 1);
            }}
            color={FIELD_TEXT_COLOR}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            disabled={readOnly}
            inputVariant={readOnly ? "standard" : "outlined"}
            fullWidth
            keyboardIcon={readOnly ? false : undefined}
            slotProps={{
              textField: {
                InputProps: {
                  placeholder: "",
                  sx: { fontSize: "15px" },
                },
                InputLabelProps: {
                  sx: { fontSize: "15px", color: Theme.palette.text.primary },
                },
              },
              switchViewButton: {
                sx: { padding: "5px" },
              },
              inputAdornment: {
                sx: {
                  "& .MuiButtonBase-root": { marginRight: "10px" },
                  "& .MuiSvgIcon-root": { fontSize: "22.5px" },
                },
              },
              actionBar: { actions: ["clear"] },
            }}
            sx={StyleApp.disabled}
          />
        </LocalizationProvider>
        {element && Boolean(element.field_info.trim()) ? (
          <MuiTooltip title={t("globals.fieldInfo.tooltip")}>
            <InfoIcon
              onClick={() =>
                setInfoDialog({
                  show: true,
                  fieldLabel: label,
                  fieldContent: element.field_info,
                })
              }
              sx={{
                cursor: "pointer",
                fontSize: "18.75px",
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "21px" }}></span>
        )}
        {Boolean(links) ? (
          <MuiTooltip title={t("globals.fieldHistory.tooltip")}>
            <HistoryIcon
              color={iconColor}
              onClick={() =>
                setScrollDialog({
                  show: true,
                  fieldLabel: label,
                  links: links,
                })
              }
              sx={{
                marginLeft: "5px",
                fontSize: iconSize,
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "1.5em" }}></span>
        )}
      </div>
      {fieldHelper && (
        <MuiFormHelperText component="div">
          <MuiTypography
            color={HELPER_TEXT_COLOR}
            variant="subtitle2"
            component="span"
            dangerouslySetInnerHTML={{
              __html: sanitize(fieldHelper, {
                ALLOWED_TAGS: AllowedTags,
              }),
            }}
            sx={StyleApp.links}
            style={{ fontSize: "13.125px" }}
          ></MuiTypography>
        </MuiFormHelperText>
      )}
    </MuiGrid>
  );
};
