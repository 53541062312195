import { useState } from "react";
import {
  Box as MuiBox,
  Link as MuiLink,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
} from "@mui/material";
import { DataGridPro as MuiDataGrid } from "@mui/x-data-grid-pro";
import {
  Help as MuiHelpIcon,
  Email as MuiEmailIcon,
  History as MuiHistory,
} from "@mui/icons-material";
import { MuiDataGridStyle, filterContainer } from "app/actions/Actions.styles";
import { ActionsFilter } from "app/actions/ActionsFilter";
import { useActions } from "app/actions/actionsService";
import { Links } from "app/actions/Links";
import { useApp } from "app/appService";
import {
  FormStatus,
  DashboardColumnsWidth,
  DashboardTableWidth,
} from "app/shared/constants";
import { useTranslation } from "react-i18next";
import { setStoreValue } from "app/shared/utils";
import { ActionHistory } from "app/actions/ActionHistory";
import { StartAction } from "app/actions/StartAction";
import { LoadingGridOverlay, NoRowsGridOverlay } from "common";

export const Actions = () => {
  const { tenantId } = useApp();
  const {
    actions,
    isLoading,
    loadFilters,
    filters,
    setFilters,
    actionState,
    setActionState,
    dispatch,
    loadActionHistory,
  } = useActions();

  const { t, i18n } = useTranslation();

  const NoActionOverlay = () => (
    <NoRowsGridOverlay
      message={`${t("dashboard.action.list.noRecordsFound")}`}
    />
  );

  const getColumnWidth = (columnVal) => {
    return DashboardColumnsWidth[tenantId][columnVal];
  };

  const [actionHistory, setActionHistory] = useState({
    show: false,
    title: "",
    rows: [],
    columns: [
      {
        id: "events",
        label: t(
          `dashboard.action.list.actionHistory.${tenantId}.columns.events`
        ),
        minWidth: 170,
        align: "left",
      },
      {
        id: "triggeredAt",
        label: t(
          `dashboard.action.list.actionHistory.${tenantId}.columns.triggeredAt`
        ),
        minWidth: 150,
        align: "left",
      },
      {
        id: "triggeredBy",
        label: t(
          `dashboard.action.list.actionHistory.${tenantId}.columns.triggeredBy`
        ),
        minWidth: 170,
        align: "left",
      },
    ],
    noContentText: t(
      `dashboard.action.list.actionHistory.${tenantId}.noHistoryText`
    ),
  });

  const columns = {
    medonboard: [
      {
        field: "id",
        headerClassName: "gridHeader",
        width: getColumnWidth("id"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medonboard.actionId")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <>
            <MuiTypography noWrap={true} variant="body2">
              {params.value}
            </MuiTypography>
            {i18n.exists(`dashboard.action.list.actionHistory.${tenantId}`) &&
              params.row.hateos.action_history && (
                <MuiTooltip title={t("globals.actionHistory.tooltip")}>
                  <MuiHistory
                    color={"primary"}
                    onClick={() =>
                      setActionHistory({
                        ...actionHistory,
                        link: params.row.hateos.action_history?.href,
                        actionId: params.row.id,
                        show: params.row.hateos.action_history ? true : false,
                        title: t(
                          `dashboard.action.list.actionHistory.${tenantId}.title`,
                          {
                            name: params.row.candidateNameSequence,
                            actionId: params.row.id,
                          }
                        ),
                      })
                    }
                    sx={{
                      cursor: "pointer",
                      fontSize: "22.5px",
                    }}
                  />
                </MuiTooltip>
              )}
          </>
        ),
      },
      {
        field: "candidateName",
        headerClassName: "gridHeader",
        width: getColumnWidth("candidateName"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medonboard.candidateName")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value || ""} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "hiringDepartment",
        headerClassName: "gridHeader",
        width: getColumnWidth("hiringDepartment"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(
              "dashboard.action.list.tableColumns.medonboard.hiringDepartment"
            )}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "hiringDivision",
        headerClassName: "gridHeader",
        width: getColumnWidth("hiringDivision"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medonboard.hiringDivision")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "startDate",
        headerClassName: "gridHeader",
        width: getColumnWidth("startDate"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medonboard.startDate")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          const startDate = params.value;
          return (
            <MuiTypography noWrap={true} variant="body2">
              {startDate}
            </MuiTypography>
          );
        },
      },
      {
        field: "status",
        headerClassName: "gridHeader",
        width: getColumnWidth("status"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medonboard.status")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTooltip
            title={t(FormStatus[params.value]) || ""}
            placement={"top"}
          >
            <MuiTypography noWrap={true} variant="body2">
              {t(FormStatus[params.value])}
            </MuiTypography>
          </MuiTooltip>
        ),
      },
      {
        field: "candidateStatus",
        headerClassName: "gridHeader",
        width: getColumnWidth("candidateStatus"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medonboard.candidateStatus")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTooltip title={params.value || ""} placement={"top"}>
            <MuiTypography noWrap={true} variant="body2">
              {params.value}
            </MuiTypography>
          </MuiTooltip>
        ),
      },
      {
        field: "links",
        headerClassName: "gridHeader",
        width: getColumnWidth("links"),
        sortable: false,
        renderHeader: () => (
          <MuiTypography variant="body1" sx={{ fontWeight: 600 }}>
            {t("dashboard.action.list.tableColumns.medonboard.links")}
          </MuiTypography>
        ),
        renderCell: (params) => <Links params={params} />,
      },
    ],
    medact: [
      {
        field: "id",
        headerClassName: "gridHeader",
        width: getColumnWidth("id"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medact.actionId")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <>
            <MuiTypography noWrap={true} variant="body2">
              {params.value}
            </MuiTypography>
            {i18n.exists(`dashboard.action.list.actionHistory.${tenantId}`) &&
              params.row.hateos.action_history && (
                <MuiTooltip title={t("globals.actionHistory.tooltip")}>
                  <MuiHistory
                    color={"primary"}
                    onClick={() =>
                      setActionHistory({
                        ...actionHistory,
                        link: params.row.hateos.action_history?.href,
                        actionId: params.row.id,
                        show: params.row.hateos.action_history ? true : false,
                        title: t(
                          `dashboard.action.list.actionHistory.${tenantId}.title`,
                          {
                            name: params.row.candidateNameSequence,
                            actionId: params.row.id,
                          }
                        ),
                      })
                    }
                    sx={{
                      cursor: "pointer",
                      fontSize: "22.5px",
                    }}
                  />
                </MuiTooltip>
              )}
          </>
        ),
      },
      {
        field: "candidateName",
        headerClassName: "gridHeader",
        width: getColumnWidth("candidateName"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medact.candidateName")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value || ""} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "hiringDepartment",
        headerClassName: "gridHeader",
        width: getColumnWidth("hiringDepartment"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medact.hiringDepartment")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "hiringDivision",
        headerClassName: "gridHeader",
        width: getColumnWidth("hiringDivision"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medact.hiringDivision")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "candidateLine",
        headerClassName: "gridHeader",
        width: getColumnWidth("candidateLine"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medact.candidateLine")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "startDate",
        headerClassName: "gridHeader",
        width: getColumnWidth("startDate"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medact.startDate")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          const startDate = params.value;
          return (
            <MuiTypography noWrap={true} variant="body2">
              {startDate}
            </MuiTypography>
          );
        },
      },
      {
        field: "termSheetStatus",
        headerClassName: "gridHeader",
        width: getColumnWidth("termSheetStatus"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medact.status")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTooltip title={t(params.value) || ""} placement={"top"}>
            <MuiTypography noWrap={true} variant="body2">
              {t(params.value)}
            </MuiTypography>
          </MuiTooltip>
        ),
      },
      {
        field: "links",
        headerClassName: "gridHeader",
        width: getColumnWidth("links"),
        sortable: false,
        renderHeader: () => (
          <MuiTypography variant="body1" sx={{ fontWeight: 600 }}>
            {t("dashboard.action.list.tableColumns.medact.links")}
          </MuiTypography>
        ),
        renderCell: (params) => <Links params={params} />,
      },
    ],
    medleave: [
      {
        field: "id",
        headerClassName: "gridHeader",
        width: getColumnWidth("id"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.requestId")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <>
            <MuiTypography noWrap={true} variant="body2">
              {params.value}
            </MuiTypography>
            {i18n.exists(`dashboard.action.list.actionHistory.${tenantId}`) &&
              params.row.hateos.action_history && (
                <MuiTooltip title={t("globals.actionHistory.tooltip")}>
                  <MuiHistory
                    color={"primary"}
                    onClick={() =>
                      setActionHistory({
                        ...actionHistory,
                        link: params.row.hateos.action_history?.href,
                        actionId: params.row.id,
                        show: params.row.hateos.action_history ? true : false,
                        title: t(
                          `dashboard.action.list.actionHistory.${tenantId}.title`,
                          {
                            name: params.row.candidateNameSequence,
                            actionId: params.row.id,
                          }
                        ),
                      })
                    }
                    sx={{
                      cursor: "pointer",
                      fontSize: "22.5px",
                    }}
                  />
                </MuiTooltip>
              )}
          </>
        ),
      },
      {
        field: "facultyName",
        headerClassName: "gridHeader",
        width: getColumnWidth("facultyName"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.facultyName")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value || ""} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "department",
        headerClassName: "gridHeader",
        width: getColumnWidth("department"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.department")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "division",
        headerClassName: "gridHeader",
        width: getColumnWidth("division"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.division")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "leaveType",
        headerClassName: "gridHeader",
        width: getColumnWidth("leaveType"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.leaveType")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "leaveStartDate",
        headerClassName: "gridHeader",
        width: getColumnWidth("leaveStartDate"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.leaveStartDate")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          const startDate = params.value;
          return (
            <MuiTypography noWrap={true} variant="body2">
              {startDate}
            </MuiTypography>
          );
        },
      },
      {
        field: "leaveEndDate",
        headerClassName: "gridHeader",
        width: getColumnWidth("leaveEndDate"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.leaveEndDate")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          const endDate = params.value;
          return (
            <MuiTypography noWrap={true} variant="body2">
              {endDate}
            </MuiTypography>
          );
        },
      },
      {
        field: "status",
        headerClassName: "gridHeader",
        width: getColumnWidth("status"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.status")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTooltip
            title={t(FormStatus[params.value]) || ""}
            placement={"top"}
          >
            <MuiTypography noWrap={true} variant="body2">
              {t(FormStatus[params.value])}
            </MuiTypography>
          </MuiTooltip>
        ),
      },
      {
        field: "requestStatus",
        headerClassName: "gridHeader",
        width: getColumnWidth("requestStatus"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.medleave.requestStatus")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTooltip title={params.value || ""} placement={"top"}>
            <MuiTypography noWrap={true} variant="body2">
              {params.value}
            </MuiTypography>
          </MuiTooltip>
        ),
      },
      {
        field: "links",
        headerClassName: "gridHeader",
        width: getColumnWidth("links"),
        sortable: false,
        renderHeader: () => (
          <MuiTypography variant="body1" sx={{ fontWeight: 600 }}>
            {t("dashboard.action.list.tableColumns.medleave.links")}
          </MuiTypography>
        ),
        renderCell: (params) => <Links params={params} />,
      },
    ],
    relman: [
      {
        field: "id",
        headerClassName: "gridHeader",
        width: getColumnWidth("id"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.relman.actionId")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <>
            <MuiTypography noWrap={true} variant="body2">
              {params.value}
            </MuiTypography>
            {i18n.exists(`dashboard.action.list.actionHistory.${tenantId}`) &&
              params.row.hateos.action_history && (
                <MuiTooltip title={t("globals.actionHistory.tooltip")}>
                  <MuiHistory
                    color={"primary"}
                    onClick={() =>
                      setActionHistory({
                        ...actionHistory,
                        link: params.row.hateos.action_history?.href,
                        actionId: params.row.id,
                        show: params.row.hateos.action_history ? true : false,
                        title: t(
                          `dashboard.action.list.actionHistory.${tenantId}.title`,
                          {
                            name: params.row.candidateNameSequence,
                            actionId: params.row.id,
                          }
                        ),
                      })
                    }
                    sx={{
                      cursor: "pointer",
                      fontSize: "22.5px",
                    }}
                  />
                </MuiTooltip>
              )}
          </>
        ),
      },
      {
        field: "releaseProject",
        headerClassName: "gridHeader",
        width: getColumnWidth("releaseProject"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.relman.releaseProject")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value || ""} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "releaseTag",
        headerClassName: "gridHeader",
        width: getColumnWidth("releaseTag"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.relman.releaseTag")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "releaseRolling",
        headerClassName: "gridHeader",
        width: getColumnWidth("releaseRolling"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.relman.releaseRolling")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "releaseHighProfile",
        headerClassName: "gridHeader",
        width: getColumnWidth("releaseHighProfile"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.relman.releaseHighProfile")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "releaseRequiresCoordination",
        headerClassName: "gridHeader",
        width: getColumnWidth("releaseRequiresCoordination"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.relman.releaseRequiresCoordination")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "releaseRequestDate",
        headerClassName: "gridHeader",
        width: getColumnWidth("releaseRequestDate"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.relman.releaseRequestDate")}
          </MuiTypography>
        ),
        renderCell: (params) => {
          const startDate = params.value;
          return (
            <MuiTypography noWrap={true} variant="body2">
              {startDate}
            </MuiTypography>
          );
        },
      },
      {
        field: "releaseStatus",
        headerClassName: "gridHeader",
        width: getColumnWidth("releaseStatus"),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t("dashboard.action.list.tableColumns.relman.status")}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTooltip title={t(params.value) || ""} placement={"top"}>
            <MuiTypography noWrap={true} variant="body2">
              {t(params.value)}
            </MuiTypography>
          </MuiTooltip>
        ),
      },
      {
        field: "links",
        headerClassName: "gridHeader",
        width: getColumnWidth("links"),
        sortable: false,
        renderHeader: () => (
          <MuiTypography variant="body1" sx={{ fontWeight: 600 }}>
            {t("dashboard.action.list.tableColumns.relman.links")}
          </MuiTypography>
        ),
        renderCell: (params) => <Links params={params} />,
      },
    ],
  };

  const handleSortModelChange = (params) => {
    if (isSortChanged(params, actions.sortModel)) {
      dispatch({
        action: "SET_SORT_MODEL",
        value: params,
      });
      const newState = { ...actionState, sortModel: params };
      setStoreValue("sort", newState);
      setActionState(newState);
    }
  };

  const handlePageChange = (page) => {
    dispatch({
      action: "SET_PAGE",
      value: page,
    });
    const newState = { ...actionState, pageNo: page };
    setStoreValue("pagination", newState);
    setActionState(newState);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handlePageSizeChange = (size) => {
    dispatch({
      action: "SET_PAGE",
      value: 0,
    });
    dispatch({
      action: "SET_PAGE_SIZE",
      value: size,
    });
    const newState = { ...actionState, pageNo: 0, pageSize: size };
    setStoreValue("page", newState);
    setActionState(newState);
  };

  const handlePaginationModelChange = (e, details) => {
    if (actions.pageNo !== e.page) {
      handlePageChange(e.page);
    }

    if (actions.pageSize !== e.pageSize) {
      handlePageSizeChange(e.pageSize);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        overflowX: "auto",
        paddingBottom: "16px",
        paddingTop: "16px",
        backgroundColor: "#FAFAFA",
        height: "100%",
      }}
    >
      <div style={{ width: DashboardTableWidth[tenantId], margin: "0 auto" }}>
        <MuiBox
          sx={{
            display: "flex",
            marginBottom: 2,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <MuiTypography
            variant="h1"
            sx={{ display: "inline-block", marginLeft: "16px" }}
          >
            {t(`dashboard.action.title.${tenantId}`)}
          </MuiTypography>
          {["medleave"].includes(tenantId) && (
            <div
              style={{
                gap: "16px",
                display: "flex",
                alignItems: "center",
                marginRight: "16px",
              }}
            >
              <MuiLink
                target="_blank"
                rel="noopener"
                underline="none"
                sx={{ display: "flex", alignItems: "center", gap: "2px" }}
                href={t("app.leave.helpLink")}
              >
                <MuiHelpIcon sx={{ fontSize: "18px" }} />
                <MuiTypography sx={{ fontSize: "15px", fontWeight: 500 }}>
                  {t("globals.help")}
                </MuiTypography>
              </MuiLink>
              <MuiLink
                underline="none"
                sx={{ display: "flex", alignItems: "center", gap: "2px" }}
                href={`mailto:${t("app.leave.supportEmail")}?subject=${t(
                  "app.leave.supportEmailSubject"
                )}`}
              >
                <MuiEmailIcon sx={{ fontSize: "18px" }} />
                <MuiTypography sx={{ fontSize: "15px", fontWeight: 500 }}>
                  {t("globals.contactUs")}
                </MuiTypography>
              </MuiLink>
            </div>
          )}
        </MuiBox>
        <div style={{ width: "80%", float: "left", margin: "0 16px" }}>
          {actions.links && actions.links.apiUrl && (
            <StartAction actionLinks={actions.links.apiUrl} />
          )}
        </div>
        <div
          style={{ width: "17%", float: "left", paddingRight: "16px" }}
        ></div>
      </div>
      <div style={{ width: DashboardTableWidth[tenantId], margin: "0 auto" }}>
        <MuiBox sx={{ width: "80%", float: "left", margin: "0 16px" }}>
          <MuiDataGrid
            slots={{
              loadingOverlay: LoadingGridOverlay,
              noRowsOverlay: NoActionOverlay,
            }}
            pagination
            disableExtendRowFullWidth={true}
            loading={isLoading}
            rows={actions.actionItems}
            columns={columns[tenantId]}
            autoHeight
            disableColumnResize
            disableColumnReorder
            disableColumnMenu
            disableRowSelectionOnClick
            sx={MuiDataGridStyle}
            paginationMode="server"
            sortingMode="server"
            paginationModel={{
              page: actions.pageNo,
              pageSize: actions.pageSize,
            }}
            onPaginationModelChange={handlePaginationModelChange}
            rowCount={actions.totalRows}
            sortModel={actions.sortModel}
            onSortModelChange={handleSortModelChange}
            sortingOrder={["asc", "desc"]}
          />
        </MuiBox>
        <MuiBox sx={filterContainer}>
          <ActionsFilter
            actionDispatcher={dispatch}
            loadFilters={loadFilters}
            filters={filters}
            setFilters={setFilters}
          />
        </MuiBox>
      </div>
      <ActionHistory
        params={actionHistory}
        setParams={setActionHistory}
        loadActionHistory={loadActionHistory}
      />
    </div>
  );
};

const isSortChanged = (params, sortModel) =>
  params.length &&
  (params[0].field !== sortModel[0].field ||
    params[0].sort !== sortModel[0].sort);
