import { useState } from "react";
import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  Backdrop as MuiBackdrop,
  CircularProgress as MuiCircularProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  paperFullWidth,
  backdrop,
  title,
} from "app/file-attachments/RenameAttachment.styles";
import { deleteFileAttachment } from "app/file-attachments/fileAttachmentsService";

export const DeleteConfirmation = (props) => {
  const {
    open: showDialog,
    setShowDialog,
    fileName,
    actionId,
    documentId,
    clearAlert,
    setAlert,
    setRefresh,
  } = props;
  const { t } = useTranslation();
  const [, setOpen] = useState(showDialog);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setShowDialog({ fileName, open: false });
  };

  return (
    <MuiDialog
      open={showDialog}
      onClose={handleClose}
      fullWidth={true}
      sx={paperFullWidth}
    >
      <MuiDialogTitle sx={title}>
        {t("fileAttachments.deleteDialog.title")}
      </MuiDialogTitle>
      <MuiDialogContent sx={{ padding: "8px 24px" }}>
        {loading ? (
          <MuiBackdrop open={loading} sx={backdrop} invisible={true}>
            <MuiCircularProgress />
          </MuiBackdrop>
        ) : (
          <MuiTypography variant={"body2"} sx={{ paddingTop: "8px" }}>
            {t("fileAttachments.deleteDialog.content", { fileName })}
          </MuiTypography>
        )}
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton
          onClick={handleClose}
          color="primary"
          sx={{ fontSize: "17px" }}
        >
          {t("fileAttachments.deleteDialog.actionButtons.cancel.label")}
        </MuiButton>
        <MuiButton
          color="primary"
          onClick={() => {
            deleteFileAttachment({
              actionId,
              documentId,
              setLoading,
              clearAlert,
              setAlert,
              handleClose,
              setRefresh,
              t,
              oldFileName: fileName,
            });
          }}
          sx={{ fontSize: "17px" }}
        >
          {t("fileAttachments.deleteDialog.actionButtons.delete.label")}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};
