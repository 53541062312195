import { useState } from "react";
import {
  Grid as MuiGrid,
  Container as MuiContainer,
  Paper as MuiPaper,
  Button as MuiButton,
  Slide as MuiSlide,
  Typography as MuiTypography,
  Link as MuiLink,
  TextField as MuiTextField,
} from "@mui/material";
import { useFormik } from "formik";
import { useTranslation, Trans } from "react-i18next";
import * as Yup from "yup";
import { usePortalAuth } from "app/portal/portalAuthService";
import { Theme } from "common";

// building custom styles
const StyleApp = {
  container: {
    maxWidth: 700,
  },
  surveyinstructions: {
    fontSize: 15,
  },
  wrapIcon: {
    display: "inline-flex",
    verticalAlign: "middle",
  },
  loginWrapper: {
    marginTop: "56px",
    marginBottom: "40px",
    width: 700,
  },
  backButton: {
    float: "right",
  },
  link: {
    textDecoration: "none",
    color: "#007c92",
    cursor: "pointer",
  },
  textField: {
    "& label": { fontSize: "15px", color: Theme.palette.text.primary },
    "& input": { fontSize: "15px" },
  },
};

// custom paper component to add elevation
const CustomPaper = (props) => <MuiPaper elevation={2} {...props} />;

// request login link component
export const PortalLogin = () => {
  const { postLoginRequest } = usePortalAuth();
  const [successMessage, setSuccessMessage] = useState("");
  const formik = useFormik({
    initialValues: { email: "" },
    enableReinitialize: true,
    validationSchema: Yup.object({
      email: Yup.string().email().trim().required(),
    }),
    onSubmit: (values, { setFieldError, setSubmitting }) => {
      postLoginRequest({ values, setSubmitting, setSuccessMessage });
    },
  });

  const { t } = useTranslation();

  const buttonText = formik.isSubmitting
    ? t("portal.portalLogin.inProcessButton")
    : t("portal.portalLogin.submitButton");

  return (
    <MuiContainer component={CustomPaper} sx={StyleApp.loginWrapper}>
      <form onSubmit={formik.handleSubmit}>
        {!successMessage ? (
          <MuiSlide direction="right" in={true} mountOnEnter unmountOnExit>
            <MuiGrid container justifyContent="space-between" spacing={4}>
              <MuiGrid
                item
                xs={12}
                sx={{ "&.MuiGrid-item": { paddingTop: "16px" } }}
              >
                <LoginMessage />
              </MuiGrid>
              <MuiGrid item xs={12}>
                <MuiTextField
                  name="email"
                  label={t("portal.portalLogin.emailField.label")}
                  required={true}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  fullWidth
                  variant={"outlined"}
                  sx={StyleApp.textField}
                />
              </MuiGrid>
              <MuiGrid item>
                <MuiTypography variant="body2">
                  <Trans i18nKey="loginAccessContactInfo">
                    If you're still having issues you can contact{" "}
                    <MuiLink
                      href={`mailto:${t("app.candidate-portal.supportEmail")}`}
                      sx={StyleApp.link}
                    >
                      {t("app.candidate-portal.supportEmail")}
                    </MuiLink>{" "}
                    for help.
                  </Trans>
                </MuiTypography>
              </MuiGrid>
              <MuiGrid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                pb={2}
              >
                <MuiButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  disabled={
                    !formik.isValid || !formik.dirty || formik.isSubmitting
                  }
                  sx={{ fontSize: "17px" }}
                >
                  {buttonText}
                </MuiButton>
              </MuiGrid>
            </MuiGrid>
          </MuiSlide>
        ) : (
          <MuiGrid container justifyContent="space-between" spacing={4}>
            <MuiGrid
              item
              xs={12}
              sx={{ "&.MuiGrid-item": { paddingTop: "16px" } }}
            >
              <MuiSlide direction="left" in={true} mountOnEnter unmountOnExit>
                <MuiTypography variant="body2">
                  <Trans i18nKey="success">
                    If the email you entered exists in our records, an email
                    will be sent right away with your new unique login link.
                    Please add our Candidate Onboarding Email{" "}
                    <MuiLink
                      href={`mailto:${t("app.candidate-portal.supportEmail")}`}
                      sx={StyleApp.link}
                    >
                      {t("app.candidate-portal.supportEmail")}
                    </MuiLink>{" "}
                    to your contact list and check your Spam Folder if you do
                    not receive an email.
                  </Trans>
                </MuiTypography>
              </MuiSlide>
            </MuiGrid>
            <MuiGrid item xs={12} pb={2}>
              <MuiTypography variant="body2">
                <Trans i18nKey="loginAccessContactInfo">
                  Click{" "}
                  <MuiLink
                    underline="none"
                    onClick={() => setSuccessMessage(false)}
                    sx={StyleApp.link}
                  >
                    here
                  </MuiLink>{" "}
                  If you have still have not received an email with your new
                  link and want to request a new email link.
                </Trans>
              </MuiTypography>
            </MuiGrid>
          </MuiGrid>
        )}
      </form>
    </MuiContainer>
  );
};

const LoginMessage = () => {
  const { t } = useTranslation();

  return (
    <>
      <MuiTypography gutterBottom variant="body2">
        {t("app.candidate-portal.loginAccessDescription")}
      </MuiTypography>
    </>
  );
};
