import { useState, useEffect } from "react";
import { Typography as MuiTypography, Grid as MuiGrid } from "@mui/material";
import { setFieldsInText, shouldRenderElement } from "app/shared/utils";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { StyleApp } from "app/shared/ui/ui.styles";

export const Label = (props) => {
  const [text, setText] = useState();
  const [fieldRenderedExp, setFieldRenderedExp] = useState("");
  const [shouldRender, setShouldRender] = useState(false);

  let {
    name,
    label,
    formValues,
    actionid,
    formspec,
    element,
    formFlatMap,
    globalValueObj,
    formik,
    render,
  } = props;

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation
    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_editable
      )
    );
  }, [
    element.field_id,
    element.field_rendered,
    element.field_editable,
    globalValueObj,
    formFlatMap,
    formik,
    element.field_value_type,
    render,
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp); // SYNC and ASYNC

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
  }, [fieldRenderedExp, name]);

  useEffect(() => {
    setFieldsInText(
      label,
      formValues,
      {
        actionId: actionid,
        formSpec: formspec,
      },
      setText
    );
  }, [label, formValues, actionid, formspec]);

  if (!shouldRender) return null;

  return (
    <MuiGrid item xs={12}>
      <MuiTypography
        variant="body2"
        component="span"
        dangerouslySetInnerHTML={{
          __html: sanitize(text, {
            ALLOWED_TAGS: AllowedTags,
          }),
        }}
        sx={StyleApp.links}
      ></MuiTypography>
    </MuiGrid>
  );
};
