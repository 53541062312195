import { useState, useEffect } from "react";
import { Typography as MuiTypography, Grid as MuiGrid } from "@mui/material";
import { StyleApp } from "app/shared/ui/ui.styles";
import { setFieldsInText } from "app/shared/utils";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";

export const Instructions = (props) => {
  const [text, setText] = useState();
  let { label, formValues, actionid, formspec, render } = props;

  useEffect(() => {
    setFieldsInText(
      label,
      formValues,
      {
        actionId: actionid,
        formSpec: formspec,
      },
      setText
    );
  }, [label, formValues, actionid, formspec, render]);

  return (
    <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { paddingTop: "8px" } }}>
      <MuiTypography
        variant="subtitle1"
        component="span"
        dangerouslySetInnerHTML={{
          __html: sanitize(text, {
            ALLOWED_TAGS: AllowedTags,
          }),
        }}
        sx={StyleApp.surveyinstructions}
      ></MuiTypography>
    </MuiGrid>
  );
};
