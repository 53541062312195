import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Autocomplete as MuiAutocomplete,
  Dialog as MuiDialog,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  DialogActions as MuiDialogActions,
  Button as MuiButton,
  Typography as MuiTypography,
  Grid as MuiGrid,
  CircularProgress as MuiCircularProgress,
  Backdrop as MuiBackdrop,
  TextField as MuiTextField,
  FormHelperText as MuiFormHelperText,
} from "@mui/material";
import {
  paperFullWidth,
  title,
  backdrop,
  doneIcon,
  actionDialogRoot,
  autocompleteStyles,
  errorOutlineIcon,
} from "./UploadAttachments.styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DropZone } from "app/shared/ui/DropZone/DropZone";
import {
  Done as DoneIcon,
  ErrorOutline as ErrorOutlineIcon,
} from "@mui/icons-material";
import { saveFileAttachment } from "app/file-attachments/fileAttachmentsService";
import { useAlerts } from "common";
import { useApp } from "app/appService";
import { UPLOAD_ATTACHMENT_MAX_SIZE } from "app/shared/constants";

const FileTypeOptions = {
  medonboard: [
    { title: "CV", type: "CV" },
    { title: "Exception Approval", type: "EXCEPTION_APPROVAL" },
    { title: "Letter of Interest", type: "LETTER_OF_INTEREST" },
    { title: "Chair Memo", type: "CHAIR_MEMO" },
    { title: "Other", type: "OTHER" },
  ],
  medact: [
    { title: "CV", type: "CV" },
    { title: "Exception Approval", type: "EXCEPTION_APPROVAL" },
    { title: "Letter of Interest", type: "LETTER_OF_INTEREST" },
    { title: "Chair Memo", type: "CHAIR_MEMO" },
    { title: "Other", type: "OTHER" },
  ],
  medleave: [
    {
      title: "Practice Policy Exemption (PPE)",
      type: "PRACTICE_POLICY_EXEMPTION",
    },
    {
      title: "Teaching Courses Exception Questionnaire",
      type: "TEACHING_COURSES_EXCEPTION_QUESTIONNAIRE",
    },
    {
      title: "Continuance of Duties Exception",
      type: "CONTINUANCE_OF_DUTIES_EXCEPTION",
    },
    { title: "Other", type: "OTHER" },
  ],
};
const initialUploadDocumentErrorState = {
  invalidFileTypeError: false,
  fileSizeLimitExceedError: false,
};

export const UploadAttachments = ({
  openDialog,
  setOpenDialog,
  actionId,
  setRefresh,
  fileAttachments,
}) => {
  const [loading, setLoading] = useState(false);
  const [uploadDocumentError, setUploadDocumentError] = useState(
    initialUploadDocumentErrorState
  );
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [fileAttachmentType, setFileAttachmentType] = useState("");
  const [rejectedFileName, setRejectedFileName] = useState("");
  const { t } = useTranslation();
  const { clearAlert, setAlert } = useAlerts();
  const { tenantId } = useApp();

  const formik = useFormik({
    initialValues: {
      fileAttachmentType: "",
      fileAttachment: "",
    },
    validationSchema: uploadAttachmentsValidation,
    onSubmit: (values, { setSubmitting, resetForm }) => {},
  });

  const handleClose = () => {
    setOpenDialog(false);
    setFileAttachmentType("");
    formik.setFieldValue("file", "");
    setUploadDocumentError({ ...initialUploadDocumentErrorState });
    setRejectedFileName("");
  };

  const handleFile = (files) => {
    const file = files[0];
    formik.setFieldValue("fileAttachment", file);
    setUploadDocumentError({ ...initialUploadDocumentErrorState });
  };

  const handleDropRejectedFile = (rejectedFiles) => {
    const { path } = rejectedFiles[0];

    if (rejectedFiles[0].size > UPLOAD_ATTACHMENT_MAX_SIZE) {
      setUploadDocumentError((prevState) => ({
        ...prevState,
        fileSizeLimitExceedError: true,
      }));
      setRejectedFileName(path);
    } else {
      setRejectedFileName(path);
      setUploadDocumentError((prevState) => ({
        ...prevState,
        invalidFileTypeError: true,
      }));
    }
  };

  const handleConfirmationClose = () => {
    setConfirmationDialog(false);
  };

  const uploadAttachment = (resetForm) => {
    const IS_FILE_EXISTS = isFileExists(
      fileAttachments,
      formik.values["fileAttachment"].name
    );

    saveFileAttachment(
      initialUploadDocumentErrorState,
      IS_FILE_EXISTS,
      actionId,
      formik.values["fileAttachment"],
      fileAttachmentType.type,
      setLoading,
      setUploadDocumentError,
      handleClose,
      setAlert,
      clearAlert,
      setRefresh,
      setConfirmationDialog,
      resetForm,
      t,
      IS_FILE_EXISTS // passing document id
    );
  };

  const getFormattedFileName = (file) => {
    if (!file?.name) return "";
    const { filename, extension } = getFilePropFromObj(file);
    return (
      filename.substring(0, 40) +
      (file.name.length > 40 ? "... ." : ".") +
      extension
    );
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <MuiDialog
          open={openDialog}
          onClose={() => {
            formik.resetForm();
            handleClose();
          }}
          sx={paperFullWidth}
        >
          <MuiDialogTitle sx={title}>
            {t("fileAttachments.uploadAttachmentsDialog.title")}
          </MuiDialogTitle>
          <MuiDialogContent sx={{ padding: "8px 24px" }}>
            <MuiGrid
              container
              spacing={2}
              alignItems={"center"}
              sx={{ paddingTop: "8px" }}
            >
              <MuiGrid item xs={12}>
                <MuiAutocomplete
                  name="fileAttachmentType"
                  options={FileTypeOptions[tenantId]}
                  getOptionLabel={(option) => option?.title || ""}
                  isOptionEqualToValue={(option, optionValue) =>
                    option.type === optionValue.type
                  }
                  fullWidth
                  onChange={(event, value) => {
                    const ATTACHMENT_TYPE = value?.type || "";
                    setFileAttachmentType(value);
                    formik.setFieldValue("fileAttachmentType", ATTACHMENT_TYPE);
                  }}
                  value={fileAttachmentType ? fileAttachmentType : null}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label="File Attachment Type"
                      variant="outlined"
                      required
                    />
                  )}
                  renderOption={(props, option) => (
                    <div style={{ fontSize: "15px" }} {...props}>
                      {`${option?.title}`}
                    </div>
                  )}
                  sx={autocompleteStyles}
                />
              </MuiGrid>
              <MuiGrid item xs={12}>
                <MuiBackdrop open={loading} sx={backdrop} invisible={true}>
                  <MuiCircularProgress />
                </MuiBackdrop>
                <DropZone
                  dropzoneText={
                    <MuiGrid>
                      <MuiTypography variant="body2">
                        {t(
                          "fileAttachments.uploadAttachmentsDialog.fields.uploader.label"
                        )}
                      </MuiTypography>
                      <em>
                        {t(
                          "fileAttachments.uploadAttachmentsDialog.fields.uploader.acceptedFilesLabel"
                        )}
                      </em>
                    </MuiGrid>
                  }
                  handleDocumentUpload={handleFile}
                  handleDropRejected={handleDropRejectedFile}
                />
                <MuiFormHelperText component="div">
                  {t(
                    "fileAttachments.uploadAttachmentsDialog.notification.fileSizeLimitHelperText"
                  )}
                </MuiFormHelperText>
              </MuiGrid>
              <MuiGrid item xs={12}>
                {(uploadDocumentError.invalidFileTypeError ||
                  uploadDocumentError.fileSizeLimitExceedError) && (
                  <MuiTypography color="error" variant="body2">
                    <ErrorOutlineIcon sx={errorOutlineIcon} color="error" />
                    &nbsp;
                    {t(
                      uploadDocumentError.invalidFileTypeError
                        ? "fileAttachments.uploadAttachmentsDialog.error.fileTypeNotSupported"
                        : "fileAttachments.uploadAttachmentsDialog.error.fileSizeLimitExceeded",
                      {
                        fileName: rejectedFileName,
                      }
                    )}
                  </MuiTypography>
                )}
                {!(
                  uploadDocumentError.invalidFileTypeError ||
                  uploadDocumentError.fileSizeLimitExceedError
                ) &&
                  formik.values["fileAttachment"]?.name && (
                    <MuiTypography display="block" variant="body2">
                      <DoneIcon size="small" sx={doneIcon} />{" "}
                      {getFormattedFileName(formik.values["fileAttachment"])}
                    </MuiTypography>
                  )}
              </MuiGrid>
            </MuiGrid>
          </MuiDialogContent>
          <MuiDialogActions sx={actionDialogRoot}>
            {/* Cancel Button */}
            <MuiButton
              onClick={() => {
                formik.resetForm();
                handleClose();
              }}
              color="primary"
              sx={{ fontSize: "17px" }}
            >
              {t(
                "fileAttachments.uploadAttachmentsDialog.actionButtons.cancel.label"
              )}
            </MuiButton>
            {/* Upload Button */}
            <MuiButton
              color="primary"
              onClick={() => {
                setConfirmationDialog(true);
              }}
              disabled={
                !formik.dirty ||
                !formik.isValid ||
                formik.isSubmitting ||
                uploadDocumentError.invalidFileTypeError ||
                uploadDocumentError.fileSizeLimitExceedError
              }
              sx={{ fontSize: "17px" }}
            >
              {t(
                "fileAttachments.uploadAttachmentsDialog.actionButtons.upload.label"
              )}
            </MuiButton>
          </MuiDialogActions>
        </MuiDialog>
      </form>
      <MuiDialog
        open={confirmationDialog}
        onClose={handleConfirmationClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MuiDialogTitle
          id="alert-dialog-title"
          sx={{ fontWeight: 500, fontSize: "18.75px" }}
        >
          {"Confirmation"}
        </MuiDialogTitle>
        <MuiDialogContent sx={{ padding: "8px 24px" }}>
          <MuiTypography variant="body2" sx={{ paddingTop: "8px" }}>
            {t(
              `fileAttachments.uploadAttachmentsDialog.confirmation.${
                isFileExists(
                  fileAttachments,
                  formik.values["fileAttachment"]?.name
                )
                  ? "replace"
                  : "upload"
              }`,
              {
                fileName: getFormattedFileName(formik.values["fileAttachment"]),
              }
            )}
          </MuiTypography>
        </MuiDialogContent>
        <MuiDialogActions>
          <MuiButton
            onClick={handleConfirmationClose}
            color="primary"
            sx={{ fontSize: "17px" }}
          >
            Cancel
          </MuiButton>
          <MuiButton
            onClick={() => uploadAttachment(formik.resetForm)}
            autoFocus
            color="primary"
            sx={{ fontSize: "17px" }}
          >
            Confirm
          </MuiButton>
        </MuiDialogActions>
      </MuiDialog>
    </>
  );
};

const uploadAttachmentsValidation = Yup.object().shape({
  fileAttachment: Yup.mixed().required(),
  fileAttachmentType: Yup.string().required(),
});

const isFileExists = (files, newName) => {
  let fileId = 0;
  if (!files.length) return false;

  files.forEach((item) => {
    if (item.fileName.localeCompare(newName) === 0) {
      fileId = item.documentId;
    }
  });
  return fileId;
};

const getFilePropFromObj = (file) => {
  const nameString = file.name.split(".");
  // Popping out the last element which would be file extension
  const extension = nameString.pop();
  // Creating filename by joining the characters of string
  return { filename: nameString.join("."), extension };
};
