import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAlerts } from "common";
import { BundleIds, OnboardCandidateStatus } from "app/shared/constants";
import { useApp } from "app/appService";
import { clearAuthStorage } from "app/portal/portalAuthService";
import isJSON from "is-json";

const FormsContext = createContext();

export const FormsProvider = ({ apiUrl, children }) => {
  const [formFields, setFormFields] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [summaryFields, setSummaryFields] = useState([]);
  const [formAccess, setFormAccess] = useState();
  const [formFlatMap, setFormFlatMap] = useState({});
  const [formLabelMap, setFormLabelMap] = useState({});
  const [formValues, setFormValues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [duplicateIds, setDuplicateIds] = useState([]);
  const { clearAlert, setAlert } = useAlerts();
  const [showStartHere, setShowStartHere] = useState(false);
  const { bundleId } = useApp();

  const formName = apiUrl && apiUrl.split("/").pop();

  useEffect(() => {
    setFormFields([]);
    setAllFields([]);
    setSummaryFields([]);
    setFormFlatMap({});
    setFormLabelMap({});
    setFormValues({});
  }, [formName]);

  useEffect(() => {
    (async () => {
      clearAlert();
      setIsLoading(true);

      try {
        if (apiUrl) {
          const response = await axios(apiUrl);
          response.data.fields.forEach((item, index) => {
            if (item.field_value === "{}" || item.field_value === null) {
              response.data.fields[index].field_value = "";
            }
          });
          const formData = response.data.fields;

          const fields = getFormFields(response);

          setAllFields(formData);

          setFormAccess(response.data.access);
          setFormFields(fields);

          const flatMap = flatten({}, formData);
          const labelMap = getLabels(formData);
          const values = flatten({}, getUserFields(formData));

          setFormFlatMap(flatMap);
          setFormLabelMap(labelMap);
          setFormValues(values);
          setDuplicateIds(getDuplicateIds(formData));

          const summaryRows = formData.filter((item) => getSummaryFields(item));
          const candidateStatus = formData.find(
            (f) => f.field_id === "candidate_status"
          );

          setSummaryFields(summaryRows);
          setShowStartHere(
            candidateStatus?.field_value === OnboardCandidateStatus.INVITED
          );
          setIsLoading(false);
        }
      } catch (error) {
        if (
          error?.response?.status === 401 &&
          bundleId === BundleIds.MEDONBOARD_PORTAL
        ) {
          clearAuthStorage();
          window.location.href = "/";
        }
        console.log(error);
        setIsLoading(false);
        setAlert("error", error.message);
      }
    })();
  }, [apiUrl, bundleId, clearAlert, formName, setAlert]);

  return (
    <FormsContext.Provider
      value={{
        formFields,
        formFlatMap,
        formAccess,
        formValues,
        isLoading,
        showStartHere,
        allFields,
        summaryFields,
        duplicateIds,
        formLabelMap,
      }}
    >
      {children}
    </FormsContext.Provider>
  );
};

// Custom hook
export const useForms = () => {
  return useContext(FormsContext);
};

const flatten = (into, node) => {
  if (node == null) return into;
  if (Array.isArray(node)) return node.reduce(flatten, into);
  into[node.field_id] = node && !isImmutable(node) ? node.field_value : "";
  return flatten(into, node?.subOrgs);
};

const getLabels = (formData) => {
  const labelMap = {};
  for (let i of formData) {
    labelMap[i.field_id] = i.field_label;
  }
  return labelMap;
};

export const isHidden = (field) =>
  // Currently two condition is being maintained to make the backward compatiblity with other tenants.
  // Once other tenants update their formspec, then condition of @HIDDEN can be removed.
  field["field_type"] === "hidden" ||
  field?.["field_format"]?.split(" ")?.indexOf("@HIDDEN") > -1;

export const isImmutable = (element) => {
  const clearOnLoad = isJSON(element.field_attributes)
    ? JSON.parse(element.field_attributes)["clearOnLoad"]
    : "";
  // Currently two condition is being maintained to make the backward compatiblity with other tenants.
  // Once other tenants update their formspec, then condition of @IMMUTABLE can be removed.
  return (
    clearOnLoad === true ||
    element?.["field_format"]?.split(" ")?.indexOf("@IMMUTABLE") > -1
  );
};

export const isUserField = (field) =>
  [
    "text",
    "number",
    "money",
    "percent",
    "radio",
    "dropdown",
    "checkbox",
  ].includes(field.field_type);

export const getUserFields = (fields) =>
  fields.filter((field) => isUserField(field));

export const getDuplicateIds = (fields) => {
  const lookup = fields.reduce((a, e) => {
    a[e.field_id] = ++a[e.field_id] || 0;
    return a;
  }, {});

  return [
    ...new Set(
      fields.filter((e) => lookup[e.field_id]).map((item) => item.field_id)
    ),
  ];
};

export const getSummaryFields = (element) =>
  element["field_type"] === "summary";

const getFormFields = (response) => {
  let summaryAdded = false;
  return response.data.fields.filter((item) => {
    if (
      (item.field_type === "summary" && !summaryAdded) ||
      item.field_type !== "summary"
    ) {
      if (item.field_type === "summary") summaryAdded = true;
      return item;
    }
    return false;
  });
};
