import { useState, useEffect } from "react";
import { Typography as MuiTypography } from "@mui/material";
import { setFieldsInText } from "app/shared/utils";

export const Title = (props) => {
  const { label, formValues, actionid, formspec } = props;

  const [text, setText] = useState("");

  useEffect(() => {
    setFieldsInText(
      label,
      formValues,
      {
        actionId: actionid,
        formSpec: formspec,
      },
      setText
    );
  }, [label, formValues, actionid, formspec]);

  useEffect(() => {
    try {
      // TEMPORARY CODE FOR OVERRDING A TITLE VALUE.
      formspec[0]["field_label"] = text;
    } catch (err) {
      console.log("error in title");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <MuiTypography variant="h1" component="span">
      {text}
    </MuiTypography>
  );
};
