// NOTE: This file is maintained in the parent project TDS-AS Common.
//       Your application should avoid modifying this file.

import { createRoot } from "react-dom/client";
import { AppRoot } from "AppRoot";
import { setupAxios } from "common";
import { AppProvider } from "app/appService";
import { AuthProviderSelector } from "AuthProviderSelector";
import reportWebVitals from "reportWebVitals";
import "common/i18n";

setupAxios();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  // <React.StrictMode>
  <AppProvider>
    <AuthProviderSelector>
      <AppRoot />
    </AuthProviderSelector>
  </AppProvider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
