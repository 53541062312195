import { useState, useEffect, memo } from "react";
import {
  TextField as MuiTextField,
  Typography as MuiTypography,
  Tooltip as MuiTooltip,
  FormHelperText as MuiFormHelperText,
  Grid as MuiGrid,
} from "@mui/material";
import { StyleApp } from "app/shared/ui/ui.styles";
import {
  shouldRenderElement,
  getCalculatedExp,
  addFieldValidation,
  getFormattedDate,
} from "app/shared/utils";
import { History as HistoryIcon, Info as InfoIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import isJSON from "is-json";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { useDirtyFlag } from "app/portal/DirtyFlagProvider";
import { Theme } from "common";

let clearTimeout;

export const TextField = memo((props) => {
  const {
    name,
    label,
    value,
    required,
    onChange,
    readOnly,
    helpertext,
    element,
    formFlatMap,
    formik,
    globalValueObj,
    setScrollDialog,
    links,
    updated,
    fieldAttr,
    content,
    fieldType,
    setRender,
    setInfoDialog,
    render,
    validation,
    yup,
    duplicateIds,
  } = props;

  const [inputValue, setInputValue] = useState(formFlatMap?.[name] || "");
  const [inputEvent, setInputEvent] = useState();
  const [exp, setExp] = useState("");
  const [fieldRenderedExp, setFieldRenderedExp] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const [fieldHelper, setFieldHelper] = useState(helpertext);
  const [touched, setTouched] = useState(false);
  const [fieldValidation, setFieldValidation] = useState(false);

  const { setIsDirty } = useDirtyFlag();

  const { textRows, maxLength } = isJSON(fieldAttr) ? JSON.parse(fieldAttr) : 0;

  const {
    type: validationType,
    test: validationScheme,
    message: validationMessage,
  } = isJSON(validation) ? JSON.parse(validation) : "";

  const { defaultValue = "" } = isJSON(fieldAttr) ? JSON.parse(fieldAttr) : {};

  const { t } = useTranslation();

  const iconSize = Boolean(updated) ? "37.5px" : "18.75px";
  const iconColor = Boolean(updated) ? "error" : "inherit";

  const ERROR = (touched && Boolean(formik.errors[name])) || fieldValidation;

  const FIELD_TEXT_COLOR =
    !ERROR && !fieldValidation ? Theme.palette.text.primary : undefined;

  const HELPER_TEXT_COLOR =
    (touched && Boolean(formik.errors[name])) || fieldValidation
      ? "error"
      : Theme.palette.text.secondary;

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation
    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_editable
      )
    );
  }, [
    element.field_id,
    element.field_rendered,
    element.field_editable,
    globalValueObj,
    formFlatMap,
    formik,
    element.field_value_type,
    render,
  ]);

  // ** THIS CODE NEED OPTIMIZATION, as WE COULD USE a single addFieldValidation.
  // This code wil be removed once single invocation of the function gets implemented.
  useEffect(() => {
    if (readOnly) {
      // Adding Validation elementary level
      addFieldValidation({
        name,
        // eslint-disable-next-line no-eval
        evaledExp: eval(fieldRenderedExp),
        required,
        touched,
        helpertext,
        validationType,
        validationScheme,
        validationMessage,
        t,
        formik,
        setFieldValidation,
        setFieldHelper,
        formFlatMap,
        globalValueObj,
        yup,
        element,
        readOnly,
        duplicateIds,
      });
    }
  }, [
    duplicateIds,
    element,
    fieldRenderedExp,
    formFlatMap,
    formik,
    globalValueObj,
    helpertext,
    name,
    readOnly,
    required,
    touched,
    t,
    validationMessage,
    validationScheme,
    validationType,
    yup,
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp);

      // Adding Validation elementary level
      addFieldValidation({
        name,
        evaledExp,
        required,
        touched,
        helpertext,
        validationType,
        validationScheme,
        validationMessage,
        t,
        formik,
        setFieldValidation,
        setFieldHelper,
        formFlatMap,
        globalValueObj,
        yup,
        element,
        readOnly,
        duplicateIds,
      });

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRenderedExp, name, render]);

  useEffect(() => {
    if (formik && element) {
      const expression = getCalculatedExp(
        element.field_calculation,
        {
          ...globalValueObj,
          ...formik.values,
          ...formFlatMap,
        },
        element.field_value_type
      );
      setExp(expression);
    }
  }, [formik, element, globalValueObj, formik.values, formFlatMap]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-unused-vars
      let getDate = getFormattedDate;

      // eslint-disable-next-line no-eval
      const evaledExp = eval(exp);
      const fieldDefaultValue =
        shouldRender && String(formFlatMap?.[name]).trim() === ""
          ? String(defaultValue)
          : "";
      let fieldValue = formFlatMap?.[name]; // setting default to the value of formFlatMap
      if (shouldRender) {
        if (exp) {
          fieldValue = evaledExp;
        } else if (fieldDefaultValue) {
          fieldValue = fieldDefaultValue;
        }
      }

      if (exp || String(fieldValue)) {
        globalValueObj[name] = fieldValue;
        formik.values[name] = fieldValue;
        formFlatMap[name] = fieldValue;
        setInputValue(fieldValue);
        if (clearTimeout) window.clearTimeout(clearTimeout);
        clearTimeout = window.setTimeout(() => {
          setRender((value) => value + 1);
        }, 300);
      }
    } catch (err) {
      console.log(name, err);
    }
  }, [
    exp,
    name,
    formik.values,
    formFlatMap,
    globalValueObj,
    readOnly,
    setRender,
    shouldRender,
    defaultValue,
  ]);

  useEffect(() => {
    if (inputEvent) {
      formik.values[name] = inputValue;
      globalValueObj[name] = inputValue;
      formFlatMap[name] = inputValue;

      if (clearTimeout) window.clearTimeout(clearTimeout);
      clearTimeout = window.setTimeout(() => {
        setRender((value) => value + 1);
      }, 1000);
    } else {
      const value = Boolean(element?.field_content)
        ? content
        : formFlatMap?.[name];
      setInputValue(value);
      formik.values[name] = value;
      globalValueObj[name] = value;
      formFlatMap[name] = value;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    inputValue,
    onChange,
    inputEvent,
    value,
    content,
    element?.field_content,
    formFlatMap,
    formik.errors,
    formik.values,
    globalValueObj,
    name,
    setRender,
    helpertext,
  ]);

  useEffect(() => {
    setRender((value) => value + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required]);

  if (!shouldRender) return null;

  return (
    <MuiGrid item xs={12} id={`field-${name}`}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <MuiTextField
          error={ERROR}
          id={name}
          name={name}
          label={label}
          required={required}
          disabled={readOnly}
          variant={!readOnly ? "outlined" : "standard"}
          fullWidth
          multiline={Boolean(textRows)}
          minRows={Boolean(textRows) ? textRows : 1}
          maxRows={Boolean(textRows) ? textRows : 1}
          value={inputValue || (readOnly ? " " : "")}
          onChange={(event) => {
            setInputValue(event.target.value || defaultValue);
            setInputEvent(event);
            setIsDirty(true);
          }}
          onBlur={() => {
            setTouched(true);
            return required && setRender((value) => value + 1);
          }}
          autoComplete="off"
          sx={getClasses({ readOnly, textRows })}
          InputLabelProps={{
            sx: { color: FIELD_TEXT_COLOR, fontSize: "15px" },
          }}
          inputProps={{ maxLength, style: { fontSize: "15px" } }}
        />
        {element && Boolean(element.field_info?.trim()) ? (
          <MuiTooltip title={t("globals.fieldInfo.tooltip")}>
            <InfoIcon
              onClick={() =>
                setInfoDialog({
                  show: true,
                  fieldLabel: label,
                  fieldContent: element.field_info,
                })
              }
              sx={{
                cursor: "pointer",
                fontSize: "18.75px",
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "21px" }}></span>
        )}
        {Boolean(links) ? (
          <MuiTooltip title={t("globals.fieldHistory.tooltip")}>
            <HistoryIcon
              color={iconColor}
              onClick={() =>
                setScrollDialog({
                  show: true,
                  fieldLabel: label,
                  links: links,
                  fieldType,
                })
              }
              sx={{
                marginLeft: "5px",
                cursor: "pointer",
                fontSize: iconSize,
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "1.5em" }}></span>
        )}
      </div>
      {fieldHelper && (
        <MuiFormHelperText component="div">
          <MuiTypography
            color={HELPER_TEXT_COLOR}
            variant="subtitle2"
            component="span"
            dangerouslySetInnerHTML={{
              __html: sanitize(fieldHelper, {
                ALLOWED_TAGS: AllowedTags,
              }),
            }}
            sx={StyleApp.links}
            style={{ fontSize: "13.125px" }}
          ></MuiTypography>
        </MuiFormHelperText>
      )}
    </MuiGrid>
  );
});

const getClasses = ({ readOnly, textRows }) => {
  if (readOnly && !Boolean(textRows)) {
    return StyleApp.disabled;
  } else if (readOnly && Boolean(textRows)) {
    return StyleApp.commentsHistory;
  }
  return undefined;
};
