import styled from "@emotion/styled";
import {
  AppBar as MuiAppBar,
  Paper as MuiPaper,
  Tab as MuiTab,
  Tabs as MuiTabs,
  Popper as MuiPopper,
} from "@mui/material";

const FONT_FAMILY = "Source sans pro";
const STANFORD_MEDIUM_GRAY = "#666666";
const STANFORD_TEXT_GRAY = "#333333";
const WHITE = "#FFFFFF";

export const NavbarAppBar = styled(MuiAppBar)`
  box-shadow: none;
  background-color: ${STANFORD_TEXT_GRAY};
  height: auto;
`;

export const NavbarPaper = styled(MuiPaper)`
  color: ${WHITE};
  margin-top: 10px;
  margin-left: -8px;

  .MuiList-root {
    background-color: ${STANFORD_TEXT_GRAY};
    outline: none;

    .MuiMenuItem-root {
      font-weight: 400;
      font-size: 15px;
      font-family: ${FONT_FAMILY};

      &:focus {
        background-color: ${STANFORD_MEDIUM_GRAY};
      }
    }

    .impersonation-item {
      color: ${STANFORD_TEXT_GRAY};
    }

    .submenu-item {
      &:hover {
        background: ${STANFORD_MEDIUM_GRAY};
        color: ${WHITE};
      }
    }

    .Mui-selected {
      background: ${STANFORD_MEDIUM_GRAY};
      color: ${WHITE};

      &:hover: {
        background: ${STANFORD_MEDIUM_GRAY};
        color: ${WHITE};
      }
    }
  }
`;

export const NavbarTab = styled(MuiTab)`
  display: inline-flex;
  width: auto;
  max-width: 264px;
  text-align: left;
  flex-direction: row-reverse;
  min-width: 0px;
  min-height: 40px;
  padding: 6px 0px;
  justify-content: flex-end;
  margin-left: 24px;
  margin-right: 9px;
  padding-left: 8px;
  padding-right: 8px;

  &:focus {
    color: transparent;
    background: ${STANFORD_MEDIUM_GRAY};
  }

  &:hover {
    background: ${STANFORD_MEDIUM_GRAY};
    color: ${WHITE};
  }

  .arrowIcon {
    display: inline-flex;
    vertical-align: middle;
    color: ${WHITE};
    font-size: 15px;
    font-weight: 400;
    height: 17.5px;
    text-transform: none;

    .MuiSvgIcon-root {
      font-size: 22.5px;
      align-self: center;
    }
  }

  .MuiTab-iconWrapper {
    display: contents;
    font-size: 15px;
    font-family: ${FONT_FAMILY};
    text-transform: none;
  }
`;

export const NavbarTabs = styled(MuiTabs)`
  min-height: 40px;
  height: auto;

  .MuiTabs-scroller {
    min-height: 40px;
    display: flex;
  }

  .MuiTabs-indicator {
    background-color: ${WHITE};
    height: 3px;
    position: absolute;

    @media (max-width: 765px) {
      top: ${(props) =>
        props.indicator !== "100%" ? props.indicator : "none"};
    }
  }

  .MuiTabs-flexContainer {
    flex-flow: wrap;

    @media (max-width: 765px) {
      display: block;
      margin: 0 auto;

      > button {
        justify-content: space-evenly;
        display: block;
        margin: 0 auto;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const NavbarPopper = styled(MuiPopper)`
  z-index: 2;
`;
