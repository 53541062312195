import { LinearProgress as MuiLinearProgress } from "@mui/material";
import { GridOverlay as MuiGridOverlay } from "@mui/x-data-grid-pro";

export const LoadingGridOverlay = () => {
  const loadingOverlayStyle = { top: 0, height: "100%", width: "100%" };

  return (
    <MuiGridOverlay>
      <div style={loadingOverlayStyle}>
        <MuiLinearProgress />
      </div>
    </MuiGridOverlay>
  );
};
