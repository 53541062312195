import { Tooltip as MuiTooltip, Link as MuiLink } from "@mui/material";
import { useRoutes } from "app/routes/routesService";
import { useDownload } from "app/shared/Download";
import { useNavigate } from "react-router-dom";

export const LinkItem = ({
  children,
  title,
  position,
  hidden,
  serviceUrl,
  display = "block",
  fileName = "",
  type = "",
}) => {
  const { setServiceUrl } = useRoutes();
  const { getDocument } = useDownload();
  const navigate = useNavigate();

  const getAction = () => {
    if (type === "DOCUMENT") {
      return getDocument(serviceUrl);
    } else if (type === "ATTACHMENT") {
      return navigate(serviceUrl);
    } else {
      return setServiceUrl({ apiUrl: serviceUrl });
    }
  };

  return (
    <MuiTooltip title={title} placement={position || "bottom"}>
      <MuiLink
        component="button"
        onClick={() => getAction()}
        sx={{
          lineHeight: 0,
          visibility: hidden ? "hidden" : "visible",
          display,
        }}
      >
        {children}
      </MuiLink>
    </MuiTooltip>
  );
};
