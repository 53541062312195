import { useApp } from "app/appService";
import axios from "axios";
import { useAlerts } from "common";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { getStoreValue, getFormattedDate } from "app/shared/utils";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FileTypes } from "app/shared/constants";

const FileAttachmentsContext = createContext();

const initialFileAttachmentState = {
  sortModel: {
    medonboard: [
      {
        field: "fileName",
        sort: "desc",
      },
    ],
    medact: [
      {
        field: "fileName",
        sort: "desc",
      },
    ],
    medleave: [
      {
        field: "fileName",
        sort: "desc",
      },
    ],
  },
};

export const FileAttachmentsProvider = ({ children }) => {
  const [fileAttachmentState, setFileAttachmentState] = useState(
    initialFileAttachmentState
  );
  return (
    <FileAttachmentsContext.Provider
      value={{ fileAttachmentState, setFileAttachmentState }}
    >
      {children}
    </FileAttachmentsContext.Provider>
  );
};

const actionsReducer = (state, payload) => {
  switch (payload.action) {
    case "SET_SORT_MODEL":
      return { ...state, ...{ sortModel: payload.value } };
    case "SET_FILE_ATTACHMENT_ITEMS":
      return {
        ...state,
        ...{
          fileAttachmentItems: payload.value.fileAttachmentItems,
          totalRows: payload.value.totalRows,
          gridHeight: payload.value.gridHeight,
          showUploadButton: payload.value.showUploadButton,
        },
      };
    case "SET_ERROR":
      return {
        ...state,
        ...{
          error: payload.value.error,
        },
      };
    default:
      return state;
  }
};

export const useFileAttachments = () => {
  const { tenantId } = useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [actionName, setActionName] = useState("");
  const { clearAlert, setAlert } = useAlerts();
  const { actionId } = useParams();
  const [refresh, setRefresh] = useState(0);
  const { t } = useTranslation();

  /** File Attachments useReducer */
  const [fileAttachments, dispatch] = useReducer(actionsReducer, {
    sortModel:
      getStoreValue("file-attachment-sort")?.sortModel ||
      getSortModel(useContext, tenantId),
    totalRows: 0,
    gridHeight: 150,
    fileAttachmentItems: [],
    error: false,
  });

  const loadAllFileAttachments = useCallback(
    async (actionId, sortModelObj) => {
      try {
        clearAlert();
        setIsLoading(true);

        // To get mapped column for input column with UI column.
        let sortBy = getMappedColumn(sortModelObj[0].field);
        let sortOrder = sortModelObj[0].sort;

        const params = {
          s: `${sortBy}:${sortOrder}`,
        };

        const response = await axios({
          url: `/actions/${actionId}/documents`,
          method: "GET",
          params,
        });

        setActionName(response.data?.meta?._action_name || "");

        if (response.data.count) {
          const fileAttachmentItems = response.data.values.map(
            (fileAttachment, index) =>
              Object.assign(
                {},
                {
                  id: index,
                  documentId: fileAttachment.documentId,
                  fileType: t(FileTypes[fileAttachment.type]),
                  fileName: fileAttachment.fileName,
                  updatedBy: fileAttachment.updatedBy,
                  updatedAt: getFormattedDate(fileAttachment.updated),
                  links: fileAttachment._links,
                }
              )
          );

          dispatch({
            action: "SET_FILE_ATTACHMENT_ITEMS",
            value: {
              fileAttachmentItems,
              totalRows: response.data.count,
              gridHeight: (response.data.count + 3) * 52,
              showUploadButton: response.data._links?.create,
            },
          });
        } else {
          dispatch({
            action: "SET_FILE_ATTACHMENT_ITEMS",
            value: {
              fileAttachmentItems: [],
              totalRows: 0,
              gridHeight: 3 * 52,
              showUploadButton: response.data._links?.create,
            },
          });
        }
      } catch (error) {
        console.log(error);
        dispatch({
          action: "SET_ERROR",
          value: { error: true },
        });
        setAlert("error", error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [clearAlert, setAlert, t]
  );

  useEffect(() => {
    loadAllFileAttachments(actionId, fileAttachments.sortModel);
  }, [actionId, loadAllFileAttachments, fileAttachments.sortModel, refresh]);

  // Fn to return useContext for File Attachment

  return {
    loadAllFileAttachments,
    fileAttachmentState: useContext(FileAttachmentsContext).fileAttachmentState,
    setFileAttachmentState: useContext(FileAttachmentsContext)
      .setFileAttachmentState,
    isLoading,
    fileAttachments,
    dispatch,
    setRefresh,
    actionName,
  };
};

export const saveFileAttachment = async (
  initialUploadDocumentErrorState,
  fileExists,
  actionId,
  file,
  fileAttachmentType,
  setLoading,
  setUploadDocumentError,
  handleClose,
  setAlert,
  clearAlert,
  setRefresh,
  setConfirmationDialog,
  resetForm,
  t,
  documentId
) => {
  try {
    clearAlert();
    setUploadDocumentError({ ...initialUploadDocumentErrorState });
    setLoading(true);
    // creating formData
    const formData = new FormData();

    // Splitting the filename to split to get the corresponding array
    // of all possible separated string by `.`
    const nameString = file.name.split(".");

    // Popping out the last element which would be file extension
    nameString.pop();

    // Creating filename by joining the characters of string
    const filename = nameString.join(".");

    // setting up file data
    formData.set("uploadedFile", file);
    formData.set("id", actionId);
    formData.set("fileName", filename);
    formData.set("type", fileAttachmentType);
    // axios call to upload file
    const response = await axios({
      method: fileExists ? "put" : "post",
      url: `/actions/${actionId}/documents/${fileExists ? documentId : ""}`,
      data: formData,
      timeout: 20000,
    });

    setRefresh((value) => value + 1);

    // settings arguments to message page for showing file upload success
    setTimeout(() => {
      setAlert(
        "success",
        t(
          `fileAttachments.uploadAttachmentsDialog.notification.success.${
            fileExists ? "replace" : "upload"
          }`,
          {
            fileName: response.data.fileName,
            createdBy: response.data.updatedBy,
          }
        ),
        true
      );
    }, 500);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    // finally setting up loading to false
    setLoading(false);

    // Resetting form
    resetForm();

    // setting confirmation dialog to false
    setConfirmationDialog(false);

    // closing the modal up after success
    handleClose();
  }
};

export const updateFileAttachment = async ({
  actionId,
  documentId,
  dataToUpdate,
  setLoading,
  clearAlert,
  setAlert,
  handleClose,
  setRefresh,
  t,
  oldFileName,
}) => {
  try {
    clearAlert();
    setLoading(true);

    await axios({
      method: "patch",
      url: `/actions/${actionId}/documents/${documentId}`,
      data: dataToUpdate,
    });

    setRefresh((value) => value + 1);
    // settings arguments to message page for showing file upload success
    setTimeout(() => {
      setAlert(
        "success",
        t("fileAttachments.renameDialog.notification.success", {
          oldFileName,
          newFileName: `${dataToUpdate.fileName}.${oldFileName.split(".")[1]}`,
        }),
        true
      );
    }, 500);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    // closing the modal up after success
    handleClose();
    // finally setting up loading to false
    setLoading(false);
  }
};

export const deleteFileAttachment = async ({
  actionId,
  documentId,
  setLoading,
  clearAlert,
  setAlert,
  handleClose,
  setRefresh,
  t,
  oldFileName,
}) => {
  try {
    clearAlert();
    setLoading(true);

    await axios({
      method: "delete",
      url: `/actions/${actionId}/documents/${documentId}`,
    });

    setRefresh((value) => value + 1);
    // settings arguments to message page for showing file delete success
    setTimeout(() => {
      setAlert(
        "success",
        t("fileAttachments.deleteDialog.notification.success", {
          oldFileName,
        }),
        true
      );
    }, 500);
  } catch (error) {
    setAlert("error", error.message);
  } finally {
    // closing the modal up after success / error
    handleClose();
    // finally setting up loading to false
    setLoading(false);
  }
};

const getSortModel = (context, tenantId) => {
  const sortModel = context(FileAttachmentsContext).fileAttachmentState
    .sortModel;
  return sortModel?.[tenantId] ?? sortModel;
};

const getMappedColumn = (inputColumn) => {
  if (inputColumn === "fileName") {
    return "name";
  } else if (inputColumn === "fileType") {
    return "type";
  } else if (inputColumn === "updatedAt") {
    return "updated";
  }
  return inputColumn;
};
