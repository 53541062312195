import { Alert, Body, Footer, Header, useAlerts } from "common";
import { useApp } from "app/appService";
import { ActionsProvider } from "app/actions/actionsService";
import { usePortalAuth } from "app/portal/portalAuthService";
import { PortalRoutes } from "app/PortalRoutes";
import { PortalLogin } from "app/portal/PortalLogin";
import { useTranslation } from "react-i18next";
import { RoutesProvider } from "./routes/routesService";
import ReactGA from "react-ga4";

export const PortalApp = () => {
  const { bundleId } = useApp();
  const { isAuthenticated, isLoading } = usePortalAuth();
  const { t } = useTranslation();
  const { alert } = useAlerts();

  // Start Google Analytics after the portal user is authenticated
  if (!isLoading && isAuthenticated) {
    ReactGA.initialize(window.GA_MEASUREMENT_ID);
  }

  if (isLoading) return null;

  return (
    <>
      <Header
        logoTitle={t(`app.${bundleId}.logoTitle`)}
        logoSubtitle={t(`app.${bundleId}.logoSubtitle`)}
      />
      <Body>
        {alert.exists && <Alert />}
        {isAuthenticated ? (
          <ActionsProvider>
            <RoutesProvider>
              <PortalRoutes />
            </RoutesProvider>
          </ActionsProvider>
        ) : (
          <PortalLogin />
        )}
      </Body>
      <Footer
        copyrightYear={t("app.copyrightYear")}
        buildVersion={process.env.REACT_APP_VERSION}
      />
    </>
  );
};
