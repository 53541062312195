import styled from "@emotion/styled";
import { Theme } from "common/components/Theme/Theme";

export const DevelopmentInfoContainer = styled.div`
  .development-server-name {
    background: ${Theme.palette.primary.medium};
    color: ${Theme.palette.common.white};
    font-size: 14px;
    padding: 4px 8px;
  }
`;
