import { Alert, Body, Footer, Header, useAlerts, useUsers } from "common";
import { useApp } from "app/appService";
import { ActionsProvider } from "app/actions/actionsService";
import { AppRoutes } from "app/AppRoutes";
import { useTranslation } from "react-i18next";
import { RoutesProvider } from "app/routes/routesService";
import { Permissions } from "app/shared/constants";
import ReactGA from "react-ga4";

export const AdminApp = () => {
  const { bundleId, tenantId } = useApp();
  const { alert } = useAlerts();
  const { t } = useTranslation();
  const { currentUser, accessDenied } = useUsers();

  // Start Google Analytics after the user is authenticated and authorized
  if (currentUser.isLoaded && !accessDenied) {
    ReactGA.initialize(window.GA_MEASUREMENT_ID);
  }

  // Setting tenantId in Session Storage
  sessionStorage.tenantId = tenantId;

  return (
    <>
      <Header
        logoTitle={t(`app.${bundleId}.logoTitle`)}
        logoSubtitle={t(`app.${bundleId}.logoSubtitle`)}
        userDisplayName={currentUser.displayName}
        impersonateCapable={
          currentUser.permissions &&
          Permissions.IMPERSONATE_USER in currentUser.permissions
        }
      />
      <Body>
        <ActionsProvider>
          {alert.exists && <Alert />}
          <RoutesProvider>
            <AppRoutes />
          </RoutesProvider>
        </ActionsProvider>
      </Body>
      <Footer
        copyrightYear={t("app.copyrightYear")}
        buildVersion={process.env.REACT_APP_VERSION}
      />
    </>
  );
};
