import { Actions } from "app/actions/Actions";
import { useApp } from "app/appService";
import { Form } from "app/forms/Form";
import { useRoutes } from "app/routes/routesService";
import { FormList } from "app/shared/constants";
import { AccessDenied, useUsers } from "common";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { DocumentLink } from "app/actions/DocumentLink";
import { FileAttachments } from "app/file-attachments/FileAttachments";
import { FileAttachmentsProvider } from "app/file-attachments/fileAttachmentsService";

export const AppRoutes = () => {
  const { tenantId, bundleId } = useApp();

  // Getting currentUser object from API `/users/me` using custom hook
  const { currentUser, accessDenied } = useUsers();

  // Getting translation object to get translation for custom message.
  const { t } = useTranslation();

  const { serviceUrl } = useRoutes();

  // Do not continue if the current user is not loaded yet from users/me
  if (!currentUser.isLoaded) {
    return null;
  }

  // Prevent access if the user is not authorized to use the application
  if (accessDenied) {
    return <AccessDenied supportEmail={t(`app.${bundleId}.supportEmail`)} />;
  }

  return (
    <Routes>
      {/* Actions */}
      <Route path="/" exact={true} element={<Actions />} />
      <Route path="/actions" exact={true} element={<Actions />} />
      {/* <Route path="/" exact={true} elememt={<Actions />} /> */}

      {/* Document Viewer */}
      <Route
        path="/documents/:actionId/:templateId"
        exact={true}
        element={<DocumentLink />}
      />

      {/* File Attachments */}
      <Route
        path="/file-attachments/:actionId/:name?"
        exact={true}
        element={
          <FileAttachmentsProvider>
            <FileAttachments />
          </FileAttachmentsProvider>
        }
      />

      {/* ROUTE - FORM LINKS (in case of form gets accessed via email or direct URL) */}
      {FormList[tenantId].map((formName, index) => {
        // Remove `_form` from the end of the formName
        const newFormName = formName.substr(0, formName.lastIndexOf("_"));
        // Replacing all `_` with `-` and form a route
        const route = newFormName.replace(/_/g, "-");
        // Rendering Form by creatin API URL using actionId from URL

        return (
          <Route
            path={`/${route}/:actionId?`}
            key={index}
            exact={true}
            element={<Form apiUrl={serviceUrl.apiUrl} formName={formName} />}
          />
        );
      })}
    </Routes>
  );
};
