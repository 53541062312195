import styled from "@emotion/styled";
import { Theme } from "common/components/Theme/Theme";

export const HeaderContainer = styled.div`
  color: ${Theme.palette.text.primary};
  line-height: 20px;

  .header-local {
    min-height: 92px;
    width: 100%;
    margin: 0;
    position: relative;
    z-index: 2;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
    border-top: 1px solid #ffffff;
    border-bottom: 1px solid transparent;
    display: flex;
    justify-content: space-between;
    font-family: ${Theme.typography.fontFamily};

    .container {
      margin-left: 24px;
      margin-right: unset;
      padding-left: 0px;
      margin-top: 24px;
      margin-bottom: 24px;
      padding-right: 0px;

      .skip-to-links {
        .sr-only {
          border-color: rgb(0, 124, 146);
          border: 0;
          clip: rect(0, 0, 0, 0);
          color: rgb(0, 124, 146);
          height: 1px;
          margin: -1px;
          overflow-wrap: break-word;
          overflow: hidden;
          padding: 0;
          position: absolute;
          width: 1px;
        }
      }

      #headerContent {
        float: none;
        width: auto;
        clear: both;
        zoom: 1;

        &::before {
          content: " "
          display: table;
        }

        &::after {
          content: " ";
          display: table;
          clear: both;
        }

        @media (min-width: 479px) {
          float: right;
          clear: none;
          width: 50%;
          position: absolute;
          bottom: 7px;
          right: 8px;
        }

        @media (min-width: 768px) {
          width: 30%;
        }

        @media (min-width: 992px); {
          bottom: 0
        }

        p {
          display: none;
          margin: 0px 0px 8px 0px;
          padding: 0;
          color: ${Theme.palette.grey[400]};
          font-size: 14px;
          font-style: italic;
          text-align: right;
          line-height: 16px;

          @media (min-width: 992px) {
            display: block;
          }
        }

        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          float: right;
        }

        li {
          float: left;
          margin-left: 8px;
        }
      }
    }

    .display-name {
      color: ${Theme.palette.text.primary};
      font-size: 15px;
      margin: 10px 24px;
    }

    .display-name-button {
      text-transform: none;
      color: ${Theme.palette.text.primary};
    }

    .social-links {
      bottom: -30px;
      position: absolute;
      right: 8px;

      @media (min-width: 992px) {
        bottom: 0;
        width: 168px;
      }

      &::before {
        content: " ";
        display: table;
      }

      &::after {
        content: " ";
        display: table;
        clear: both;
      }

      p {
        display: none;

        @media (min-width: 992px) {
          display: block;
          margin: 0 0 0.57142857142857rem 0;
          padding: 0;
          color: #999999;
          font-size: 14px;
          font-style: italic;
          text-align: right;
          line-height: 16px;
        }
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        float: right;
      }

      li {
        float: left;
        margin-left: 8px;
      }
    }

    @media (max-width: 991px) {
      min-height: 0;
    }
  }
`;
