import { useApp } from "app/appService";
import { FormList } from "app/shared/constants";
import { Route, Routes } from "react-router-dom";
import { PortalForm } from "app/portal/PortalForm";

export const PortalRoutes = () => {
  const { bundleId } = useApp();

  return (
    //Default Route for intake form
    <Routes>
      <Route path="/intake/:actionId?" exact={true} element={<PortalForm />} />

      {FormList[bundleId].map((formName, index) => {
        // Remove `_form` from the end of the formName
        const newFormName = formName.substr(0, formName.lastIndexOf("_"));
        // Replacing all `_` with `-` and form a route
        const route = newFormName.replace(/_/g, "-");
        // Rendering Form by creatin API URL using actionId from URL

        return (
          <Route
            path={`/${route}/:actionId?`}
            key={index}
            exact={true}
            element={<PortalForm />}
          />
        );
      })}
    </Routes>
  );
};
