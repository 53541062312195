import { useState, useEffect } from "react";
import {
  Box as MuiBox,
  Typography as MuiTypography,
  Grid as MuiGrid,
} from "@mui/material";
import { setFieldsInText, shouldRenderElement } from "app/shared/utils";

export const Section = (props) => {
  let {
    name,
    label,
    formValues,
    actionid,
    formspec,
    element,
    formFlatMap,
    globalValueObj,
    formik,
    render,
  } = props;
  const [text, setText] = useState("");
  const [fieldRenderedExp, setFieldRenderedExp] = useState("");
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation
    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_editable
      )
    );
  }, [
    element.field_id,
    element.field_rendered,
    element.field_editable,
    globalValueObj,
    formFlatMap,
    formik,
    element.field_value_type,
    render,
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp); // SYNC and ASYNC

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
  }, [fieldRenderedExp, name]);

  useEffect(() => {
    setFieldsInText(
      label,
      formValues,
      {
        actionId: actionid,
        formSpec: formspec,
      },
      setText
    );
  }, [label, formValues, actionid, formspec]);

  if (!shouldRender) return null;

  return (
    <MuiGrid item xs={12}>
      <MuiBox>
        <MuiTypography variant={"h2"} component="span">
          {text}
        </MuiTypography>
      </MuiBox>
    </MuiGrid>
  );
};
