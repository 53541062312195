import { useState, useEffect } from "react";
import {
  FormGroup as MuiFormGroup,
  FormControlLabel as MuiFormControlLabel,
  Checkbox as MuiCheckbox,
  Typography as MuiTypography,
  Box as MuiBox,
  Tooltip as MuiTooltip,
  FormHelperText as MuiFormHelperText,
  Grid as MuiGrid,
  FormControl as MuiFormControl,
} from "@mui/material";
import { StyleApp } from "app/shared/ui/ui.styles";
import {
  setFieldsInText,
  replaceFieldCalculationValues,
  shouldRenderElement,
  addFieldValidation,
} from "app/shared/utils";
import { useTranslation } from "react-i18next";
import { History as HistoryIcon, Info as InfoIcon } from "@mui/icons-material";
import isJSON from "is-json";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { useDirtyFlag } from "app/portal/DirtyFlagProvider";
import { Theme } from "common";

let timeout;

export const Checkbox = (props) => {
  const [text, setText] = useState();
  const [exp, setExp] = useState("");
  const [fieldRenderedExp, setFieldRenderedExp] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const [inputEvent, setInputEvent] = useState();

  let { label, formValues } = props;
  const {
    name,
    options,
    value,
    required,
    readOnly,
    actionid,
    formspec,
    element,
    setScrollDialog,
    setInfoDialog,
    links,
    updated,
    helpertext,
    formFlatMap,
    globalValueObj,
    formik,
    render,
    setRender,
    validation,
    yup,
    duplicateIds,
    fieldAttr,
  } = props;

  const [inputValue, setInputValue] = useState(formValues?.[name] || "");
  const { t } = useTranslation();
  const [fieldHelper, setFieldHelper] = useState(helpertext);
  const [fieldValidation, setFieldValidation] = useState(false);

  const { setIsDirty } = useDirtyFlag();

  const {
    type: validationType,
    test: validationScheme,
    message: validationMessage,
  } = isJSON(validation) ? JSON.parse(validation) : "";

  const { defaultValue = "" } = isJSON(fieldAttr) ? JSON.parse(fieldAttr) : {};

  const iconSize = Boolean(updated) ? "37.5px" : "18.75px";
  const iconColor = Boolean(updated) ? "error" : "inherit";

  const ERROR = fieldValidation;
  const FIELD_TEXT_COLOR = !fieldValidation
    ? Theme.palette.text.primary
    : undefined;
  const HELPER_TEXT_COLOR = fieldValidation
    ? "error"
    : Theme.palette.text.secondary;

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation
    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_editable
      )
    );
  }, [
    element.field_id,
    element.field_rendered,
    element.field_editable,
    globalValueObj,
    formFlatMap,
    formik,
    element.field_value_type,
    render,
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp); // SYNC and ASYNC

      addFieldValidation({
        name,
        evaledExp,
        required,
        touched: false,
        helpertext,
        validationType,
        validationScheme,
        validationMessage,
        t,
        formik,
        setFieldValidation,
        setFieldHelper,
        formFlatMap: formValues,
        globalValueObj,
        yup,
        element,
        duplicateIds,
      });

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRenderedExp, name, render]);

  useEffect(() => {
    if (formik && element) {
      const calculatedExp = getCalculatedExp(
        element.field_calculation,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_value_type,
        element.field_editable
      );
      setExp(calculatedExp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values, globalValueObj]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(exp);

      const fieldDefaultValue = shouldRender
        ? getOptionsDefaultValueMap(options, defaultValue)
        : "";

      const fieldValue =
        shouldRender && String(formFlatMap?.[name]).trim() !== ""
          ? evaledExp || formFlatMap?.[name]
          : fieldDefaultValue;

      if (exp || String(fieldValue)) {
        globalValueObj[name] = fieldValue;
        formik.values[name] = fieldValue;
        formFlatMap[name] = fieldValue;
        setInputValue(fieldValue);
      }
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exp, name, shouldRender, defaultValue]);

  useEffect(() => {
    setFieldsInText(
      label,
      formValues,
      {
        actionId: actionid,
        formSpec: formspec,
      },
      setText
    );
  }, [label, formValues, actionid, formspec]);

  useEffect(() => {
    // Fill with value or formValues
    setIsChecked(getIsCheckedValue(value || formValues?.[name]));
  }, [value, formValues, name]);

  useEffect(() => {
    if (inputEvent) {
      if (timeout) window.clearTimeout(timeout);
      // main element
      formik.values[name] = inputValue;
      globalValueObj[name] = inputValue;

      const idList = Array.isArray(inputValue)
        ? inputValue.map((item) => item?.id ?? item)
        : inputValue;

      // id list attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_id_list`] = idList;
      globalValueObj[`${name}_id_list`] = idList;

      // id list attributes
      // As per new specification
      formik.values[`${name}.ids`] = idList;
      globalValueObj[`${name}.ids`] = idList;

      if (idList.length) {
        delete formik.errors[name];
      }

      timeout = window.setTimeout(() => {
        setRender((value) => value + 1);
      }, 300);
    } else {
      setInputValue(formValues?.[name]);
      formik.values[name] = inputValue;
      globalValueObj[name] = inputValue;

      const idList = Array.isArray(inputValue)
        ? inputValue.map((item) => item?.id ?? item)
        : inputValue;

      // id list attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_id_list`] = idList;
      globalValueObj[`${name}_id_list`] = idList;

      // id list attributes
      // As per new specification
      formik.values[`${name}.ids`] = idList;
      globalValueObj[`${name}.ids`] = idList;

      if (idList.length) {
        delete formik.errors[name];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, inputValue, inputEvent, value]);

  const [isChecked, setIsChecked] = useState(() => getIsCheckedValue(value));

  const toggleCheckboxValue = (changedValue) => {
    const valueIndex = isChecked.indexOf(changedValue);
    if (valueIndex !== -1) {
      isChecked.splice(valueIndex, 1);
    } else {
      isChecked.push(changedValue);
    }

    formik.values[name] = isChecked;
    globalValueObj[name] = isChecked;
    formFlatMap[name] = isChecked;

    if (Array.isArray(isChecked) && isChecked.length)
      delete formik.errors[name];
    else {
      if (required)
        formik.errors[name] = `${element.field_label} is a required field`;
    }
    setInputValue(
      Array.isArray(isChecked) && isChecked.length ? isChecked : ""
    );
  };

  if (!options.length) return "";

  if (!shouldRender) return null;

  return (
    <MuiGrid item xs={12} id={`field-${name}`}>
      <MuiTypography
        component="span"
        dangerouslySetInnerHTML={{
          __html: sanitize(`${text || ""} ${required ? "*" : ""}`, {
            ALLOWED_TAGS: AllowedTags,
          }),
        }}
        gutterBottom
        variant="body2"
        sx={StyleApp.links}
      ></MuiTypography>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <MuiBox
          sx={{
            width: "100%",
          }}
        >
          <MuiFormControl error={ERROR} component="fieldset">
            <MuiFormGroup>
              {options.map((item, index) => {
                return (
                  <MuiFormControlLabel
                    control={
                      <MuiCheckbox
                        name={name}
                        value={item.id}
                        color="primary"
                        onChange={(event, changedValue) => {
                          setInputEvent(event);
                          toggleCheckboxValue(item.id);
                          setIsDirty(true);
                        }}
                        checked={
                          isChecked.indexOf(item.id) !== -1 ? true : false
                        }
                        disabled={readOnly}
                      />
                    }
                    sx={StyleApp.disabledCheckbox}
                    labelPlacement="end"
                    label={
                      <MuiTypography
                        error={FIELD_TEXT_COLOR}
                        variant="body2"
                        component="span"
                        dangerouslySetInnerHTML={{
                          __html: sanitize(item.text, {
                            ALLOWED_TAGS: AllowedTags,
                          }),
                        }}
                        sx={StyleApp.links}
                      ></MuiTypography>
                    }
                    key={index}
                  />
                );
              })}
            </MuiFormGroup>
          </MuiFormControl>
        </MuiBox>
        {element && Boolean(element.field_info.trim()) && (
          <MuiTooltip title={t("globals.fieldInfo.tooltip")}>
            <InfoIcon
              onClick={() =>
                setInfoDialog({
                  show: true,
                  fieldLabel: label,
                  fieldContent: element.field_info,
                })
              }
              sx={{
                cursor: "pointer",
                fontSize: "18.75px",
              }}
            />
          </MuiTooltip>
        )}
        {Boolean(links) && (
          <MuiTooltip title={t("globals.fieldHistory.tooltip")}>
            <HistoryIcon
              color={iconColor}
              onClick={() =>
                setScrollDialog({
                  show: true,
                  links: links,
                  fieldLabel: label,
                })
              }
              sx={{
                marginLeft: "5px",
                fontSize: iconSize,
              }}
            />
          </MuiTooltip>
        )}
      </div>
      {fieldHelper && (
        <MuiFormHelperText component="div">
          <MuiTypography
            variant="subtitle2"
            component="span"
            color={HELPER_TEXT_COLOR}
            sx={{ fontSize: "13.125px" }}
          >
            {fieldHelper}
          </MuiTypography>
        </MuiFormHelperText>
      )}
    </MuiGrid>
  );
};

const getIsCheckedValue = (value) => {
  if (!value || value === "{}" || value === "[]") return [];
  return value.map((item) => item?.id ?? item);
};

const getCalculatedExp = (
  fieldCalculation,
  formValueObj,
  valueType,
  fieldEditable
) => {
  return replaceFieldCalculationValues(
    fieldCalculation,
    formValueObj,
    valueType,
    fieldEditable
  );
};

const getOptionsDefaultValueMap = (options, defaultValue) =>
  Array.isArray(options) && Array.isArray(defaultValue)
    ? defaultValue.map((item) => {
        return options.find((option) => (option.id === item ? option : null));
      })
    : "";
