import { useState, useEffect, useRef } from "react";
import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  CircularProgress as MuiCircularProgress,
  Typography as MuiTypography,
  Link as MuiLink,
  Popover as MuiPopover,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "app/shared/utils";
import {
  EventTypes,
  FileTypes,
  DeliveryStatus,
  FormStatus,
} from "app/shared/constants";
import { useApp } from "app/appService";

export const ActionHistory = ({ params, setParams, loadActionHistory }) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [actionId, setActionId] = useState(() => params.actionId);

  const { t } = useTranslation();

  const handleClose = () => {
    setShow(false);
    setParams({ ...params, show: false });
  };

  useEffect(() => {
    setShow(params.show);
    params.show ? setActionId(params.actionId) : setActionId(0);
  }, [params.show, params.actionId]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (show) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [show]);

  useEffect(() => {
    actionId &&
      loadActionHistory({
        actionId,
        setIsLoading,
        params,
        setParams,
        setItems,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionId, loadActionHistory]);

  return (
    <MuiDialog
      open={params.show}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="history-dialog-title"
      aria-describedby="history-dialog-description"
      maxWidth="md"
    >
      <MuiDialogTitle id="history-dialog-title">{params.title}</MuiDialogTitle>
      <MuiDialogContent dividers={true}>
        {!isLoading ? (
          <MuiTableContainer>
            <ContentTable
              columns={params.columns}
              rows={items}
              params={params}
            />
          </MuiTableContainer>
        ) : (
          <div style={{ textAlign: "center" }}>
            <MuiCircularProgress />
          </div>
        )}
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton onClick={handleClose} color="primary">
          {t("forms.historyDialog.button.close")}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

const ContentTable = ({ columns, rows, params }) => {
  return rows.length ? (
    <MuiTable stickyHeader aria-label="sticky table">
      <MuiTableHead>
        <MuiTableRow>
          {columns.map((column, index) => (
            <MuiTableCell
              key={`head-${index}`}
              align={column.align}
              sx={{ minWidth: column.minWidth }}
            >
              {column.label}
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
      <MuiTableBody>
        {rows.map((row, index) => {
          return (
            <MuiTableRow
              hover
              role="checkbox"
              tabIndex={-1}
              key={`row-${index}`}
            >
              <MuiTableCell align={"left"}>
                <EventType
                  id={index}
                  type={row.eventType}
                  details={row.eventDetails}
                />
              </MuiTableCell>
              <MuiTableCell align={"left"}>
                {getFormattedDate(row.eventDateTime)}
              </MuiTableCell>
              <MuiTableCell align={"left"}>{row.triggeredBy}</MuiTableCell>
            </MuiTableRow>
          );
        })}
      </MuiTableBody>
    </MuiTable>
  ) : (
    <MuiTypography variant={"body2"}>{params.noContentText}</MuiTypography>
  );
};

const EventType = ({ id, type, details }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOnClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { t } = useTranslation();
  const { tenantId } = useApp();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const eventNameStr = `dashboard.action.list.actionHistory.${tenantId}.rows.eventTypes.${EventTypes[type]}`;
  const detailsObj = {
    ...details,
    deliveryStatus: t(DeliveryStatus[details?.deliveryStatus]) || undefined,
    fileType:
      details?.type && details?.type?.toLowerCase() === "term_sheet_non_final"
        ? "(non-final)"
        : t(FileTypes[details?.type]) || undefined,
    fileName: details?.name || undefined,
    previousFileName: details?.previous_name || undefined,
    changeValue: FormStatus?.[details?.changeValue]
      ? t(FormStatus?.[details?.changeValue])
      : details?.changeValue,
  };

  return (
    <MuiTypography variant="body2">
      {t(
        details?.template
          ? `${eventNameStr}.${details.template}`
          : eventNameStr,
        detailsObj
      )}{" "}
      {details?.recipients && (
        <>
          <MuiLink
            onClick={handleOnClick}
            underline="always"
            component={"button"}
          >
            {`(${t("globals.recipientsText")})`}
          </MuiLink>
          <MuiPopover
            id={`action-history-popover-${id}`}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <MuiTypography>
              <ul style={{ listStyleType: "none", padding: "0px 10px" }}>
                {String(details.recipients)
                  .split(";")
                  .sort()
                  ?.map((item, index) => (
                    <li key={`email-${index}`}>{item}</li>
                  ))}
              </ul>
            </MuiTypography>
          </MuiPopover>
        </>
      )}
    </MuiTypography>
  );
};
