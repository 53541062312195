import { Theme } from "common";

export const StyleApp = {
  surveyinstructions: {
    "& p": {
      fontSize: 17,
      fontWeight: 500,
    },
    fontSize: "15px",
    fontWeight: 500,
    "& a": {
      textDecoration: "none",
      color: "#007c92",
      cursor: "pointer",
    },
  },
  disabled: {
    width: "100%",
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "none",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: Theme.palette.text.primary,
      fontSize: "15px",
      transform: "translate(0, -1.5px) scale(0.75);",
    },
    "& .MuiInputBase-root.Mui-disabled": {
      marginTop: "16px",
      "& input": {
        fontSize: "15px",
        padding: "6px 0px 7px 0px",
        WebkitTextFillColor: Theme.palette.text.primary,
      },
      "& .MuiInputAdornment-root": {
        display: "none",
      },
    },
    "& .Mui-disabled .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  disabledRadio: {
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: Theme.palette.text.primary,
    },
  },
  disabledCheckbox: {
    "& .MuiFormControlLabel-label.Mui-disabled": {
      color: Theme.palette.text.primary,
    },
    marginBottom: "10px",
  },
  commentsHistory: {
    "& .MuiFormLabel-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)",
    },
    "& .MuiInput-underline.Mui-disabled:before": {
      borderBottomStyle: "none",
    },
    "& .MuiInput-underline.Mui-disabled": {
      border: "1px solid grey",
      padding: "4px",
      borderRadius: "3px",
      color: Theme.palette.text.primary,
      fontSize: "15px",
    },
    "& .MuiInputBase-input.Mui-disabled": {
      WebkitTextFillColor: Theme.palette.text.primary,
    },
  },
  links: {
    "& a": {
      textDecoration: "none",
      color: "#007c92",
      cursor: "pointer",
    },
  },
};
