export const MuiDataGrid = {
  "& .MuiDataGrid-window": {
    overflowY: "hidden !important",
  },
  "& .MuiDataGrid-columnHeader": {
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-cell": {
    display: "flex",
    alignItems: "center",
    "&:focus": {
      outline: "none",
    },
  },
  backgroundColor: "#fff",
  "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none",
  },
  "& .MuiDataGrid-columnHeader--last .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-scrollbar--horizontal ": {
    display: "none",
  },
  "& .MuiDataGrid-filler": {
    display: "none",
  },
  "&& .MuiIconButton-root": {
    width: "22.875px",
    height: "22.875px",
    margin: 0,
    padding: "3px",
  },
  "& .MuiDataGrid-iconButtonContainer": { paddingTop: "2px" },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "visible",
  },
};

export const tooltip = {
  lineHeight: 0,
  marginRight: "5px",
  "& .MuiSvgIcon-root": {
    fontSize: "22.5px",
  },
};
export const fileAttachmentWrapper = {
  width: "100%",
  overflowX: "auto",
  paddingBottom: "16px",
  paddingTop: "16px",
  backgroundColor: "#FFFFFF",
  height: "100%",
};
export const fileAttachmentContainer = {
  width: "1072px",
  margin: "0 auto",
};
export const wrapIcon = {
  display: "inline-flex",
  verticalAlign: "middle",
};
export const listContainer = {
  marginLeft: "auto",
};
