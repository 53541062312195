import { useState, useEffect } from "react";
import {
  Tabs as MuiTabs,
  Tab as MuiTab,
  Container as MuiContainer,
  Box as MuiBox,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Link as MuiLink,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormBody } from "app/forms/FormBody";
import StartHereChip from "./StartHereChip";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { CandidatePortal } from "app/shared/constants";
import { usePortalAuth } from "app/portal/portalAuthService";
import { FormsProvider } from "app/forms/formsService";
import { useDirtyFlag } from "./DirtyFlagProvider";

const pageStyle = {
  container: {
    maxWidth: 700,
    marginBottom: 3,
  },
  chipWrapper: {
    lineHeight: 1.5,
    width: "100%",
  },
  chipTab: {
    padding: 0,
  },
  chip: {
    float: "right",
    textTransform: "none",
    fontWeight: "normal",
  },
  link: {
    textDecoration: "none",
    color: "#007c92",
    cursor: "pointer",
  },
  tabs: {
    "& .MuiTypography-root": {
      fontWeight: 600,
      "& .MuiChip-root": {
        float: "right",
      },
      "& .MuiChip-label": {
        fontWeight: 400,
        fontSize: "12.1875px",
        textTransform: "none",
      },
    },
  },
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <MuiBox p={1}>
          <MuiTypography component="span">{children}</MuiTypography>
        </MuiBox>
      )}
    </div>
  );
}

export const PortalForm = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { candidateActionId, postLogoutRequest } = usePortalAuth();
  const [value, setValue] = useState(
    (location.pathname && location.pathname.split("/")[1]) ||
      t(CandidatePortal["INTAKE"])
  );
  const { isDirty, setIsDirty } = useDirtyFlag();
  const [fieldHandler, setFieldHandler] = useState();
  const [confirmationDialog, setConfirmationDialog] = useState({
    show: false,
    content: "",
    secondaryLabel: "No",
    primaryLabel: "Yes",
  });
  const [pendingMove, setPendingMove] = useState(
    (location.pathname && location.pathname.split("/")[1]) ||
      t(CandidatePortal["INTAKE"])
  );
  const [previous, setPrevious] = useState();
  const [apiUrl, setApiUrl] = useState();
  const [newValue, setNewValue] = useState(
    (location.pathname && location.pathname.split("/")[1]) ||
      t(CandidatePortal["INTAKE"])
  );
  const [changeTrigger, setChangeTrigger] = useState(false);

  useEffect(() => {
    if (!isDirty) {
      handleFormChange(newValue);
    } else {
      if (!fieldHandler) {
        handleFormChange(newValue);
      }

      // This is where we invoke showConfirmation()
      // eslint-disable-next-line no-eval
      eval(fieldHandler);
      setPrevious(location.pathname && location.pathname.split("/")[1]);
      setPendingMove(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeTrigger, value]);

  useEffect(() => {
    if (!confirmationDialog.show) {
      previous && setValue(previous);
      previous && setNewValue(previous);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmationDialog.show]);

  /**
   * Retrieves the current tab's URL segment from the pathname.
   *
   * @returns {string} The URL segment of the current tab.
   */
  const getTabURL = () => {
    return location.pathname && location.pathname.split("/")[1];
  };

  /**
   * Effect that triggers when the pathname changes, calling API and updating form state accordingly.
   *
   * @effect
   * @param {Array} dependencies - The dependencies to track for triggering the effect.
   */
  useEffect(() => {
    const redirectTab = getTabURL();
    setNewValue(redirectTab);
    handleFormChange(redirectTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleFormChange = (newValue) => {
    setIsDirty(false);
    setValue(newValue);
    switch (newValue) {
      case `${t(CandidatePortal["QUESTIONNAIRE"])}`:
        setApiUrl(
          `actions/${candidateActionId}/forms/candidate_questionnaire_form`
        );
        navigate(`/questionnaire/${candidateActionId}`);
        break;
      case `${t(CandidatePortal["TODOLIST"])}`:
        setApiUrl(
          `actions/${candidateActionId}/forms/candidate_todo_list_form`
        );
        navigate(`/todolist/${candidateActionId}`);
        break;
      case `${t(CandidatePortal["INTAKE"])}`:
      default:
        setApiUrl(`actions/${candidateActionId}/forms/candidate_intake_form`);
        navigate(`/intake/${candidateActionId}`);
        break;
    }
  };

  // eslint-disable-next-line no-unused-vars
  const showConfirmation = (content, secondaryLabel, primaryLabel) => {
    setConfirmationDialog({
      show: true,
      content,
      secondaryLabel,
      primaryLabel,
    });
  };
  return (
    <FormsProvider apiUrl={apiUrl}>
      <MuiContainer maxWidth="" sx={pageStyle.container}>
        <MuiBox mt={3}>
          <MuiBox mb={2}>
            <MuiTypography align="right" gutterBottom>
              <MuiLink
                onClick={() => postLogoutRequest()}
                underline="none"
                component="button"
                color="primary"
              >
                Logout
              </MuiLink>
            </MuiTypography>
            <MuiTypography variant="body2">
              {t("portal.portalForm.description.header")}
            </MuiTypography>
            <MuiTypography component="span" variant="body2">
              <ul>
                <li>{t("portal.portalForm.description.listItemOne")}</li>
                <li>{t("portal.portalForm.description.listItemTwo")}</li>
                <li>{t("portal.portalForm.description.listItemThree")}</li>
              </ul>
            </MuiTypography>
            <MuiTypography variant="body2">
              Please contact{" "}
              <MuiLink
                href={`mailto:${t("app.candidate-portal.supportEmail")}`}
                sx={pageStyle.link}
              >
                {t("app.candidate-portal.supportEmail")}
              </MuiLink>{" "}
              or your department Onboarding Contact if you have problems using
              our portal.
            </MuiTypography>
          </MuiBox>
          <MuiPaper>
            <MuiTabs
              value={value}
              onChange={(e, changedValue) => {
                setNewValue(changedValue);
                setChangeTrigger(!changeTrigger);
              }}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              component={MuiPaper}
              sx={pageStyle.tabs}
            >
              <MuiTab
                sx={pageStyle.chipTab}
                value={t(CandidatePortal["INTAKE"])}
                label={
                  <MuiTypography
                    component="span"
                    display="inline"
                    sx={pageStyle.chipWrapper}
                  >
                    Intake <StartHereChip sx={pageStyle.chip} />
                  </MuiTypography>
                }
              />
              <MuiTab
                value={t(CandidatePortal["QUESTIONNAIRE"])}
                label={<MuiTypography>Questionnaire</MuiTypography>}
              />
              <MuiTab
                value={t(CandidatePortal["TODOLIST"])}
                label={<MuiTypography>To-Do List</MuiTypography>}
              />
            </MuiTabs>
            <TabPanel value={value} index={t(CandidatePortal["INTAKE"])}>
              <MuiBox p={2}>
                <FormBody
                  breadcrumbs={false}
                  actionid={candidateActionId}
                  setFieldHandler={setFieldHandler}
                  formname="candidate_intake_form"
                />
              </MuiBox>
            </TabPanel>
            <TabPanel value={value} index={t(CandidatePortal["QUESTIONNAIRE"])}>
              <MuiBox p={2}>
                <FormBody
                  breadcrumbs={false}
                  actionid={candidateActionId}
                  setFieldHandler={setFieldHandler}
                  formname="candidate_questionnaire_form"
                />
              </MuiBox>
            </TabPanel>
            <TabPanel value={value} index={t(CandidatePortal["TODOLIST"])}>
              <MuiBox p={2}>
                <FormBody
                  breadcrumbs={false}
                  actionid={candidateActionId}
                  setFieldHandler={setFieldHandler}
                  formname="candidate_todo_list_form"
                />
              </MuiBox>
            </TabPanel>
          </MuiPaper>
        </MuiBox>
      </MuiContainer>
      <ConfirmationDialog
        show={confirmationDialog.show}
        setShow={setConfirmationDialog}
        content={confirmationDialog.content}
        secondaryLabel={confirmationDialog.secondaryLabel}
        primaryLabel={confirmationDialog.primaryLabel}
        primaryAction={() => {
          setConfirmationDialog({ ...confirmationDialog, show: false });
          handleFormChange(pendingMove);
        }}
      />
    </FormsProvider>
  );
};
