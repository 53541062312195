// NOTE: This file is maintained in the parent project TDS-AS Common.
//       Your application should avoid modifying this file.

import { AlertsProvider, useAuth, UsersProvider, Page } from "common";
import { CssBaseline as MuiCssBaseline } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";
import { App } from "app/App";
import { useApp } from "app/appService";
import { BundleIds } from "app/shared/constants";
import { MedActionsTheme } from "theme";
import { DirtyFlagProvider } from "app/portal/DirtyFlagProvider";

export const AppRoot = () => {
  const auth = useAuth();
  const { bundleId } = useApp();

  return (
    <div>
      {(bundleId === BundleIds.MEDONBOARD_PORTAL || auth.isAuthenticated) && (
        <MuiCssBaseline>
          <MuiThemeProvider theme={MedActionsTheme}>
            <BrowserRouter>
              <AlertsProvider>
                <UsersProvider>
                  <DirtyFlagProvider>
                    <Page>
                      <App />
                    </Page>
                  </DirtyFlagProvider>
                </UsersProvider>
              </AlertsProvider>
            </BrowserRouter>
          </MuiThemeProvider>
        </MuiCssBaseline>
      )}
    </div>
  );
};
