import { useEffect } from "react";
import { useDownload } from "app/shared/Download";
import { useParams } from "react-router-dom";

export const DocumentLink = () => {
  const { getDocument } = useDownload("_self");
  const params = useParams();

  useEffect(() => {
    getDocument(
      `actions/${params.actionId}/documents/template/${params.templateId}`
    );
  }, [getDocument, params.actionId, params.templateId]);

  return null;
};
