import { useState, cloneElement } from "react";
import {
  Grid as MuiGrid,
  Container as MuiContainer,
  Box as MuiBox,
  Paper as MuiPaper,
  Typography as MuiTypography,
  Breadcrumbs as MuiBreadcrumbs,
  Backdrop as MuiBackdrop,
  CircularProgress as MuiCircularProgress,
  Link as MuiLink,
} from "@mui/material";
import { Title } from "app/shared/ui";
import { ArrowBack as MuiArrowBackIcon } from "@mui/icons-material";
import { useForms } from "app/forms/formsService";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
export const FormContainer = ({ children }) => {
  const { formFields, isLoading, formAccess, formFlatMap, allFields } =
    useForms();
  const [goBackConfirmation, setGoBackConfirmation] = useState({
    show: false,
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { actionId } = useParams();

  if (isLoading || !formFields.length)
    return (
      <MuiBackdrop
        open={isLoading}
        invisible={true}
        style={{ zIndex: 1201 }}
        children={<MuiCircularProgress />}
      />
    );

  return (
    <MuiContainer
      sx={{
        paddingTop: "16px",
        paddingBottom: "16px",
        height: "100%",
      }}
    >
      <MuiGrid container item xs={12}>
        <MuiContainer maxWidth={false} sx={{ maxWidth: "700px" }}>
          <MuiBox mb={2}>
            <MuiGrid
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Title
                label={formFields[0].field_label}
                formValues={formFlatMap}
                actionid={actionId}
                formspec={allFields}
              />
              <MuiBreadcrumbs>
                <MuiLink
                  onClick={() =>
                    formAccess !== "VIEW"
                      ? setGoBackConfirmation({ show: true })
                      : navigate("/")
                  }
                  component="button"
                >
                  <MuiTypography
                    variant="subtitle2"
                    sx={{
                      display: "inline-flex",
                      verticalAlign: "middle",
                    }}
                  >
                    <MuiArrowBackIcon
                      color="primary"
                      fontSize="small"
                      sx={{ fontSize: "18px" }}
                    />
                    <MuiTypography
                      component="span"
                      variant="subtitle2"
                      color="primary"
                      fontSize="small"
                    >
                      Dashboard
                    </MuiTypography>
                  </MuiTypography>
                </MuiLink>
              </MuiBreadcrumbs>
            </MuiGrid>
          </MuiBox>
          <MuiContainer component={MuiPaper}>
            {cloneElement(children)}
          </MuiContainer>
        </MuiContainer>
      </MuiGrid>
      <ConfirmationDialog
        show={goBackConfirmation.show}
        setShow={setGoBackConfirmation}
        content={t("forms.goBackConfirmationDialog.content")}
        primaryAction={() => navigate("/")}
      />
    </MuiContainer>
  );
};
