import {
  AddCircle as AddCircleIcon,
  Assistant as AssistantIcon,
  CheckCircle as CheckCircleIcon,
  ListAlt as ListAltIcon,
  PersonAdd as PersonAddIcon,
  QuestionAnswer as QuestionAnswerIcon,
  RateReview as RateReviewIcon,
  SpeakerNotes as SpeakerNotesIcon,
  VerifiedUser as VerifiedUserIcon,
  Description as DescriptionIcon,
  PictureAsPdf as PictureAsPdfIcon,
  AttachFile as AttachFileIcon,
  LibraryAddCheck as LibraryAddCheckIcon,
  SpeakerNotesOutlined as SpeakerNotesOutlinedIcon,
  CheckCircleOutlined as CheckCircleOutlinedIcon,
  DescriptionOutlined as DescriptionOutlinedIcon,
  VerifiedUserOutlined as VerifiedUserOutlinedIcon,
  AssistantOutlined as AssistantOutlinedIcon,
  RateReviewOutlined as RateReviewOutlinedIcon,
  PersonAddOutlined as PersonAddOutlinedIcon,
  ListAltOutlined as ListAltOutlinedIcon,
  QuestionAnswerOutlined as QuestionAnswerOutlinedIcon,
  AddCircleOutline as AddCircleOutlineIcon,
  LibraryAddCheckOutlined as LibraryAddCheckOutlinedIcon,
} from "@mui/icons-material";
import { LinkItem } from "app/actions/LinkItem";
import { useApp } from "app/appService";
import { useTranslation } from "react-i18next";
import { IconResize } from "app/actions/Actions.styles";

export const Links = ({ params }) => {
  const { tenantId } = useApp();
  const hateos = params.row.hateos;

  if (!hateos) return "";

  const actionId = params.row.id;

  const linksOrder = {
    medonboard: [
      "onboarding_request_form",
      "onboarding_request_review_form",
      "onboarding_information_form",
      "onboarding_candidate_invitation_form",
      "onboarding_release_form",
      "candidate_questionnaire_form",
      "candidate_todo_list_form",
      "onboarding_ssn_intro_form",
      "onboarding_resolution_form",
      "onboarding_summary_form",
      "onboarding_mssd_summary_form",
      "onboarding_hr_summary_form",
    ],
    medact: [
      "intake_information_form",
      "intake_information_school_reviewer_form",
      "term_sheet_document",
      "attachment",
      "action_resolution_form",
    ],
    medleave: [
      "admin_leave_request_form",
      "faculty_leave_request_form",
      "faculty_approval_form",
      "data_security_attestation_form",
      "leave_request_approval_form",
      "leave_approval_summary_form",
      "leave_request_review_form",
      "attachment",
      "leave_request_document",
      "leave_request_with_payroll_document",
      "leave_resolution_form",
    ],
    relman: [
      "release_request_form",
      "release_request_reviewer_form",
      "release_resolution_form",
    ],
  };

  const hateosOrder = [];

  linksOrder[tenantId].forEach((item) => {
    hateosOrder.push({
      formName: item,
      serviceUrl: hateos[item]?.href ?? "",
      type: hateos[item]?.type ?? "",
      access: hateos[item]?.access ?? "",
    });
  });

  return hateosOrder.map((item, index) => (
    <Link
      key={index}
      formName={item.formName}
      actionId={actionId}
      serviceUrl={item.serviceUrl}
      type={item.type}
      params={params}
      access={item.access}
    />
  ));
};

const Link = ({ formName, actionId, serviceUrl, type, params, access }) => {
  const { t } = useTranslation();
  const ACCESS = access?.toLowerCase();

  switch (formName) {
    case "onboarding_request_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.action")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <AddCircleIcon color="primary" sx={IconResize} />
          ) : (
            <AddCircleOutlineIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "onboarding_request_review_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.approve")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <RateReviewIcon color="primary" sx={IconResize} />
          ) : (
            <RateReviewOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "onboarding_information_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.information")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <SpeakerNotesIcon color="primary" sx={IconResize} />
          ) : (
            <SpeakerNotesOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "candidate_questionnaire_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.questionnaire")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <QuestionAnswerIcon color="primary" sx={IconResize} />
          ) : (
            <QuestionAnswerOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "onboarding_release_form": // MedOnboard
      return (
        <LinkItem
          title={t(
            "dashboard.action.list.tooltip.medonboard.questionnaireReview"
          )}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <AssistantIcon color="primary" sx={IconResize} />
          ) : (
            <AssistantOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "candidate_todo_list_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.todoList")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <ListAltIcon color="primary" sx={IconResize} />
          ) : (
            <ListAltOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "onboarding_hr_summary_form": // MedOnboard
    case "onboarding_mssd_summary_form": // MedOnboard
    case "onboarding_summary_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.summary")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
          display={!Boolean(serviceUrl) ? "none" : "block"}
        >
          {ACCESS === "edit" ? (
            <DescriptionIcon color="primary" sx={IconResize} />
          ) : (
            <DescriptionOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "onboarding_ssn_intro_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.ssn")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <VerifiedUserIcon color="primary" sx={IconResize} />
          ) : (
            <VerifiedUserOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "onboarding_resolution_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.resolution")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <CheckCircleIcon color="primary" sx={IconResize} />
          ) : (
            <CheckCircleOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "onboarding_candidate_invitation_form": // MedOnboard
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medonboard.invitation")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <PersonAddIcon color="primary" sx={IconResize} />
          ) : (
            <PersonAddOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "intake_information_form": // MedACT
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medact.intakeInformation")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <SpeakerNotesIcon color="primary" sx={IconResize} />
          ) : (
            <SpeakerNotesOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "intake_information_school_reviewer_form": // MedACT
      return (
        <LinkItem
          title={t(
            "dashboard.action.list.tooltip.medact.intakeInformationSchoolReviewer"
          )}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <SpeakerNotesIcon color="primary" sx={IconResize} />
          ) : (
            <SpeakerNotesOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "term_sheet_document": // MedACT
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medact.termSheetDocument")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
          fileName={`${formName}.pdf`}
          type={type}
        >
          <PictureAsPdfIcon color="primary" sx={IconResize} />
        </LinkItem>
      );
    case "attachment": // MedACT MedLeave
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medact.fileAttachment")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={`/file-attachments/${actionId}`}
          type={type}
        >
          <AttachFileIcon color="primary" sx={IconResize} />
        </LinkItem>
      );
    case "action_resolution_form": // MedACT
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medact.actionResolution")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <CheckCircleIcon color="primary" sx={IconResize} />
          ) : (
            <CheckCircleOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "admin_leave_request_form": // MedLeave
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medleave.leaveRequest")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <SpeakerNotesIcon color="primary" sx={IconResize} />
          ) : (
            <SpeakerNotesOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "leave_request_document": // MedLeave
      return (
        <LinkItem
          title={t(
            "dashboard.action.list.tooltip.medleave.leaveRequestDocument"
          )}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
          fileName={`${formName}.pdf`}
          type={type}
        >
          <PictureAsPdfIcon color="primary" sx={IconResize} />
        </LinkItem>
      );
    case "leave_request_with_payroll_document": // MedLeave
      return (
        <LinkItem
          title={t(
            "dashboard.action.list.tooltip.medleave.leaveRequestPayrollDocument"
          )}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
          fileName={`${formName}.pdf`}
          type={type}
        >
          <PictureAsPdfIcon color="primary" sx={IconResize} />
        </LinkItem>
      );
    case "faculty_leave_request_form": // MedLeave
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medleave.leaveRequest")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <SpeakerNotesIcon color="primary" sx={IconResize} />
          ) : (
            <SpeakerNotesOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "leave_request_review_form": // MedLeave
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medleave.leaveSummary")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <DescriptionIcon color="primary" sx={IconResize} />
          ) : (
            <DescriptionOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "data_security_attestation_form": // MedLeave
      return (
        <LinkItem
          title={t(
            "dashboard.action.list.tooltip.medleave.dataSecurityAttestation"
          )}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <VerifiedUserIcon color="primary" sx={IconResize} />
          ) : (
            <VerifiedUserOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "faculty_approval_form": // MedLeave
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medleave.approveLeave")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <AssistantIcon color="primary" sx={IconResize} />
          ) : (
            <AssistantOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "leave_request_approval_form": // MedLeave
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medleave.reviewLeave")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <RateReviewIcon color="primary" sx={IconResize} />
          ) : (
            <RateReviewOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "leave_approval_summary_form": // MedLeave
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medleave.approvalSummary")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <LibraryAddCheckIcon color="primary" sx={IconResize} />
          ) : (
            <LibraryAddCheckOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "leave_resolution_form": // MedLeave
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.medleave.requestResolution")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <CheckCircleIcon color="primary" sx={IconResize} />
          ) : (
            <CheckCircleOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "release_request_form": // RelMan
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.relman.requestRelease")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <SpeakerNotesIcon color="primary" sx={IconResize} />
          ) : (
            <SpeakerNotesOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "release_request_reviewer_form": // RelMan
      return (
        <LinkItem
          title={t(
            "dashboard.action.list.tooltip.relman.releaseRequestReviewer"
          )}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <SpeakerNotesIcon color="primary" sx={IconResize} />
          ) : (
            <SpeakerNotesOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    case "release_resolution_form": // RelMan
      return (
        <LinkItem
          title={t("dashboard.action.list.tooltip.relman.releaseResolution")}
          hidden={!Boolean(serviceUrl)}
          serviceUrl={serviceUrl}
        >
          {ACCESS === "edit" ? (
            <CheckCircleIcon color="primary" sx={IconResize} />
          ) : (
            <CheckCircleOutlinedIcon color="primary" sx={IconResize} />
          )}
        </LinkItem>
      );
    default:
      return (
        <LinkItem title={""} hidden={true} serviceUrl={""}>
          <PersonAddIcon color="primary" sx={IconResize} />
        </LinkItem>
      );
  }
};
