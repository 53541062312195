import {
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  Typography as MuiTypography,
} from "@mui/material";
import { getString } from "app/shared/utils";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import isJSON from "is-json";

const StyleApp = {
  summaryRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#f5f5f5",
    },
  },
  summaryCell: {
    border: 0,
  },
  summaryLink: {
    "& a": {
      textDecoration: "none",
      color: "#007c92",
      cursor: "pointer",
    },
  },
};

const getFieldLabel = (text, fields, otherValues, wrapValuesWith) => {
  const matches = text.match(/\[.+?\]/g) || [];
  return matches.reduce((prevText, match) => {
    const key = match.slice(1, -1);

    return prevText.replace(
      match,
      getString(fields, key, otherValues, wrapValuesWith)
    );
  }, text);
};

export const FormSummaryRow = ({
  fields,
  formValues,
  formFields,
  showEmptyFields,
}) => {
  return fields.map((item, index) => {
    const fieldLabel = item?.field_label
      ? getFieldLabel(
          item.field_label,
          formValues,
          {
            formSpec: formFields,
          },
          ""
        )
      : "";
    const fieldValue =
      item?.field_id &&
      formValues[item.field_id] !== "{}" &&
      formValues[item.field_id] !== null
        ? getString(
            formValues,
            item.field_id,
            {
              formSpec: formFields,
            },
            ""
          )
        : "";

    const attributes =
      item.field_attributes && isJSON(item.field_attributes)
        ? JSON.parse(item.field_attributes)
        : null;
    const fieldType = attributes?.field_type;
    return (
      fieldLabel &&
      (fieldValue || showEmptyFields) && (
        <MuiTableRow key={index} sx={StyleApp.summaryRow}>
          <MuiTableCell width="288" sx={StyleApp.summaryCell}>
            <MuiTypography variant="body2">{fieldLabel}</MuiTypography>
          </MuiTableCell>
          <MuiTableCell sx={StyleApp.summaryCell}>
            <MuiTypography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: sanitize(getFormattedValue(fieldValue, fieldType), {
                  ALLOWED_TAGS: AllowedTags,
                }),
              }}
              sx={StyleApp.summaryLink}
            />
          </MuiTableCell>
        </MuiTableRow>
      )
    );
  });
};

const getFormattedValue = (inputValue, fieldType) => {
  if (fieldType === "percent") return `${parseFloat(inputValue)}%`;
  else if (fieldType === "money") {
    return `${Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(String(inputValue))
      .replace(/(\.|,)00$/g, "")}`;
  }
  return inputValue;
};
