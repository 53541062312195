import { parseISO } from "date-fns";
import {
  Autocomplete as MuiAutocomplete,
  Box as MuiBox,
  Grid as MuiGrid,
  TextField as MuiTextField,
  Typography as MuiTypography,
  RadioGroup as MuiRadioGroup,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  FormControl as MuiFormControl,
} from "@mui/material";
import {
  dateSeparator,
  datePicker,
  filter,
  datePickerContainer,
  startDate,
  endDate,
  autocompleteStyles,
  multiSelectAutocompleteStyles,
} from "app/actions/Actions.styles";
import { ResetFilter } from "app/actions/ResetFilter";
import { useApp } from "app/appService";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { getStoreValue, setStoreValue } from "app/shared/utils";
import { Permissions } from "app/shared/constants";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";

const initialFilters = {
  action_id: [], // MedACT
  candidate_last_name: [], // MedOnboard, MedACT
  candidate_first_name: [], // MedOnboard, MedACT
  candidate_primary_division_org: [], // MedOnboard, MedACT
  candidate_primary_department_org: [], // MedOnboard, MedACT
  candidate_academic_line_choice: [], // MedACT
  term_sheet_status: [], // MedACT
  faculty_last_name: [], // MedLeave
  faculty_first_name: [], // MedLeave
  faculty_primary_department_org: [], //MedLeave
  faculty_primary_division_org: [], //MedLeave
  status: [], // MedLeave
  request_status: [], // MedLeave
  action_query_options: [],
  rpm_contact_name: [], // MedLeave
  leave_type: [], // MedLeave
  release_project: [], //RelMan
  release_tag: [], //RelMan
  release_rolling: [], //RelMan
  release_high_profile: [], //RelMan
  release_requires_coordination: [], //RelMan
  release_request_status: [], //RelMan
};

const statusValueStr = "dashboard.action.filter.fieldValues.status";

export const ActionsFilter = ({
  actionDispatcher,
  loadFilters,
  filters,
  setFilters,
}) => {
  const { tenantId } = useApp();
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState(initialFilters);

  let newState = "";
  const filterReducer = (state, payload) => {
    switch (payload.action) {
      case "ACTION_ID": // MedOnboard, MedACT
        newState = {
          ...state,
          ...{ action_id: payload.value },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_LASTNAME": // MedOnboard, MedACT
        newState = {
          ...state,
          ...{ candidate_last_name: payload.value },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_FIRSTNAME": // MedOnboard, MedACT
        newState = {
          ...state,
          ...{ candidate_first_name: payload.value },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_DEPARTMENT": // MedOnboard, MedACT
        newState = {
          ...state,
          ...{
            candidate_primary_department_org: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RELEASE_PROJECT": // RelMan
        newState = {
          ...state,
          ...{
            release_project: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RELEASE_TAG": // RelMan
        newState = {
          ...state,
          ...{
            release_tag: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RELEASE_ROLLING": // RelMan
        newState = {
          ...state,
          ...{
            release_rolling: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RELEASE_HIGH_PROFILE": // RelMan
        newState = {
          ...state,
          ...{
            release_high_profile: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RELEASE_REQUIRES_COORDINATION": // RelMan
        newState = {
          ...state,
          ...{
            release_requires_coordination: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RELEASE_STARTDATE": // RelMan
        newState = { ...state, ...{ release_requested_date: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RELEASE_ENDDATE": // RelMan
        newState = { ...state, ...{ release_request_enddate: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RELEASE_STATUS": // RelMan
        newState = { ...state, ...{ release_request_status: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_DIVISION": // MedOnboard, MedACT
        newState = {
          ...state,
          ...{
            candidate_primary_division_org: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_STARTDATE": // MedOnboard, MedACT
        newState = { ...state, ...{ candidate_start_date: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_ENDDATE": // MedOnboard, MedACT
        newState = { ...state, ...{ candidate_enddate: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_STATUS": // MedOnboard
        newState = { ...state, ...{ candidate_status: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_LINE": // MedACT
        newState = {
          ...state,
          ...{
            candidate_academic_line_choice: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_RANK": // MedACT
        newState = {
          ...state,
          ...{
            candidate_academic_rank: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_TERM_SHEET_STATUS": // MedACT
        newState = { ...state, ...{ term_sheet_status: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_FACULTY_LASTNAME": // MedLeave
        newState = {
          ...state,
          ...{ faculty_last_name: payload.value },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_FACULTY_FIRSTNAME": // MedLeave
        newState = {
          ...state,
          ...{ faculty_first_name: payload.value },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_FACULTY_DEPARTMENT": // MedLeave
        newState = {
          ...state,
          ...{
            faculty_primary_department_org: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_FACULTY_DIVISION": // MedLeave
        newState = {
          ...state,
          ...{
            faculty_primary_division_org: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_LEAVE_TYPE": // MedLeave
        newState = {
          ...state,
          ...{
            leave_type: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_LEAVE_STATUS": // MedLeave
        newState = {
          ...state,
          ...{ status: payload.value.length > 0 ? payload.value : null },
        };
        setStoreValue("filters", newState);
        return newState;
      case "SET_REQUEST_STATUS": // MedLeave
        newState = { ...state, ...{ request_status: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_LEAVE_STARTDATE_BEGIN": // MedLeave
        newState = { ...state, ...{ leave_start_date_begin: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_LEAVE_STARTDATE_END": // MedLeave
        newState = { ...state, ...{ leave_start_date_end: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_LEAVE_ENDDATE_BEGIN": // MedLeave
        newState = { ...state, ...{ leave_end_date_begin: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_LEAVE_ENDDATE_END": // MedLeave
        newState = { ...state, ...{ leave_end_date_end: payload.value } };
        setStoreValue("filters", newState);
        return newState;
      case "SET_DASHBOARD_VIEW": // MedLeave
        newState = {
          ...state,
          ...{
            action_query_options: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;

      case "SET_RPM_CONTACT": // MedLeave
        newState = {
          ...state,
          ...{
            rpm_contact_name: payload.value,
          },
        };
        setStoreValue("filters", newState);
        return newState;
      case "RESET":
        newState = {
          action_id: null, // MedOnboard, MedACT
          release_project: null, //RelMan
          release_tag: null, //RelMan
          release_rolling: null, //RelMan
          release_high_profile: null, //RelMan
          release_requires_coordination: null, //RelMan
          release_requested_date: null, // RelMan
          release_request_enddate: null, // RelMan
          release_request_status: null, //RelMan
          candidate_last_name: null, // MedOnboard, MedACT
          candidate_first_name: null, // MedOnboard, MedACT
          candidate_primary_division_org: null, // MedOnboard, MedACT
          candidate_primary_department_org: null, // MedOnboard, MedACT
          candidate_start_date: null, // MedOnboard, MedACT
          candidate_enddate: null, // MedOnboard, MedACT
          candidate_status: null, // MedOnboard
          candidate_academic_line_choice: null, // MedACT
          candidate_academic_rank: null, // MedACT
          term_sheet_status: null, // MedACT
          faculty_last_name: null, // MedLeave
          faculty_first_name: null, // MedLeave
          faculty_primary_department_org: null, //MedLeave
          faculty_primary_division_org: null, //MedLeave
          leave_type: null, // MedLeave
          status: null, // MedLeave
          request_status: null, // MedLeave
          leave_start_date_begin: null, // MedLeave
          leave_start_date_end: null, // MedLeave
          leave_end_date_begin: null, // MedLeave
          leave_end_date_end: null, // MedLeave
          action_query_options:
            filterOptions && filterOptions.action_query_options
              ? filterOptions.action_query_options[0]
              : null,
          rpm_contact_name: null, //Medleave
        };
        setStoreValue("filters", newState);
        return newState;
      default:
        break;
    }
  };

  const statusOptions = [
    {
      code: "INITIATED",
      displayName: t(`${statusValueStr}.initiated`),
    },
    {
      code: "IN_PROGRESS",
      displayName: t(`${statusValueStr}.inProgress`),
    },
    {
      code: "APPROVED",
      displayName: t(`${statusValueStr}.approved`),
    },
    {
      code: "RETURNED",
      displayName: t(`${statusValueStr}.returned`),
    },
    {
      code: "COMPLETED",
      displayName: t(`${statusValueStr}.completed`),
    },
    {
      code: "WITHDRAWN",
      displayName: t(`${statusValueStr}.withdrawn`),
    },
    {
      code: "DECLINED",
      displayName: t(`${statusValueStr}.declined`),
    },
  ];

  const [filterValues, dispatch] = useReducer(filterReducer, filters);

  var StorageItem = JSON.parse(localStorage.getItem("filters-" + tenantId));
  const candidateStartDate = StorageItem?.candidate_start_date
    ? parseISO(StorageItem.candidate_start_date)
    : filterValues.candidate_start_date;
  const candidateEnddate = StorageItem?.candidate_enddate
    ? parseISO(StorageItem.candidate_enddate)
    : filterValues.candidate_enddate;
  const leaveStartDateBegin = StorageItem?.leave_start_date_begin
    ? parseISO(StorageItem.leave_start_date_begin)
    : filterValues.leave_start_date_begin;
  const leaveStartDateEnd = StorageItem?.leave_start_date_end
    ? parseISO(StorageItem.leave_start_date_end)
    : filterValues.leave_start_date_end;
  const leaveEndDateBegin = StorageItem?.leave_end_date_begin
    ? parseISO(StorageItem.leave_end_date_begin)
    : filterValues.leave_end_date_begin;
  const leaveEndDateEnd = StorageItem?.leave_end_date_end
    ? parseISO(StorageItem.leave_end_date_end)
    : filterValues.leave_end_date_end;
  const releaseRequestDate = StorageItem?.release_requested_date
    ? parseISO(StorageItem.release_requested_date)
    : filterValues.release_requested_date;
  const releaseRequestEnddate = StorageItem?.release_request_enddate
    ? parseISO(StorageItem.release_request_enddate)
    : filterValues.release_request_enddate;

  const setInitialQueryOptionFilter = (data, dispatch, permissions) => {
    if (
      data?.action_query_options.length > 0 &&
      !getStoreValue("filters")?.["action_query_options"]
    ) {
      dispatch({
        action: "SET_DASHBOARD_VIEW",
        value: data.action_query_options[0],
      });
    } else if (
      Permissions.IMPERSONATE_USER in permissions && // This condition will remove once we get a flag from AS-Common for impersonation
      data?.action_query_options.length === 0 &&
      getStoreValue("filters")?.["action_query_options"]
    ) {
      dispatch({
        action: "SET_DASHBOARD_VIEW",
        value: null,
      });
    }
  };

  useEffect(() => {
    loadFilters(setFilterOptions, setInitialQueryOptionFilter, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadFilters]);

  useEffect(() => {
    actionDispatcher({
      action: "SET_FILTERS",
      value: filterValues,
    });
    setFilters(filterValues);
  }, [filterValues, actionDispatcher, setFilters]);

  return (
    <MuiBox>
      <MuiGrid container spacing={3} style={filter}>
        <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
          <MuiTypography sx={{ fontWeight: 600 }}>
            {t("dashboard.action.filter.sectionTitle")}
          </MuiTypography>
        </MuiGrid>

        {/* How to access this filter, delete after*/}

        {/* Medleave */}
        {filterOptions &&
          filterOptions.action_query_options &&
          filterOptions.action_query_options.length > 1 && (
            <MuiGrid
              item
              xs={12}
              sx={{ "&.MuiGrid-item": { padding: "12px" } }}
            >
              <MuiFormControl component="fieldset">
                <MuiRadioGroup
                  aria-label={t("dashboard.action.filter.sectionTitle")}
                  id={t("dashboard.action.filter.sectionTitle")}
                  name={t("dashboard.action.filter.sectionTitle")}
                  onChange={(event, changedValue) => {
                    const value = JSON.parse(changedValue);
                    dispatch({
                      action: "SET_DASHBOARD_VIEW",
                      value,
                    });
                  }}
                >
                  {filterOptions &&
                    filterOptions.action_query_options &&
                    filterOptions.action_query_options.map((item, index) => {
                      return (
                        <MuiFormControlLabel
                          control={
                            <MuiRadio
                              id={`filter-radio-${index}`}
                              value={JSON.stringify(item)}
                              color="primary"
                              checked={Boolean(
                                filterValues.action_query_options &&
                                  filterValues.action_query_options.id ===
                                    item.id
                              )}
                            />
                          }
                          label={
                            <MuiTypography
                              variant="body2"
                              component="span"
                              color="initial"
                            >
                              {item.text}
                            </MuiTypography>
                          }
                          key={index}
                        />
                      );
                    })}
                </MuiRadioGroup>
              </MuiFormControl>
            </MuiGrid>
          )}
        {/* MedLeave, MedACT, RelMan */}
        {["medact", "medleave", "relman"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions?._action_id || []}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.action_id}
              onChange={(event, value) =>
                dispatch({
                  action: "ACTION_ID",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.actionId.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedACT, MedOnboard */}
        {["medact", "medonboard"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.candidate_last_name}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.candidate_last_name}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_LASTNAME",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.lastName.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedACT, MedOnboard */}
        {["medact", "medonboard"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.candidate_first_name}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.candidate_first_name}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_FIRSTNAME",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.firstName.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedOnboard, MedACT */}
        {["medact", "medonboard"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(
                filterOptions?.candidate_primary_department_org
              )}
              getOptionLabel={(option) =>
                option ? `${option.orgName} (${option.orgCode})` : ""
              }
              clearOnEscape={true}
              value={filterValues.candidate_primary_department_org}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_DEPARTMENT",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.hiringDepartment.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.orgName} (${option.orgCode})`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedOnboard, MedACT */}
        {["medact", "medonboard"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(
                filterOptions?.candidate_primary_division_org
              )}
              getOptionLabel={(option) =>
                option ? `${option.orgName} (${option.orgCode})` : ""
              }
              clearOnEscape={true}
              value={filterValues.candidate_primary_division_org}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_DIVISION",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.hiringDivision.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.orgName} (${option.orgCode})`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedACT */}
        {tenantId === "medact" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(
                filterOptions?.candidate_academic_line_choice
              )}
              getOptionLabel={(option) => option?.text}
              clearOnEscape={true}
              value={filterValues.candidate_academic_line_choice}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_LINE",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.line.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option?.text}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedACT */}
        {tenantId === "medact" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(
                filterOptions?.candidate_academic_rank
              )}
              getOptionLabel={(option) => option?.text}
              clearOnEscape={true}
              value={filterValues.candidate_academic_rank}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_RANK",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.rank.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option?.text}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedOnboard, MedACT */}
        {["medact", "medonboard"].includes(tenantId) && (
          <MuiGrid
            container
            item
            xs={12}
            spacing={1}
            sx={{ margin: "0px !important", padding: "8px !important" }}
          >
            <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "4px" } }}>
              <MuiTypography
                display="inline"
                variant="body2"
                color="textPrimary"
              >
                {t(
                  `dashboard.action.filter.fields.${tenantId}.startDate.label`
                )}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "4px" } }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div style={datePickerContainer}>
                  <div style={startDate}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={candidateStartDate}
                      id="date-picker-start-date"
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "",
                            size: "small",
                            sx: datePicker,
                          },
                        },
                        switchViewButton: {
                          sx: { padding: "5px" },
                        },
                      }}
                      maxDate={
                        filterValues.candidate_enddate
                          ? new Date(filterValues.candidate_enddate)
                          : undefined
                      }
                      maxDateMessage={t(
                        `dashboard.action.filter.fields.${tenantId}.startDate.minDate`
                      )}
                      onChange={(value) => {
                        const timestamp = Date.parse(value);
                        if (!isNaN(timestamp) || !value) {
                          dispatch({
                            action: "SET_STARTDATE",
                            value,
                          });
                        }
                      }}
                      format="yyyy-MM-dd"
                    />
                  </div>
                  <div>
                    <MuiTypography
                      variant="body2"
                      align="center"
                      sx={dateSeparator}
                    >
                      {t(
                        `dashboard.action.filter.fields.${tenantId}.dateSeparator.label`
                      )}
                    </MuiTypography>
                  </div>
                  <div style={endDate}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={candidateEnddate}
                      id="date-picker-end-date"
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "",
                            size: "small",
                            sx: datePicker,
                          },
                        },
                        switchViewButton: {
                          sx: { padding: "5px" },
                        },
                      }}
                      minDate={
                        filterValues.candidate_start_date
                          ? new Date(filterValues.candidate_start_date)
                          : undefined
                      }
                      minDateMessage={t(
                        `dashboard.action.filter.fields.${tenantId}.endDate.maxDate`
                      )}
                      onChange={(value) => {
                        const timestamp = Date.parse(value);
                        if (!isNaN(timestamp) || !value) {
                          dispatch({
                            action: "SET_ENDDATE",
                            value,
                          });
                        }
                      }}
                      format="yyyy-MM-dd"
                    />
                  </div>
                </div>
              </LocalizationProvider>
            </MuiGrid>
          </MuiGrid>
        )}
        {/* MedOnboard */}
        {tenantId === "medonboard" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={statusOptions}
              isOptionEqualToValue={(option, value) =>
                option.displayName === value.displayName
              }
              getOptionLabel={(option) => option.displayName}
              clearOnEscape={true}
              value={filterValues.candidate_status}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_STATUS",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.status.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.displayName}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedACT */}
        {tenantId === "medact" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.term_sheet_status}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.term_sheet_status}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_TERM_SHEET_STATUS",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.termSheetStatus.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.faculty_first_name}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.faculty_first_name}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_FACULTY_FIRSTNAME",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.firstName.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.faculty_last_name}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.faculty_last_name}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_FACULTY_LASTNAME",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.lastName.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(
                filterOptions?.faculty_primary_department_org
              )}
              getOptionLabel={(option) =>
                option ? `${option.orgName} (${option.orgCode})` : ""
              }
              clearOnEscape={true}
              value={filterValues.faculty_primary_department_org}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_FACULTY_DEPARTMENT",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.hiringDepartment.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.orgName} (${option.orgCode})`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(
                filterOptions?.faculty_primary_division_org
              )}
              getOptionLabel={(option) =>
                option ? `${option.orgName} (${option.orgCode})` : ""
              }
              clearOnEscape={true}
              value={filterValues.faculty_primary_division_org}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_FACULTY_DIVISION",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.hiringDivision.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.orgName} (${option.orgCode})`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(filterOptions?.leave_type_choice)}
              getOptionLabel={(option) => (option ? option.id : "")}
              clearOnEscape={true}
              value={filterValues.leave_type}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_LEAVE_TYPE",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.leaveType.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {option.id}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid
            container
            item
            xs={12}
            spacing={1}
            sx={{ margin: "0px !important", padding: "8px !important" }}
          >
            <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "4px" } }}>
              <MuiTypography
                display="inline"
                variant="body2"
                color="textPrimary"
              >
                {t(
                  `dashboard.action.filter.fields.${tenantId}.startDate.label`
                )}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "4px" } }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div style={datePickerContainer}>
                  <div style={startDate}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={leaveStartDateBegin}
                      id="date-picker-start-date"
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "",
                            size: "small",
                            sx: datePicker,
                          },
                        },
                        switchViewButton: {
                          sx: { padding: "5px" },
                        },
                      }}
                      maxDate={
                        filterValues.leave_start_date_end
                          ? new Date(filterValues.leave_start_date_end)
                          : undefined
                      }
                      maxDateMessage={t(
                        `dashboard.action.filter.fields.${tenantId}.startDateBegin.minDate`
                      )}
                      onChange={(value) => {
                        const timestamp = Date.parse(value);
                        if (!isNaN(timestamp) || !value) {
                          dispatch({
                            action: "SET_LEAVE_STARTDATE_BEGIN",
                            value,
                          });
                        }
                      }}
                      format="yyyy-MM-dd"
                    />
                  </div>
                  <div>
                    <MuiTypography
                      variant="body2"
                      align="center"
                      sx={dateSeparator}
                    >
                      {t(
                        `dashboard.action.filter.fields.${tenantId}.dateSeparator.label`
                      )}
                    </MuiTypography>
                  </div>
                  <div style={endDate}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={leaveStartDateEnd}
                      id="date-picker-end-date"
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "",
                            size: "small",
                            sx: datePicker,
                          },
                        },
                        switchViewButton: {
                          sx: { padding: "5px" },
                        },
                      }}
                      minDate={
                        filterValues.leave_start_date_begin
                          ? new Date(filterValues.leave_start_date_begin)
                          : undefined
                      }
                      minDateMessage={t(
                        `dashboard.action.filter.fields.${tenantId}.startDateEnd.maxDate`
                      )}
                      onChange={(value) => {
                        const timestamp = Date.parse(value);
                        if (!isNaN(timestamp) || !value) {
                          dispatch({
                            action: "SET_LEAVE_STARTDATE_END",
                            value,
                          });
                        }
                      }}
                      format="yyyy-MM-dd"
                    />
                  </div>
                </div>
              </LocalizationProvider>
            </MuiGrid>
          </MuiGrid>
        )}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid
            container
            item
            xs={12}
            spacing={1}
            sx={{ margin: "0px !important", padding: "8px !important" }}
          >
            <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "4px" } }}>
              <MuiTypography
                display="inline"
                variant="body2"
                color="textPrimary"
              >
                {t(`dashboard.action.filter.fields.${tenantId}.endDate.label`)}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "4px" } }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div style={datePickerContainer}>
                  <div style={startDate}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={leaveEndDateBegin}
                      id="date-picker-start-date"
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "",
                            size: "small",
                            sx: datePicker,
                          },
                        },
                        switchViewButton: {
                          sx: { padding: "5px" },
                        },
                      }}
                      maxDate={
                        filterValues.leave_end_date_end
                          ? new Date(filterValues.leave_end_date_end)
                          : undefined
                      }
                      maxDateMessage={t(
                        `dashboard.action.filter.fields.${tenantId}.endDateBegin.minDate`
                      )}
                      onChange={(value) => {
                        const timestamp = Date.parse(value);
                        if (!isNaN(timestamp) || !value) {
                          dispatch({
                            action: "SET_LEAVE_ENDDATE_BEGIN",
                            value,
                          });
                        }
                      }}
                      format="yyyy-MM-dd"
                    />
                  </div>
                  <div>
                    <MuiTypography
                      variant="body2"
                      align="center"
                      sx={dateSeparator}
                    >
                      {t(
                        `dashboard.action.filter.fields.${tenantId}.dateSeparator.label`
                      )}
                    </MuiTypography>
                  </div>
                  <div style={endDate}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={leaveEndDateEnd}
                      id="date-picker-end-date"
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "",
                            size: "small",
                            sx: datePicker,
                          },
                        },
                        switchViewButton: {
                          sx: { padding: "5px" },
                        },
                      }}
                      minDate={
                        filterValues.leave_end_date_begin
                          ? new Date(filterValues.leave_end_date_begin)
                          : undefined
                      }
                      minDateMessage={t(
                        `dashboard.action.filter.fields.${tenantId}.endDateEnd.maxDate`
                      )}
                      onChange={(value) => {
                        const timestamp = Date.parse(value);
                        if (!isNaN(timestamp) || !value) {
                          dispatch({
                            action: "SET_LEAVE_ENDDATE_END",
                            value,
                          });
                        }
                      }}
                      format="yyyy-MM-dd"
                    />
                  </div>
                </div>
              </LocalizationProvider>
            </MuiGrid>
          </MuiGrid>
        )}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              multiple
              size="small"
              options={statusOptions}
              isOptionEqualToValue={(option, value) =>
                option.displayName === value.displayName
              }
              getOptionLabel={(option) => option.displayName}
              clearOnEscape={true}
              value={filterValues.status ?? []}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_LEAVE_STATUS",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.status.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.displayName}`}
                </div>
              )}
              fullWidth
              sx={multiSelectAutocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* RelMan */}
        {["relman"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(filterOptions?.release_project)}
              getOptionLabel={(option) =>
                option ? `${option.text} (${option.id})` : ""
              }
              clearOnEscape={true}
              value={filterValues.release_project}
              onChange={(event, value) => {
                return dispatch({
                  action: "SET_RELEASE_PROJECT",
                  value,
                });
              }}
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.releaseProject.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.text}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* RelMan */}
        {tenantId === "relman" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.release_rolling}
              getOptionLabel={(option) => option.text}
              clearOnEscape={true}
              value={filterValues.release_rolling}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_RELEASE_ROLLING",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.releaseRolling.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.text}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* RelMan */}
        {tenantId === "relman" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.release_high_profile}
              getOptionLabel={(option) => option.text}
              clearOnEscape={true}
              value={filterValues.release_high_profile}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_RELEASE_HIGH_PROFILE",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.releaseHighProfile.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.text}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* RelMan */}
        {tenantId === "relman" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.release_requires_coordination}
              getOptionLabel={(option) => option.text}
              clearOnEscape={true}
              value={filterValues.release_requires_coordination}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_RELEASE_REQUIRES_COORDINATION",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.releaseRequiresCoordination.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.text}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* RelMan*/}
        {["relman"].includes(tenantId) && (
          <MuiGrid
            container
            item
            xs={12}
            spacing={1}
            sx={{ margin: "0px !important", padding: "8px !important" }}
          >
            <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "4px" } }}>
              <MuiTypography
                display="inline"
                variant="body2"
                color="textPrimary"
              >
                {t(
                  `dashboard.action.filter.fields.${tenantId}.releaseRequestDate.label`
                )}
              </MuiTypography>
            </MuiGrid>
            <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "4px" } }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div style={datePickerContainer}>
                  <div style={startDate}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={releaseRequestDate}
                      id="date-picker-start-date"
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "",
                            size: "small",
                            sx: datePicker,
                          },
                        },
                        switchViewButton: {
                          sx: { padding: "5px" },
                        },
                      }}
                      maxDate={
                        filterValues.release_request_enddate
                          ? new Date(filterValues.release_request_enddate)
                          : undefined
                      }
                      maxDateMessage={t(
                        `dashboard.action.filter.fields.${tenantId}.releaseRequestDate.minDate`
                      )}
                      onChange={(value) => {
                        const timestamp = Date.parse(value);
                        if (!isNaN(timestamp) || !value) {
                          dispatch({
                            action: "SET_RELEASE_STARTDATE",
                            value,
                          });
                        }
                      }}
                      format="yyyy-MM-dd"
                    />
                  </div>
                  <div>
                    <MuiTypography
                      variant="body2"
                      align="center"
                      sx={dateSeparator}
                    >
                      {t(
                        `dashboard.action.filter.fields.${tenantId}.dateSeparator.label`
                      )}
                    </MuiTypography>
                  </div>
                  <div style={endDate}>
                    <DatePicker
                      views={["year", "month", "day"]}
                      value={releaseRequestEnddate}
                      id="date-picker-end-date"
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "",
                            size: "small",
                            sx: datePicker,
                          },
                        },
                        switchViewButton: {
                          sx: { padding: "5px" },
                        },
                      }}
                      minDate={
                        filterValues.release_requested_date
                          ? new Date(filterValues.release_requested_date)
                          : undefined
                      }
                      minDateMessage={t(
                        `dashboard.action.filter.fields.${tenantId}.releaseRequestEndDate.maxDate`
                      )}
                      onChange={(value) => {
                        const timestamp = Date.parse(value);
                        if (!isNaN(timestamp) || !value) {
                          dispatch({
                            action: "SET_RELEASE_ENDDATE",
                            value,
                          });
                        }
                      }}
                      format="yyyy-MM-dd"
                    />
                  </div>
                </div>
              </LocalizationProvider>
            </MuiGrid>
          </MuiGrid>
        )}
        {/* RelMan */}
        {tenantId === "relman" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.release_request_status}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.release_request_status}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_RELEASE_STATUS",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.releaseStatus.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/*RelMan*/}
        {/* {tenantId === "relman" && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.release_request_status}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.release_request_status}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_RELEASE_STATUS",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.releaseStatus.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )} */}
        {/* MedLeave */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={filterOptions.request_status}
              getOptionLabel={(option) => option}
              clearOnEscape={true}
              value={filterValues.request_status}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_REQUEST_STATUS",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.requestStatus.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        {/* MedLeave, */}
        {["medleave"].includes(tenantId) && (
          <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
            <MuiAutocomplete
              options={getFormattedOptions(filterOptions?.rpm_contact_name)}
              getOptionLabel={(option) => (option ? option.primaryName : "")}
              clearOnEscape={true}
              value={filterValues.rpm_contact_name}
              onChange={(event, value) =>
                dispatch({
                  action: "SET_RPM_CONTACT",
                  value,
                })
              }
              renderInput={(params) => (
                <MuiTextField
                  {...params}
                  label={t(
                    `dashboard.action.filter.fields.${tenantId}.rpmContact.label`
                  )}
                  variant="outlined"
                />
              )}
              renderOption={(props, option) => (
                <div style={{ fontSize: "15px" }} {...props}>
                  {`${option.primaryName}`}
                </div>
              )}
              fullWidth
              sx={autocompleteStyles}
            />
          </MuiGrid>
        )}
        <MuiGrid item xs={12} sx={{ "&.MuiGrid-item": { padding: "12px" } }}>
          <ResetFilter resetFn={dispatch} />
        </MuiGrid>
      </MuiGrid>
    </MuiBox>
  );
};

const getFormattedOptions = (options) => {
  if (!Boolean(options)) return [];
  return options?.filter((item) => item !== "{}");
};
