import isJSON from "is-json";
import { format, isValid, parseISO } from "date-fns";
import { REGEX, FormList } from "app/shared/constants";

// Returns text interpolated with values for the fields specified within brackets [].
// Optionally wrap field values in the resulting text with a character or string.

// Call this function for all formspec text that can include dynamic field values.

export const replaceFieldsInText = (text, fields, wrapValuesWith = "") => {
  const matches = text.match(/\[(.*?)\]/g) || [];

  return matches.reduce((prevText, match) => {
    const key = match.slice(1, -1);
    return prevText.replace(
      match,
      `${wrapValuesWith}${fields[key] || ""}${wrapValuesWith}`
    );
  }, text);
};

// Evaluate the formspec branching logic expression after substituting dynamic
// field values and converting the expression to javascript format.
// Because code in the formspec loaded from the backend is trusted, the expression
// is executed with eval.
export const evalBranchingLogic = (branchingLogic, fields) => {
  if (!branchingLogic) {
    return true;
  }

  const expression = replaceFieldsInText(branchingLogic, fields, "'")
    .replace(/=/g, "==")
    .replace(/ and /gi, " && ")
    .replace(/ or /gi, " || ");

  // eslint-disable-next-line no-eval
  return eval(expression);
};

export const replaceFieldCalculationValues = (
  text,
  fields,
  fieldValueChoice = "",
  fieldEditable = ""
) => {
  const matches = text.match(/\{.+?\}/g) || [];
  return matches.reduce((prevText, match) => {
    const key = match.slice(1, -1);
    return prevText.replace(
      "${" + key + "}",
      getParsedString(fields, key, fieldValueChoice, fieldEditable).replace(
        /\$/g,
        "$$$$"
      )
    );
  }, text);
};

export const evalFieldCalculation = (fieldCalculation, fields) => {
  if (!fieldCalculation) {
    return true;
  }

  const expression = replaceFieldCalculationValues(
    fieldCalculation,
    fields,
    "'"
  );

  // eslint-disable-next-line no-eval
  return eval(expression);
};

export const setFieldsInText = (
  text,
  fields,
  otherValues,
  setReplacedHTML,
  wrapValuesWith = ""
) => {
  if (!text) return "";
  const matches = text.match(/\$\{.+?\}/g) || [];
  setReplacedHTML &&
    setReplacedHTML(
      matches.reduce((prevText, match) => {
        const key = match.slice(2, -1);
        const replacedString = prevText.replace(
          match,
          getString(fields, key, otherValues, wrapValuesWith).replace(
            /\$/g,
            "$$$$"
          )
        );
        return replacedString;
      }, text)
    );
};

export const getString = (fields, key, otherValues, wrapValuesWith) => {
  const splitkey = key.split(".");
  const fieldRow = otherValues.formSpec.filter(
    (item) => item.field_id === splitkey[0]
  );
  if (key === "id") {
    return otherValues.actionId;
  } else if (fieldRow.length && fieldRow[0].field_value_type.includes("ref")) {
    return splitkey.length > 1
      ? fields[splitkey[0]] && splitkey[1] && fields[splitkey[0]]?.[splitkey[1]]
      : getRefString(fields, key);
  } else if (fieldRow.length && fieldRow[0].field_value_type.includes("org")) {
    return splitkey.length > 1
      ? fields[splitkey[0]] && splitkey[1] && fields[splitkey[0]]?.[splitkey[1]]
      : getOrgString(fields, key);
  } else {
    if (
      (fieldRow.length &&
        fieldRow[0].field_type === "dropdown" &&
        fieldRow[0].field_options !== "[current_user_department_orgs]" &&
        fieldRow[0]?.field_format?.indexOf("@API") === -1) ||
      (fieldRow.length && fieldRow[0].field_type === "radio")
    ) {
      return `${wrapValuesWith}${
        getDropdownValue(fieldRow[0], fields[key]) || ""
      }${wrapValuesWith}`;
    } else if (fieldRow.length && fieldRow[0].field_type === "checkbox") {
      return `${wrapValuesWith}${
        (fields[key] && getCheckboxValue(fieldRow[0], fields[key])) || ""
      }${wrapValuesWith}`;
    } else if (fieldRow.length && fieldRow[0].field_value_type === "person") {
      return (
        fields[key] &&
        `${fields[key].primaryLastName}, ${fields[key].primaryFirstName} ${
          fields[key].primaryMiddleName || ""
        } `
      );
    }
    return `${wrapValuesWith}${fields[key] || ""}${wrapValuesWith}`;
  }
};

const getOrgString = (fields, key) => {
  return fields[key] ? `${fields[key].orgName} (${fields[key].orgCode})` : "";
};

const getRefString = (fields, key) => {
  return fields[key] ? `${fields[key].text}` : "";
};

const getDropdownValue = (element, fieldValue) => {
  if (isJSON(element.field_options)) {
    let optionsArray = Object.entries(JSON.parse(element.field_options));
    let dropdownValue = "";

    optionsArray.forEach((item) => {
      if (fieldValue === item[0]) {
        dropdownValue = item[1];
      }
    });
    return dropdownValue;
  }
};

const getIsCheckedValue = (value) => {
  if (!value) return [];
  // Coded temporarily, as we're not the value of type JSON in response from API.
  // It will get removed once we start getting response of type JSON.
  if (typeof value === "string")
    return value
      .match(/\[(.*?)\]/)[1]
      .split(",")
      .map((item) => item.trim());
  return value;
};

const getCheckboxValue = (element, fieldValue) => {
  const valueArray = getIsCheckedValue(fieldValue);
  try {
    let optionsArray = Object.entries(JSON.parse(element.field_options));
    const checkboxValues = valueArray;

    let response =
      "<ul style='list-style-type:none;margin:0;padding:0;font-size:15px;'>";
    optionsArray.forEach((item, index) => {
      if (Boolean(checkboxValues[index])) {
        response += `<li>${item[1]}</li>`;
      }
    });
    response += "</ul>";

    return response;
  } catch (err) {
    console.log(err.message);
  }
};

const getParsedString = (fields, key, fieldValueChoice, fieldEditable) => {
  if (fieldEditable !== "" && key === "_field_editable") return fieldEditable;

  if (getFormAccess() && key === "_form_editable")
    return getFormAccess().toLowerCase() === "edit" ? "true" : "false";

  if (
    (typeof fields[key] === "string" || typeof fields[key] === "number") &&
    fields[key] !== ""
  ) {
    return `"${encode(fields[key])}"`;
  } else {
    if (fieldValueChoice.includes("org") && isJSON(fields[key])) {
      return JSON.parse(fields[key]);
    }
    return JSON.stringify(fields[key]) || '""';
  }
};

export const getStoreValue = (key) => {
  if (!key) return "";
  const item = localStorage.getItem(
    `${key}-${sessionStorage.getItem("tenantId")}`
  );
  return isJSON(item) ? JSON.parse(item) : item;
};

export const setStoreValue = (key, value) => {
  if (!key) return "";
  localStorage.setItem(
    `${key}-${sessionStorage.getItem("tenantId")}`,
    JSON.stringify(value)
  );
};

export const removeStoreValue = (key) => {
  if (!key) return "";
  localStorage.removeItem(`${key}-${sessionStorage.getItem("tenantId")}`);
};

export const isReadonly = (element) => {
  // GET - readOnly attribute from column `field_attributes`
  const attributes =
    element.field_attributes && isJSON(element.field_attributes)
      ? JSON.parse(element.field_attributes)
      : null;

  // Priority to readOnly attribute
  return Boolean(attributes?.readOnly);
};

export const shouldRenderElement = (
  renderedExp,
  valueType,
  formValueObj,
  fieldEditable
) => {
  try {
    // Return TRUE, if there's no field rendered logic defined
    if (!renderedExp) return true;

    // eslint-disable-next-line no-eval
    return eval(
      // Preparing field-rendered expression
      replaceFieldCalculationValues(
        renderedExp,
        formValueObj,
        valueType,
        fieldEditable
      )
    );
  } catch (err) {
    console.log(err);
    return false;
  }
};

export const getCalculatedExp = (fieldCalculation, formValueObj, valueType) => {
  return replaceFieldCalculationValues(
    fieldCalculation,
    formValueObj,
    valueType
  );
};

export const addFieldValidation = ({
  name,
  evaledExp,
  required,
  touched,
  helpertext,
  validationType,
  validationScheme,
  validationMessage,
  t,
  formik,
  setFieldValidation,
  setFieldHelper,
  formFlatMap,
  globalValueObj,
  yup,
  element,
  readOnly,
  duplicateIds,
}) => {
  // Applying checks for field_required and add validation if the value of field_required is true
  /**
   * IF
   *    field_rendered = true (after eval)
   *    field_required = true
   *    field is NOT readonly
   *    field has a non-empty input
   */
  if (
    evaledExp &&
    required &&
    !readOnly &&
    !getFieldValue(name, element.field_type, formik)
  ) {
    formik.errors[name] = t("forms.validationMessage.general.required", {
      label: element.field_label,
    });
    touched ? setFieldHelper(formik.errors[name]) : setFieldHelper(helpertext);
  } else {
    /**
     * ELSE
     *      Remove the field from formik errors (because it got value)
     *      Set the field helper text to its previous value (as due to error it got overridden by error text)
     */
    if (getFieldValue(name, element.field_type, formik) || !required) {
      delete formik.errors[name];
      setFieldHelper(helpertext);
    }
  }

  //Check manually entered dates by the user
  if (["date"].includes(element.field_type) && formik.values[name]) {
    const dateCheckArgs = {
      element,
      name,
      required,
      helpertext,
      formik,
      setFieldValidation,
      setFieldHelper,
      t,
    };

    manualDateValidation(dateCheckArgs);
  }

  if (
    ["money", "number", "percent"].includes(element.field_type) &&
    element.field_attributes &&
    String(element.field_editable) === "true"
  ) {
    // Check for minValue, maxValue attribute
    const { minValue, maxValue } = isJSON(element.field_attributes)
      ? JSON.parse(element.field_attributes)
      : "";

    // Check if user input is less than minValue
    if (
      minValue &&
      maxValue >= minValue &&
      formik.values[name] < minValue &&
      getFieldValue(name, element.field_type, formik)
    ) {
      const message = `${element.field_label} value cannot be less than ${minValue}`;

      setFieldValidation(true);
      formik.errors[name] = message;
      setFieldHelper(message);
    } else {
      if (
        (required && getFieldValue(name, element.field_type, formik)) ||
        !required
      ) {
        delete formik.errors[name];
        setFieldValidation(false);
      }
    }
  }

  // Checking for key `type` (as validationType)
  if (
    validationType === "custom" &&
    String(element.field_editable) === "true"
  ) {
    // Preprartion for `type` custom
    if (validationScheme) {
      // get the placeholder values of corresponding field_id defined in field validation
      let calculatedValidationType = getCalculatedExp(
        validationScheme,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_value_type
      );

      // TODO: Determine if any of this date checking is necessary inside this
      // custom validation if-block. As the code is currently structured, any
      // "field_type === date" fields are handled by the if-statement 2 above
      // this one. Therefore, date validation here seems redundant.
      //
      // Helper function to validate user-typed dates
      const isValidDate = (field_id) => {
        // Check for minDate & maxDate attributes
        const { minDate, maxDate } = isJSON(element.field_attributes)
          ? JSON.parse(element.field_attributes)
          : "";

        // eslint-disable-next-line no-eval
        const evaledMinDate = eval(minDate) || null;
        // eslint-disable-next-line no-eval
        const evaledMaxDate = eval(maxDate) || null;

        const getLocaleMinDateOnly = new Date(
          evaledMinDate
        ).toLocaleDateString();
        const getLocaleMaxDateOnly = new Date(
          evaledMaxDate
        ).toLocaleDateString();

        const getFieldISODateString =
          formik.values[field_id] && parseISO(formik.values[field_id]);

        const fieldVal = getFieldValue(field_id, element.field_type, formik);
        const validDate = isValid(new Date(fieldVal));
        const userInputTime = new Date(getFieldISODateString).getTime();
        const minDateTime = new Date(getLocaleMinDateOnly).getTime();
        const maxDateTime = new Date(getLocaleMaxDateOnly).getTime();
        let minDatePassed = false;
        let maxDatePassed = false;

        // Check  minDate validation if available
        if (evaledMinDate === "null") {
          // Shouldn't this be regular null, not the string? Line 395 uses regular null
          if (validDate) {
            minDatePassed = true;
          }
        } else if (validDate && userInputTime > minDateTime) {
          minDatePassed = true;
        }

        // A Ternary to determine the input to an if-statement is clever,
        // but not easy to read at a glance.
        //   Maintainability > Cleverness
        //
        // if (evaledMinDate === "null"
        //     ? validDate
        //     : validDate && userInputTime > minDateTime
        // ) {
        //   minDatePassed = true;
        // }

        // Check maxDate validation if available
        if (evaledMaxDate === "null") {
          if (validDate) {
            maxDatePassed = true;
          }
        } else if (validDate && userInputTime < maxDateTime) {
          maxDatePassed = true;
        }

        return minDatePassed && maxDatePassed;
      };

      if (
        // eslint-disable-next-line no-eval
        !eval(calculatedValidationType) &&
        getFieldValue(name, element.field_type, formik)
      ) {
        setFieldValidation(true);
        formik.errors[name] = validationMessage;
        setFieldHelper(validationMessage);
      } else {
        // TODO: (Continuation of the TODO above the isValidDate declaration)
        if (
          (element.field_type === "date"
            ? isValidDate(name)
            : required && getFieldValue(name, element.field_type, formik)) ||
          !required
        ) {
          delete formik.errors[name];
          setFieldValidation(false);
          setFieldHelper(helpertext);
        }
      }
    }
  } else if (
    // Pass if condition if type is any one of the mentioned options
    ["email", "phone", "regex"].includes(validationType) &&
    String(element.field_editable) === "true"
  ) {
    // Defined regular expression for phone under file constants.js
    const phoneRegex = REGEX.phone;
    // Checking if phone then pass regext from constant.js else pass one from formspec `test`
    const regex = validationType === "phone" ? phoneRegex : validationScheme;

    // Preparaing schema for email and other
    const schema =
      validationType === "email"
        ? // Using `.email` from Yup validation to check validity against email address
          yup.string().email()
        : // Using `.matches` from Yup validation to check validity against regular expression
          yup.string().matches(regex);

    // Using isValidSync funciton from Yup library to check for field validity
    const isValid = schema.isValidSync(formik.values[name]);

    // If not valid, then apply field validation and set error message
    if (!isValid && getFieldValue(name, element.field_type, formik)) {
      // Setting custom error message `validationMessage` defined in formspec under field_validation `message` key
      formik.errors[name] = validationMessage;
      // Setting field validation to true
      setFieldValidation(true);
      // Setting field helper text to the error message (overriding the one from `field_helper` due to error in the field)
      setFieldHelper(validationMessage);
    } else {
      if (
        (required && getFieldValue(name, element.field_type, formik)) ||
        !required
      ) {
        delete formik.errors[name];
        setFieldValidation(false);
        setFieldHelper(helpertext);
      }
    }
  }

  // Checks for duplicate Ids.
  // It needs to be smoothen in future.
  if (!evaledExp) {
    // Checking the duplicateIds as due to duplicate Ids in formspec, we were getting the values
    // override.

    // ** This logic needs to be improved with other stable solution by covering more cases
    if (duplicateIds.indexOf(name) === -1) {
      globalValueObj[name] = "";
      formik.values[name] = "";
      formFlatMap[name] = "";
      delete formik.errors[name];
      setFieldHelper(helpertext);
    }
  }
};

/**
 * Helper method to handle date validation when the user manually inputs one.
 * (The DatePicker itself manages validation when using the picker widget)
 *
 * Checks for input, format of the input aligns to a valid date, if the field is
 * required and is not blank, as well as checking that the given date is within
 * any specified minimum and maximum bounds.
 *
 * If any checks fail, the appropriate fields' validation error messages get set.
 * Otherwise the validation errors are cleared.
 *
 * @param {object} params Various objects and fns passed into addFieldValidation
 */
const manualDateValidation = ({
  element,
  name,
  required,
  helpertext,
  formik,
  setFieldValidation,
  setFieldHelper,
  t,
}) => {
  const { minDate, maxDate } = isJSON(element.field_attributes)
    ? JSON.parse(element.field_attributes)
    : "";

  // eslint-disable-next-line no-eval
  const evaledMinDate = eval(minDate);
  // eslint-disable-next-line no-eval
  const evaledMaxDate = eval(maxDate);

  const getLocaleMinDateOnly = new Date(evaledMinDate).toLocaleDateString();
  const getLocaleMaxDateOnly = new Date(evaledMaxDate).toLocaleDateString();

  const getFieldISODateString =
    formik.values[name] && parseISO(formik.values[name]);

  // Various validations for manual user input dates
  if (formik.values[name] && !isValid(new Date(formik.values[name]))) {
    // Check user's input is a valid date and in a correct format
    const validationMessage = t("forms.validationMessage.date.invalidDate", {
      label: element.field_label,
    });

    setFieldValidation(true);
    formik.errors[name] = validationMessage;
    setFieldHelper(validationMessage);
  } else if (
    element.field_editable === "true" &&
    sessionStorage.getItem("formEditable") === "EDIT" &&
    formik.values[name]
  ) {
    // Date & format are valid, check the date falls within the
    // minimum and maximum bounds if specified in the formSpec
    const userInputTime = new Date(getFieldISODateString).getTime();
    let validationMessage = "";

    // Check minDate boundary
    if (evaledMinDate) {
      const minDateTime = new Date(getLocaleMinDateOnly).getTime();
      if (userInputTime < minDateTime) {
        validationMessage = t("forms.validationMessage.date.minDateFailure", {
          label: element.field_label,
        });
      }
    }

    // Check maxDate boundary
    if (evaledMaxDate) {
      const maxDateTime = new Date(getLocaleMaxDateOnly).getTime();
      if (userInputTime > maxDateTime) {
        validationMessage = t("forms.validationMessage.date.maxDateFailure", {
          label: element.field_label,
        });
      }
    }

    // Did one fail? Set validation error messages
    if (validationMessage.length > 0) {
      setFieldValidation(true);
      formik.errors[name] = validationMessage;
      setFieldHelper(validationMessage);
    } else {
      // User's input is valid, clear previous validation errors, if any
      delete formik.errors[name];
      setFieldValidation(false);
      setFieldHelper(helpertext);
    }
  } else {
    // Field is blank, check if required
    if (
      (required && getFieldValue(name, element.field_type, formik)) ||
      !required
    ) {
      // User's input is valid, clear previous validation errors, if any
      delete formik.errors[name];
      setFieldValidation(false);
      setFieldHelper(helpertext);
    }
  }
};

const getFieldValue = (name, type, formik) => {
  if (["text", "number", "money", "percent", "date"].includes(type))
    return String(formik.values[name]).trim();
  else if (type === "checkbox") {
    return Array.isArray(formik.values[name]) ? formik.values[name].length : "";
  } else if (["radio", "dropdown"].includes(type)) {
    return formik.values[name] !== "{}" && Boolean(formik.values[name]);
  }
  return false;
};

export const getFormattedDate = (dateString) => {
  const utcDate = new Date(dateString + "Z");
  return format(
    new Date(
      utcDate.toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
      })
    ),
    "yyyy/LL/dd hh:mm a"
  );
};

export const encode = (expression) => {
  return ("" + expression)
    .replace(/\\/g, "\\\\")
    .replace(/\t/g, "\\t")
    .replace(/\n/g, "\\n")
    .replace(/\u00A0/g, "\\u00A0")
    .replace(/&/g, "\\x26")
    .replace(/’/g, "\\x27")
    .replace(/"/g, "\\x22")
    .replace(/</g, "\\x3C")
    .replace(/>/g, "\\x3E");
};

export const getFormAccess = () => {
  return sessionStorage.getItem("formEditable");
};

export const generateFormServiceUrl = (redirectFormUrl, tenantId) => {
  let routeName;
  let actionId;
  // filterFormName to filter out falsy values from the array
  const filterSplitUrl = (nameArray) => {
    return nameArray.filter((item) => item);
  };

  const apiBaseUrl = process.env.REACT_APP_API_BASEURL.replace(
    "DEPLOY_ENV",
    window.DEPLOY_ENV
  );

  const splitUrl = filterSplitUrl(redirectFormUrl.split("/"));

  FormList[tenantId].forEach((item) => {
    if (splitUrl.includes(item)) {
      routeName = item;
      actionId = splitUrl[1];
    }
  });

  return (
    routeName &&
    actionId &&
    `${apiBaseUrl}/actions/${actionId}/forms/${routeName}`
  );
};
