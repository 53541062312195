import { useTranslation } from "react-i18next";
import {
  Typography as MuiTypography,
  Link as MuiLink,
  Grid as MuiGrid,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";

export const ResetFilter = ({ resetFn }) => {
  const { t } = useTranslation();

  return (
    <MuiGrid container justifyContent="flex-end" alignItems="center">
      <ClearIcon sx={{ fontSize: "18.75px" }} />
      <MuiLink
        component="button"
        underline="none"
        color="textPrimary"
        onClick={() => resetFn({ action: "RESET" })}
      >
        <MuiTypography variant="body2" sx={{ letterSpacing: "normal" }}>
          {t("dashboard.action.filter.resetLabel")}
        </MuiTypography>
      </MuiLink>
    </MuiGrid>
  );
};
