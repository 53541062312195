import { useState, useEffect } from "react";
import axios from "axios";
import { useAlerts } from "common";

export const useDownload = (source) => {
  const [url, setUrl] = useState("");
  const { setAlert, clearAlert } = useAlerts();

  useEffect(() => {
    if (url) downloadDocument(url, clearAlert, setAlert, source);
    return () => {
      setUrl("");
    };
  }, [url, clearAlert, setAlert, source]);

  return { getDocument: setUrl };
};

const downloadDocument = async (url, clearAlert, setAlert, source) => {
  clearAlert();
  try {
    const response = await axios({
      url,
      method: "GET",
      responseType: "blob",
    });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(response.data);
    } else {
      const contentDisposition = response.headers["content-disposition"];
      const fileName = contentDisposition
        ?.slice(contentDisposition?.indexOf("=") + 1)
        ?.replace(/["]+/g, "");
      const docUrl = window.URL.createObjectURL(new Blob([response.data]));
      const docLink = document.createElement("a");
      docLink.href = docUrl;
      docLink.setAttribute("download", fileName);
      document.body.appendChild(docLink);
      docLink.click();
    }
  } catch (error) {
    setAlert("dialog", error.message);
  }
};
