import { Grid as MuiGrid } from "@mui/material";
import { DropzoneArea } from "mui-file-dropzone";
import { dropZoneStyles } from "./DropZone.styles";
import { UPLOAD_ATTACHMENT_MAX_SIZE } from "app/shared/constants";

export const DropZone = (props) => {
  const options = {
    acceptedFiles: props.acceptedFiles || [
      "image/png", // png
      "image/jpeg", // jpeg
      "image/gif", // giff
      "image/tiff", // tiff
      "application/pdf", // pdf
      "text/plain", // pdf
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // docx
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xls
      "application/vnd.ms-excel", // xlsx
      "application/vnd.ms-outlook", // msg
      ".msg", // msg via extension
    ],
    filesLimit: 1,
    dropzoneText: props.dropzoneText,
    disableRejectionFeedback: false,
    showPreviews: false,
    showPreviewsInDropzone: false,
    showFileNamesInPreview: false,
    previewText: "",
    showAlerts: false,
  };

  return (
    <MuiGrid sx={dropZoneStyles}>
      <DropzoneArea
        onChange={props.handleDocumentUpload}
        onDrop={props.handleDrop}
        onDropRejected={props.handleDropRejected}
        maxFileSize={UPLOAD_ATTACHMENT_MAX_SIZE}
        {...options}
      />
    </MuiGrid>
  );
};
