// Orgs service handling integration with the orgs API
// and transformation of org data. Exports a custom hook.

import { useState, useEffect, createContext, useContext } from "react";
import { useAlerts } from "common";
import { useNavigate } from "react-router-dom";

const RoutesContext = createContext();

export const RoutesProvider = ({ children }) => {
  const [serviceUrl, setServiceUrl] = useState({ apiUrl: "" });
  const { setAlert } = useAlerts();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        const { apiUrl } = serviceUrl;
        if (apiUrl) {
          const formName = getFormFromService(apiUrl);
          const actionId = getActionIDFromService(apiUrl);

          // Remove `_form` from the end of the formName
          const newFormName = formName.substr(0, formName.lastIndexOf("_"));
          // Replacing all `_` with `-` and form a route
          const routeName = newFormName.replace(/_/g, "-");
          setServiceUrl({ apiUrl: "" });
          navigate(`/${routeName}/${actionId}`);
        }
      } catch (error) {
        setAlert("error", error.message);
      }
    })();
  }, [setAlert, serviceUrl, navigate]);

  return (
    <RoutesContext.Provider value={{ serviceUrl, setServiceUrl }}>
      {children}
    </RoutesContext.Provider>
  );
};

export const useRoutes = () => {
  return useContext(RoutesContext);
};

// Function to get FORM NAME from SERVICE URL
const getFormFromService = (serviceUrl) => {
  return serviceUrl.substring(serviceUrl.lastIndexOf("/") + 1);
};

// Function to get ACTION ID from SERVICE URL
const getActionIDFromService = (serviceUrl) => {
  const actionSegment = serviceUrl.split("/")[6];
  return parseInt(actionSegment) > 0 ? serviceUrl.split("/")[6] : "";
};
