import { useEffect, useState } from "react";
import {
  Button as MuiButton,
  Grid as MuiGrid,
  Box as MuiBox,
} from "@mui/material";
import { shouldRenderElement } from "app/shared/utils";

export const ButtonCollection = (props) => {
  const {
    type,
    formik,
    onClick,
    name,
    element,
    formFlatMap,
    globalValueObj,
    render,
  } = props;
  const [fieldRenderedExp, setFieldRenderedExp] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);

  let buttonArray = type.split("|");

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation

    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_editable
      )
    );
  }, [element, globalValueObj, formFlatMap, formik, render, type]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp);

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRenderedExp, name, render]);

  if (!shouldRender) return null;
  if (!buttonArray.length) return "";

  const getButtonMarkup = (item) => {
    const buttonType = item.trim().toLowerCase();
    const saveForLater = (
      <MuiButton
        type="button"
        color="primary"
        variant="contained"
        fullWidth={true}
        disabled={formik.isSubmitting}
        sx={{ width: 152, fontSize: "17px" }}
        onClick={onClick}
      >
        {props.label}
      </MuiButton>
    );
    const submit = (
      <MuiButton
        type="button"
        color="primary"
        variant="contained"
        fullWidth={true}
        // Form button would be disabled in two conditions metioned below:
        // 1. Form data is not valid (required fields are empty)
        // 2. Form is in submitting state
        disabled={!formik.isValid || formik.isSubmitting}
        sx={{ width: 120, fontSize: "17px" }}
        onClick={onClick}
      >
        {props.label}
      </MuiButton>
    );
    const deleteSSN = (
      <MuiButton
        type="button"
        color="primary"
        variant="contained"
        fullWidth={true}
        onClick={onClick}
      >
        {props.label}
      </MuiButton>
    );
    const button = (
      <MuiButton
        component="button"
        color="primary"
        variant="outlined"
        fullWidth={true}
        sx={{ width: 120, fontSize: "17px" }}
        onClick={onClick}
      >
        {props.label}
      </MuiButton>
    );

    switch (buttonType) {
      case "cancel":
        return button;
      case "close":
        return button;
      case "back":
        return button;
      case "delete_ssn":
        return deleteSSN;
      case "submit":
      case "save":
      case "approve":
        return submit;
      case "save_for_later":
        return saveForLater;
      default:
        break;
    }
  };

  return (
    <MuiBox mr={2} mt={2}>
      {buttonArray.map((button, index) => (
        <MuiGrid item key={index}>
          {getButtonMarkup(button)}
        </MuiGrid>
      ))}
    </MuiBox>
  );
};
