import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  useNavigate,
  useNavigationType,
  useLocation,
  NavigationType,
} from "react-router-dom";
import {
  Box as MuiBox,
  ClickAwayListener as MuiClickAwayListener,
  Grow as MuiGrow,
  MenuItem as MuiMenuItem,
  MenuList as MuiMenuList,
} from "@mui/material";
import { ArrowDropDownOutlined as MuiArrowDropDownOutlinedIcon } from "@mui/icons-material";
import {
  NavbarAppBar,
  NavbarPaper,
  NavbarTab,
  NavbarTabs,
  NavbarPopper,
} from "./Navbar.styles";

/**
 * A generic navigation bar, like a sub-header that will dynamically render any number of menu options,
 * including menu options with sub-menus/options.
 *
 * @param {array} menuItems A list of items to be rendered into the navigation bar (see: src/app/shared/config)
 * @param {number} width The width in pixels (appears to be unused/depricated)
 * @param {object?} navbarConfig (UNKNOWN (unused?))
 */
export const Navbar = ({ menuItems, width, navbarConfig }) => {
  const [selectedMenu, setSelectedMenu] = useState(0);
  const [selectedSubMenu, setSelectedSubMenu] = useState("");
  const [indicatorPosition, setIndicatorPosition] = useState(0);
  const navigate = useNavigate();

  let inputRefs = [];

  function setRef(ref) {
    inputRefs.push(ref);
  }

  // Click handler for the main navigation options (i.e. not the sub-menus)
  function handleTabClick(event, index) {
    // Use the callToggle method exposed by our imperativeHandle
    //   Lets the sub-menus, if any, know they can toggle
    inputRefs[index].callToggle(event);
  }

  useEffect(() => {
    // Only run if we have a navbarConfig
    //   Why wouldn't we have one? Or when would we need/want one?
    //   How does navbarConfig differ from menuItems?
    if (navbarConfig) {
      if (navbarConfig.route) navigate(navbarConfig.route);

      // Why do we want this index?
      let index = menuItems.findIndex((x) => {
        return (
          // Does the menuItem have a route property & is it our navConfig route?
          //   (This should be it's own function so it's clear what we're looking for)
          x.route ===
            navbarConfig.route.split("/").slice(0, 2).join("/").split("?")[0] ||
          // Does the menuItem have child list elements & do any of their route properties match our navConfig route?
          x.menuItem.some((el) => {
            // (This should be it's own function so it's clear what we're looking for)
            return (
              el.route ===
              navbarConfig.route.split("/").slice(0, 3).join("/").split("?")[0]
            );
          })
        );
      });

      setSelectedMenu(index);

      // Should this be "index >= 0"? Since array.findIndex() can return 0 as a valid index
      //   Also does checking the menuItems length matter? The findIndex above would bail and return -1 if it was empty
      if (menuItems.length && index > 0) {
        let submenu = menuItems[index].menuItem.find(
          ({ route }) =>
            // This should be it's own function so it's clear what we're looking for
            route ===
            navbarConfig.route.split("/").slice(0, 3).join("/").split("?")[0]
        );

        if (submenu) {
          setSelectedSubMenu(index + submenu.name);
        }
      }
    }

    // Shifts the tab indicator's position based on selectedMenu when the tabs are stacked
    setIndicatorPosition(((selectedMenu + 1) / menuItems.length) * 100 + "%");
  }, [width, menuItems, navigate, navbarConfig, selectedMenu]);

  return (
    <nav>
      <MuiBox size={menuItems.length}>
        <NavbarAppBar position="static">
          <div className="container">
            <NavbarTabs
              value={selectedMenu}
              orientation="horizontal"
              indicator={indicatorPosition}
              className="show"
            >
              {menuItems &&
                menuItems.map((menu, index) => {
                  return (
                    <NavbarTab
                      key={index + "Tab"}
                      label={
                        <MenuListComposition
                          name={menu.name}
                          route={menu.route}
                          external={menu.external}
                          setSelectedMenu={setSelectedMenu}
                          selectedMenu={selectedMenu}
                          index={index}
                          ref={setRef}
                          setSelectedSubMenu={setSelectedSubMenu}
                          selectedSubMenu={selectedSubMenu}
                        >
                          {menu.menuItem}
                        </MenuListComposition>
                      }
                      value={index}
                      name="Tab"
                      onClick={(e) => handleTabClick(e, index)}
                    />
                  );
                })}
            </NavbarTabs>
          </div>
        </NavbarAppBar>
      </MuiBox>
    </nav>
  );
};

// Recommend this be renamed to NavbarMenuItem to better reflect what we're building here
//   Also recommend this be moved to its own file as it is a distinct component
const MenuListComposition = forwardRef((props, ref) => {
  /**
   * Original code of this component copied & adapted from:
   *   https://mui.com/material-ui/react-menu/#menulist-composition
   */
  const [open, setOpen] = useState(false);
  const [target, setTarget] = useState(null);
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const navType = useNavigationType();

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(false);

  useEffect(() => {
    // Re-render & unmount cleanup logic
    return () => {
      function findSubMenuItemByLocationPath(locationPathName) {
        return props.children.find((item) => item.route === locationPathName);
      }

      // If user navigated, ensure correct menu items are selected and any submenus are closed
      if (navType === NavigationType.Pop) {
        if (props.route && props.route === location.pathname) {
          setOpen(false);
          props.setSelectedMenu(props.index);
          props.setSelectedSubMenu(null);
        } else if (props.children.length > 0) {
          const subMenu = findSubMenuItemByLocationPath(location.pathname);

          if (subMenu) {
            setOpen(false);
            props.setSelectedMenu(props.index);
            props.setSelectedSubMenu(`${props.index}${subMenu.name}`);
          }
        }
      }
    };
  }, [navType, location, props.route, props]);

  // Give the parent component access to menu toggling logic via the forwarded ref
  useImperativeHandle(ref, () => ({
    callToggle: (e) => {
      const {
        setSelectedMenu,
        setSelectedSubMenu,
        selectedMenu,
        index,
        route,
        external,
        children,
      } = props;

      setTarget(e.currentTarget);
      setOpen((prevState) => !prevState);

      // No sub-menus
      if (children.length < 1) {
        setSelectedMenu(index);
        setSelectedSubMenu(null);

        if (route) {
          if (external) {
            window.location.assign(route);
          } else if (index === selectedMenu) {
            navigate(route, { replace: true });
          } else {
            navigate(route);
          }
        }
      }
    },
  }));

  /**
   * Click handler for any of our sub-menu MenuItem's
   *
   * @param event The click event
   * @param item The specific sub-menu item clicked?
   */
  const handleSubMenuClick = (event, item) => {
    props.setSelectedMenu(props.index);
    props.setSelectedSubMenu(props.index.toString() + event.target.innerText);

    if (item && item.route) {
      if (item.external) {
        window.location.assign(item.route);
      } else if (
        props.selectedSubMenu ===
        props.index.toString() + event.target.innerText
      ) {
        navigate(item.route, { replace: true });
      } else {
        navigate(item.route);
      }
    }
  };

  // Event handler for clicking on anything but our MenuList or its children.
  //   (Could just place this in-line inside the clickAwayListener's onClickAway prop?)
  function handleClose(event) {
    setOpen(false);
  }

  function handleListKeyDown(event) {
    if (event.key === "Escape") {
      setOpen(false);

      if (target) {
        target.focus();
      }
    }

    if (event.key === "Tab" || event.key === "Enter") {
      event.preventDefault();
      setOpen(false);

      if (target) {
        target.focus();
      }
    }
  }

  // Keep our "instance variable" updated
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const containerId = `${props.name.toLowerCase()}-menu-container`;
  const labelId = `${props.name.toLowerCase()}-menu-label`;

  return (
    <div id={containerId}>
      <div
        id={labelId}
        ref={anchorRef}
        aria-haspopup="true"
        className="arrowIcon"
      >
        {props.name}
        {props.children.length > 0 && <MuiArrowDropDownOutlinedIcon />}
      </div>
      {props.children.length > 0 && (
        <NavbarPopper
          open={open}
          anchorEl={anchorRef.current}
          transition
          role={undefined}
          disablePortal={false}
          placement="bottom-start"
        >
          {({ TransitionProps, placement }) => (
            <MuiGrow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <NavbarPaper>
                <MuiClickAwayListener onClickAway={handleClose}>
                  <MuiMenuList
                    autoFocus={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {props.children.length > 0 &&
                      props.children.map((item, index) => (
                        <MuiMenuItem
                          key={"menuItem" + index}
                          selected={
                            props.index.toString() + item.name ===
                            props.selectedSubMenu
                          }
                          onClick={(e) => handleSubMenuClick(e, item)}
                          className="submenu-item"
                        >
                          {item.name}
                        </MuiMenuItem>
                      ))}
                  </MuiMenuList>
                </MuiClickAwayListener>
              </NavbarPaper>
            </MuiGrow>
          )}
        </NavbarPopper>
      )}
    </div>
  );
});

MenuListComposition.displayName = "MenuListComposition";
