import { useState } from "react";
import {
  Box as MuiBox,
  Button as MuiButton,
  Tooltip as MuiTooltip,
  Typography as MuiTypography,
  Link as MuiLink,
  Breadcrumbs as MuiBreadcrumbs,
  Grid as MuiGrid,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import {
  AddCircle as AddCircleIcon,
  ArrowBack as MuiArrowBackIcon,
  CloudDownloadOutlined as CloudDownloadOutlinedIcon,
  EditOutlined as EditOutlinedIcon,
  DeleteOutlineOutlined as DeleteOutlineOutlinedIcon,
} from "@mui/icons-material";
import {
  fileAttachmentContainer,
  fileAttachmentWrapper,
  listContainer,
  MuiDataGrid,
  tooltip,
  wrapIcon,
} from "app/file-attachments/FileAttachments.styles";
import { useFileAttachments } from "app/file-attachments/fileAttachmentsService";
import { useApp } from "app/appService";
import { useTranslation } from "react-i18next";
// import { setStoreValue } from "app/shared/utils";
import { useParams, useNavigate } from "react-router-dom";
import { UploadAttachments } from "app/file-attachments/UploadAttachments";
import { RenameFileAttachment } from "app/file-attachments/RenameFileAttachment";
import { DeleteConfirmation } from "app/file-attachments/DeleteConfirmation";
import { useDownload } from "app/shared/Download";
import { useAlerts, LoadingGridOverlay, NoRowsGridOverlay } from "common";

export const FileTypeOptions = {
  medonboard: [
    { title: "CV", type: "CV" },
    { title: "Exception Approval", type: "EXCEPTION_APPROVAL" },
    { title: "Letter of Interest", type: "LETTER_OF_INTEREST" },
    { title: "Chair Memo", type: "CHAIR_MEMO" },
    { title: "Other", type: "OTHER" },
  ],
  medact: [
    { title: "CV", type: "CV" },
    { title: "Exception Approval", type: "EXCEPTION_APPROVAL" },
    { title: "Letter of Interest", type: "LETTER_OF_INTEREST" },
    { title: "Chair Memo", type: "CHAIR_MEMO" },
    { title: "Other", type: "OTHER" },
  ],
  medleave: [
    { title: "CV", type: "CV" },
    { title: "Exception Approval", type: "EXCEPTION_APPROVAL" },
    { title: "Letter of Interest", type: "LETTER_OF_INTEREST" },
    { title: "Chair Memo", type: "CHAIR_MEMO" },
    { title: "Other", type: "OTHER" },
  ],
};

export const FileAttachments = () => {
  const { tenantId } = useApp();
  const navigate = useNavigate();
  const { actionId } = useParams();
  const [showUploader, setShowUploader] = useState(false);
  const [renameDialog, setRenameDialog] = useState({
    open: false,
    fileType: "",
    fileName: "",
    documentId: null,
  });
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    fileName: "",
  });

  const { getDocument } = useDownload();

  const {
    fileAttachments,
    isLoading,
    fileAttachmentState,
    setFileAttachmentState,
    dispatch,
    setRefresh,
    actionName,
  } = useFileAttachments();

  const { clearAlert, setAlert } = useAlerts();

  const { t } = useTranslation();

  const NoFileAttachmentOverlay = () => (
    <NoRowsGridOverlay
      message={`${t("fileAttachments.list.noRecordsFound")}`}
    />
  );

  const columns = {
    medonboard: [
      {
        field: "fileName",
        headerClassName: "gridHeader",
        width: 275,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.fileName`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value || ""} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "fileType",
        headerClassName: "gridHeader",
        width: 160,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.fileType`)}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTypography noWrap={true} variant="body2">
            {params.value}
          </MuiTypography>
        ),
      },
      {
        field: "updatedAt",
        headerClassName: "gridHeader",
        width: 220,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.updatedAt`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "updatedBy",
        headerClassName: "gridHeader",
        width: 220,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.updatedBy`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "operations",
        headerClassName: "gridHeader",
        width: 195,
        sortable: false,
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.operations`)}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <>
            {/* Download */}
            {params.row.links?.self && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.download`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    getDocument(
                      `/actions/${actionId}/documents/${params.row.documentId}`
                    )
                  }
                >
                  <CloudDownloadOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}

            {/* Edit */}
            {params.row.links?.edit && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.edit`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    setRenameDialog({
                      open: true,
                      fileName: params.row.fileName,
                      fileType: params.row.fileType,
                      documentId: params.row.documentId,
                    })
                  }
                >
                  <EditOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}

            {/* Delete */}
            {params.row.links?.delete && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.delete`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    setDeleteConfirmation({
                      open: true,
                      fileName: params.row.fileName,
                      documentId: params.row.documentId,
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}
          </>
        ),
      },
    ],
    medact: [
      {
        field: "fileName",
        headerClassName: "gridHeader",
        width: 275,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.fileName`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value || ""} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "fileType",
        headerClassName: "gridHeader",
        width: 160,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.fileType`)}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTypography noWrap={true} variant="body2">
            {params.value}
          </MuiTypography>
        ),
      },
      {
        field: "updatedAt",
        headerClassName: "gridHeader",
        width: 220,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.updatedAt`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "updatedBy",
        headerClassName: "gridHeader",
        width: 220,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.updatedBy`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "operations",
        headerClassName: "gridHeader",
        width: 195,
        sortable: false,
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.operations`)}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <>
            {/* Download */}
            {params.row.links?.self && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.download`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    getDocument(
                      `/actions/${actionId}/documents/${params.row.documentId}`
                    )
                  }
                >
                  <CloudDownloadOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}

            {/* Edit */}
            {params.row.links?.edit && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.edit`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    setRenameDialog({
                      open: true,
                      fileName: params.row.fileName,
                      fileType: params.row.fileType,
                      documentId: params.row.documentId,
                    })
                  }
                >
                  <EditOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}

            {/* Delete */}
            {params.row.links?.delete && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.delete`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    setDeleteConfirmation({
                      open: true,
                      fileName: params.row.fileName,
                      documentId: params.row.documentId,
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}
          </>
        ),
      },
    ],
    medleave: [
      {
        field: "fileName",
        headerClassName: "gridHeader",
        width: 275,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.fileName`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value || ""} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "fileType",
        headerClassName: "gridHeader",
        width: 160,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.fileType`)}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <MuiTypography noWrap={true} variant="body2">
            {params.value}
          </MuiTypography>
        ),
      },
      {
        field: "updatedAt",
        headerClassName: "gridHeader",
        width: 220,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.updatedAt`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "updatedBy",
        headerClassName: "gridHeader",
        width: 220,
        sortable: Boolean(fileAttachments.fileAttachmentItems.length),
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.updatedBy`)}
          </MuiTypography>
        ),
        renderCell: (params) => {
          return (
            <MuiTooltip title={params.value} placement={"top"}>
              <MuiTypography noWrap={true} variant="body2">
                {params.value}
              </MuiTypography>
            </MuiTooltip>
          );
        },
      },
      {
        field: "operations",
        headerClassName: "gridHeader",
        width: 195,
        sortable: false,
        renderHeader: () => (
          <MuiTypography
            variant="body1"
            sx={{ lineHeight: "inherit", fontWeight: 600 }}
          >
            {t(`fileAttachments.list.tableColumns.${tenantId}.operations`)}
          </MuiTypography>
        ),
        renderCell: (params) => (
          <>
            {/* Download */}
            {params.row.links?.self && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.download`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    getDocument(
                      `/actions/${actionId}/documents/${params.row.documentId}`
                    )
                  }
                >
                  <CloudDownloadOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}

            {/* Edit */}
            {params.row.links?.edit && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.edit`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    setRenameDialog({
                      open: true,
                      fileName: params.row.fileName,
                      fileType: params.row.fileType,
                      documentId: params.row.documentId,
                    })
                  }
                >
                  <EditOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}

            {/* Delete */}
            {params.row.links?.delete && (
              <MuiTooltip
                title={t(`fileAttachments.list.tooltip.${tenantId}.delete`)}
                placement={"bottom"}
              >
                <MuiLink
                  sx={tooltip}
                  component="button"
                  onClick={() =>
                    setDeleteConfirmation({
                      open: true,
                      fileName: params.row.fileName,
                      documentId: params.row.documentId,
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon fontSize="medium" />
                </MuiLink>
              </MuiTooltip>
            )}
          </>
        ),
      },
    ],
  };

  const handleSortModelChange = (params) => {
    if (isSortChanged(params, fileAttachments.sortModel)) {
      dispatch({
        action: "SET_SORT_MODEL",
        value: params,
      });
      const newState = { ...fileAttachmentState, sortModel: params };
      // setStoreValue("sort", newState);
      setFileAttachmentState(newState);
    }
  };

  return !fileAttachments.error ? (
    <div style={fileAttachmentWrapper}>
      <div style={fileAttachmentContainer}>
        <MuiBox mb={2}>
          <MuiTypography variant="h1">
            {t(`fileAttachments.title.${tenantId}`, {
              name: actionName,
              actionId,
            })}
          </MuiTypography>
        </MuiBox>
        <MuiGrid container>
          <MuiBreadcrumbs
            sx={{
              marginTop: "auto",
              marginBottom: "16px",
            }}
          >
            <MuiLink onClick={() => navigate("/")} component="button">
              <MuiTypography variant="subtitle2" sx={wrapIcon}>
                <MuiArrowBackIcon
                  color="primary"
                  sx={{ fontSize: "18.75px" }}
                />
                <MuiTypography
                  component="span"
                  variant="subtitle2"
                  color="primary"
                  sx={{ fontSize: "13.125px" }}
                >
                  {t("fileAttachments.list.actionButtons.back")}
                </MuiTypography>
              </MuiTypography>
            </MuiLink>
          </MuiBreadcrumbs>
          <div style={listContainer}>
            {!!fileAttachments.showUploadButton && (
              <MuiButton
                variant="contained"
                color="primary"
                startIcon={<AddCircleIcon />}
                sx={{
                  float: "right",
                  marginBottom: "16px",
                  fontSize: "17px",
                }}
                onClick={() => setShowUploader(true)}
              >
                {t(
                  `fileAttachments.button.uploadAttachments.label.${tenantId}`
                )}
              </MuiButton>
            )}
          </div>
        </MuiGrid>
      </div>
      <div style={fileAttachmentContainer}>
        <MuiBox sx={{ height: fileAttachments.gridHeight, listContainer }}>
          <DataGridPro
            slots={{
              loadingOverlay: LoadingGridOverlay,
              noRowsOverlay: NoFileAttachmentOverlay,
            }}
            loading={isLoading}
            rows={fileAttachments.fileAttachmentItems}
            columns={columns[tenantId]}
            autoHeight
            hideFooter
            disableColumnResize
            disableColumnReorder
            disableColumnMenu
            disableRowSelectionOnClick
            sx={MuiDataGrid}
            sortingMode="server"
            sortModel={fileAttachments.sortModel}
            onSortModelChange={handleSortModelChange}
            sortingOrder={["asc", "desc"]}
          />
          <UploadAttachments
            openDialog={showUploader}
            setOpenDialog={setShowUploader}
            history={navigate}
            actionId={actionId}
            setRefresh={setRefresh}
            fileAttachments={fileAttachments.fileAttachmentItems}
          />
          <RenameFileAttachment
            setShowDialog={setRenameDialog}
            clearAlert={clearAlert}
            setAlert={setAlert}
            actionId={actionId}
            setRefresh={setRefresh}
            {...renameDialog}
          />
          <DeleteConfirmation
            setShowDialog={setDeleteConfirmation}
            actionId={actionId}
            {...deleteConfirmation}
            clearAlert={clearAlert}
            setAlert={setAlert}
            setRefresh={setRefresh}
          />
        </MuiBox>
      </div>
    </div>
  ) : null;
};

const isSortChanged = (params, sortModel) =>
  params.length &&
  (params[0].field !== sortModel[0].field ||
    params[0].sort !== sortModel[0].sort);
