import { useState, useEffect, useRef } from "react";
import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  CircularProgress as MuiCircularProgress,
} from "@mui/material/";
import { useActions } from "app/actions/actionsService";
import { useTranslation } from "react-i18next";
import { getFormattedDate } from "app/shared/utils";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { StyleApp } from "app/shared/ui/ui.styles";

export const ScrollDialog = ({ params, setParams }) => {
  const [show, setShow] = useState(false);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [historyItems, setHistoryItems] = useState([]);

  const { loadFieldHistory } = useActions();

  const { t } = useTranslation();

  const columns = [
    {
      id: "values",
      label: t("forms.historyDialog.columns.values"),
      minWidth: 170,
      align: "left",
    },
    {
      id: "updatedAt",
      label: t("forms.historyDialog.columns.updatedAt"),
      minWidth: 150,
      align: "left",
    },
    {
      id: "updatedBy",
      label: t("forms.historyDialog.columns.updatedBy"),
      minWidth: 170,
      align: "left",
    },
  ];

  const handleClose = () => {
    setShow(false);
    setParams({ ...params, show: false });
  };

  useEffect(() => {
    setShow(params.show);
  }, [params.show]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (show) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [show]);

  useEffect(() => {
    loadFieldHistory({
      links: params.links,
      setIsHistoryLoading: setIsHistoryLoading,
      setShow,
      setHistoryItems,
    });
  }, [params.links, loadFieldHistory]);

  return (
    <MuiDialog
      open={show}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="history-dialog-title"
      aria-describedby="history-dialog-description"
    >
      <MuiDialogTitle
        id="history-dialog-title"
        style={StyleApp.links}
        sx={{ fontSize: "18.75px", fontWeight: 500 }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: sanitize(
              t("forms.historyDialog.title", { title: params.fieldLabel }),
              {
                ALLOWED_TAGS: AllowedTags,
              }
            ),
          }}
        />
      </MuiDialogTitle>
      <MuiDialogContent dividers={true}>
        {!isHistoryLoading ? (
          <MuiTableContainer sx={{ maxHeight: 740 }}>
            <ContentTable
              columns={columns}
              rows={historyItems}
              params={params}
            />
          </MuiTableContainer>
        ) : (
          <div style={{ textAlign: "center" }}>
            <MuiCircularProgress />
          </div>
        )}
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton
          onClick={handleClose}
          color="primary"
          sx={{ fontSize: "17px" }}
        >
          {t("forms.historyDialog.button.close")}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};

const ContentTable = ({ columns, rows, params }) => {
  const { t } = useTranslation();

  return rows.length ? (
    <MuiTable stickyHeader aria-label="sticky table">
      <MuiTableHead>
        <MuiTableRow>
          {columns.map((column) => (
            <MuiTableCell
              key={column.id}
              align={column.align}
              style={{ minWidth: column.minWidth }}
            >
              {column.label}
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
      <MuiTableBody>
        {rows.map((row, index) => {
          return (
            <MuiTableRow hover role="checkbox" tabIndex={-1} key={row.code}>
              <MuiTableCell key={index} align={"left"}>
                {getFormattedValue(row.changeValue, params?.fieldType)}
              </MuiTableCell>
              <MuiTableCell key={index} align={"left"}>
                {getFormattedDate(row.modified)}
              </MuiTableCell>
              <MuiTableCell key={index} align={"left"}>
                {`${row.modifiedBy.text}`}
              </MuiTableCell>
            </MuiTableRow>
          );
        })}
      </MuiTableBody>
    </MuiTable>
  ) : (
    t("forms.historyDialog.noHistoryText")
  );
};

const getFormattedValue = (valueString, valueType) => {
  if (valueType === "percent") return `${parseFloat(valueString || 0)}%`;
  if (valueType === "money")
    return `$${Intl.NumberFormat("en-US").format(String(valueString))}`;
  return valueString;
};
