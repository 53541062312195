// Setup defaults and the response interceptor for the axios HTTP library.

import axios from "axios";
import i18n from "common/i18n";
import tenantSpecific from "app/shared/tenantErrorMessages.json";

export const setupAxios = () => {
  // If the base URL contains DEPLOY_ENV, update with the deployment environment from public/env.js
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL.replace(
    "DEPLOY_ENV",
    window.DEPLOY_ENV
  );
  axios.defaults.timeout = parseInt(process.env.REACT_APP_API_TIMEOUT);

  // For any API error, set a default error message which the services in the application should either use or override
  axios.interceptors.response.use(
    (response) => {
      // Any status code of 2xx causes this function to trigger
      // Return the unmodified response back to the service for handling
      return response;
    },
    (error) => {
      // Any results other than status code 2xx causes this function to trigger
      if (error.response) {
        // The request was made and the server responded with a status code other than 2xx
        if (error.response.data.status && error.response.data.code) {
          // Function to get Error Message String
          error.message = errorMessage(
            error.response.config.headers["x-tenant-id"],
            error.response.data
          );
        } else {
          if (error.response.config.responseType === "blob") {
            if (error.response.status === 423) {
              error.message = i18n.t(
                `common:apiDefaultErrorMessages.${error.response.status}`
              );
            } else {
              error.message = i18n.t(
                `common:apiDefaultErrorMessages.500.SERVER_ERROR`,
                {
                  message: error.response.data.message,
                }
              );
            }
          }
        }
        error.status = error.response.status;
        error.code = error.response.data.code;
      } else if (error.request) {
        // The request was made but no response was received
        error.message = i18n.t(
          "common:apiDefaultErrorMessages.REQUEST_TIMEOUT"
        );
      } else {
        // Something happened in setting up the request that triggered an error
        error.message = i18n.t("common:apiDefaultErrorMessages.REQUEST_ERROR");
      }

      // Return the default error message to the service in error.message
      // Also return error.status and error.code if the error came from the backend
      return Promise.reject(error);
    }
  );
};

// To prepare error message. Either the message would be specific to tenant or a default error message.
// If there's a field_id present then, we create tenant specific error message using file `tenantErrorMessages.json`
// else we return a default mesasge using file `common.json`

const errorMessage = (tenantId, data) => {
  // Get field_id from error response `data`
  const tenantObjIndex = tenantMessageObj(tenantId, data);

  // If status and code exists, then return the tenant specific message, according to status and code
  if (tenantObjIndex > -1) {
    return tenantSpecificMessage(tenantId, tenantObjIndex, data);
  }

  // Return the default translated message according to status and code
  return i18n.t(`common:apiDefaultErrorMessages.${data.status}.${data.code}`, {
    name: data.detail?.properties?.[0]?.name,
    value: data.detail?.properties?.[0]?.value,
    resource: data.detail?.properties?.[0]?.resource,
    message: data.detail?.properties?.[0]?.message,
  });
};

// To prepare tenant specific messages based on `tenantId`, `field_id` and data (containing status and code)

const tenantSpecificMessage = (tenantId, tenantObjIndex, data) => {
  return i18n.t(
    // To create `tenantSpecific` key we inserted and created a json file named tenantErrorMessages.json and included in
    // the file named `i18n.js`
    `tenantSpecific:${tenantId}.${tenantObjIndex}.errorMessage`,
    {
      name: data.detail?.properties?.[0]?.name,
      value: data.detail?.properties?.[0]?.value,
      resource: data.detail?.properties?.[0]?.resource,
      message: data.detail?.properties?.[0]?.message,
    }
  );
};

const tenantMessageObj = (tenantId, data) => {
  // If the tenants entry doesn't exists, return -1
  if (!tenantSpecific?.[tenantId]) {
    return -1;
  }

  // field_id from API response
  const field_id = data.detail.properties?.[0]?.field_id;

  return tenantSpecific?.[tenantId].findIndex(
    (obj) =>
      data.status === obj.statusCode &&
      data.code === obj.errorCode &&
      (obj.fieldId === field_id || !obj.fieldId)
  );
};
