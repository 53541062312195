export const wrapper = {
  paddingTop: "16px",
  paddingBottom: "16px",
  backgroundColor: "#FFFFFF",
  height: "100%",
};
export const MuiDataGridStyle = {
  "& .MuiDataGrid-cell": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiDataGrid-window": {
    overflowY: "hidden !important",
    overflowX: "hidden",
  },
  "& .MuiDataGrid-virtualScroller": {
    overflow: "hidden",
  },
  "& .MuiDataGrid-scrollbar--horizontal ": {
    display: "none",
  },
  "& .MuiDataGrid-filler": {
    display: "none",
  },
  "& .MuiDataGrid-main": {
    overflowX: "hidden",
    "&:focus": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-columnHeader--last .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiTablePagination-input": {
    fontSize: "15px",
  },
  backgroundColor: "#FFFFFF",
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
    {
      outline: "none",
    },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within":
    {
      outline: "none",
    },
  "&& .MuiIconButton-root": {
    width: "22.875px",
    height: "22.875px",
    margin: 0,
    padding: "3px",
  },
  "& .MuiDataGrid-iconButtonContainer": { paddingTop: "2px" },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "visible",
  },
};
export const tooltip = { lineHeight: 0, marginRight: 5 };
export const input = {
  "& .MuiOutlinedInput-input": {
    padding: "12.5px 14px",
  },
};
export const datePicker = {
  fontSize: "15px",
  paddingRight: "3px",
  "& .MuiButtonBase-root": {
    margin: 0,
    padding: 0,
    height: "auto",
    width: "auto",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "22.5px",
  },
};
export const filter = {
  backgroundColor: "#FFFFFF",
  border: `1px solid #DDDDDD`,
  margin: 0,
  borderRadius: 3,
  padding: 10,
};
export const actionWrapper = {
  width: "100%",
  overflowX: "auto",
  paddingBottom: "16px",
  paddingTop: "16px",
  backgroundColor: "#FFFFFF",
};
export const actionContainer = {
  width: "1780px",
  margin: "0 auto",
};
export const listContainer = {
  width: "80%",
  float: "left",
  margin: "0 16px",
};
export const filterContainer = {
  width: "17%",
  float: "left",
  paddingRight: "16px",
};
export const dataGrid = {
  backgroundColor: "#FFFFFF",
};
export const datePickerContainer = { width: "100%" };
export const releaseRequestDate = { width: "45%", float: "left" };
export const startDate = { width: "45%", float: "left" };
export const endDate = { width: "45%", float: "right" };
export const dateSeparator = { width: "10%", float: "left", lineHeight: "2.5" };
export const startActionContainer = { float: "right", marginBottom: "16px" };
export const IconResize = { fontSize: "22.5px" };
export const autocompleteStyles = {
  "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
    height: "auto",
    width: "auto",
  },
  "& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root": {
    fontSize: "18.75px",
  },
  "& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root": {
    fontSize: "22.5px",
  },
  "& .MuiInputLabel-root, .MuiInputBase-root": {
    fontSize: "15px",
  },
  "& .MuiInputBase-root": {
    paddingTop: "6px",
    paddingLeft: "6px",
    paddingBottom: "6px",
    "& .MuiAutocomplete-input": {
      paddingTop: "4.5px",
      paddingLeft: "6px",
      paddingBottom: "4.5px",
      height: "1.1876em",
    },
  },
  "& .MuiInputLabel-root": {
    inset: "-7px 0px",
  },
  "& .MuiInputLabel-shrink": {
    inset: 0,
  },
};

export const multiSelectAutocompleteStyles = {
  "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
    height: "auto",
    width: "auto",
  },
  "& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root": {
    fontSize: "18.75px",
  },
  "& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root": {
    fontSize: "22.5px",
  },
  "& .MuiInputLabel-root, .MuiInputBase-root": {
    fontSize: "15px",
  },
};
