import { useState } from "react";
import { Box as MuiBox } from "@mui/material";
import { FormContainer } from "app/forms/FormContainer";
import { FormBody } from "app/forms/FormBody";
import { FormsProvider } from "app/forms/formsService";
import { useParams } from "react-router-dom";

export const Form = ({ apiUrl, formName }) => {
  const { actionId } = useParams();
  const [formUrl, setFormUrl] = useState(() =>
    getServiceURL({ apiUrl, actionId, formName })
  );

  return (
    <MuiBox sx={{ backgroundColor: "#fafafa", height: "100%" }}>
      <FormsProvider apiUrl={formUrl} formName={formName}>
        <FormContainer>
          <FormBody
            actionid={actionId}
            formname={formName}
            setFormUrl={setFormUrl}
          />
        </FormContainer>
      </FormsProvider>
    </MuiBox>
  );
};

const getServiceURL = ({ apiUrl, actionId, formName }) => {
  if (apiUrl) return apiUrl;
  return actionId
    ? `actions/${actionId}/forms/${formName}`
    : `actions/forms/${formName}`;
};
