import styled from "@emotion/styled";
import { Theme } from "common/components/Theme/Theme";

export const FooterContainer = styled.div`
  color: ${Theme.palette.text.primary};
  display: block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: ${Theme.typography.fontFamily};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  border-color: ${Theme.palette.grey[400]};
  border-top: 1px solid ${Theme.palette.grey[400]};
  padding: 12px 24px;

  .footer-local {
    font-size: 12px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    width: 100%;

    .content {
      margin-left: 0px;
      margin-right: 0px;
      width: 100%;

      &::before {
        content: " ";
        display: table;
      }

      &::after {
        content: " ";
        display: table;
        clear: both;
      }

      .simple-footer {
        &::before {
          content: " ";
          display: table;
        }

        &::after {
          content: " ";
          display: table;
          clear: both;
        }

        .legal-text {
          color: ${Theme.palette.text.secondary};
          font-size: 14px;
          min-height: 1px;
          padding: 0px;
          position: relative;

          @media (min-width: 479px) {
            text-align: left;
          }

          @media (min-width: 768px) {
            float: left;
            width: 50%;
          }
        }
      }

      .footer-links {
        min-height: 1px;
        padding-right: 15px;
        position: relative;

        @media (min-width: 768px) {
          float: left;
          width: 50%;
        }
      }
    }
  }
`;
