import { Fragment, memo, useEffect, useState } from "react";
import {
  Backdrop as MuiBackdrop,
  Box as MuiBox,
  CircularProgress as MuiCircularProgress,
  Grid as MuiGrid,
} from "@mui/material";
import { saveAction } from "app/actions/actionsService";
import { useApp } from "app/appService";
import { useForms } from "app/forms/formsService";
import { useRoutes } from "app/routes/routesService";
import { ConfirmationDialog } from "app/shared/ui/ConfirmationDialog";
import { useAlerts, useUsers } from "common";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormElement } from "app/forms/FormElement";
import { ScrollDialog } from "app/shared/ui/ScrollDialog";
import { InfoDialog } from "app/shared/ui/InfoDialog";
import {
  replaceFieldCalculationValues,
  setStoreValue,
  removeStoreValue,
} from "app/shared/utils";

let formikValues = {};
let globalValueObj = {};
let timeoutForValidate, timeoutForSchema;

export const FormBody = ({
  actionid: actionId,
  setFieldHandler,
  formname,
  setFormUrl,
}) => {
  const {
    formFields,
    formAccess,
    formFlatMap,
    formValues,
    isLoading,
    allFields,
    summaryFields,
    duplicateIds,
    formLabelMap,
  } = useForms();

  const { currentUser: user } = useUsers();

  useEffect(() => {
    removeStoreValue("clearField");
    setStoreValue("clearField", { initialCall: true });
  }, []);

  sessionStorage.setItem("formEditable", formAccess);

  if (Object.keys(formFlatMap).length === 0) return "";

  return (
    <FormContent
      actionId={actionId}
      setFieldHandler={setFieldHandler}
      formname={formname}
      formFields={formFields}
      formAccess={formAccess}
      formFlatMap={formFlatMap}
      formValues={formValues}
      isLoading={isLoading}
      saveRecord={saveAction}
      user={user}
      allFields={allFields}
      summaryFields={summaryFields}
      setFormUrl={setFormUrl}
      duplicateIds={duplicateIds}
      labelMap={formLabelMap}
    />
  );
};

export const FormContent = memo(
  ({
    actionId,
    setFieldHandler,
    formname,
    formFields,
    formAccess,
    formFlatMap,
    formValues,
    isLoading,
    saveRecord,
    user,
    allFields,
    summaryFields,
    setFormUrl,
    duplicateIds,
    labelMap,
  }) => {
    const { tenantId } = useApp();
    const [orgCode, setOrgCode] = useState("");
    const navigate = useNavigate();
    const { setServiceUrl } = useRoutes();
    const [confirmationDialog, setConfirmationDialog] = useState({
      show: false,
      content: "",
      secondaryLabel: "No",
      primaryLabel: "Yes",
      primaryAction: () => navigate("/"),
    });
    const [scrollDialog, setScrollDialog] = useState({
      show: false,
      fieldLabel: "",
      links: "",
      fieldType: "",
    });
    const [infoDialog, setInfoDialog] = useState({
      show: false,
      fieldLabel: "",
      fieldContent: "",
    });

    const [validationSchema, setValidationSchema] = useState(
      Yup.object().shape({})
    );

    const [summaryList, setSummaryList] = useState([]);
    const [summaryState, setSummaryState] = useState(false);

    const pageType = formAccess === "VIEW" ? "view" : "";

    const { clearAlert, setAlert } = useAlerts();

    const [render, setRender] = useState(0);

    const { t } = useTranslation();

    const formik = useFormik({
      initialValues: formValues,
      enableReinitialize: true,
      validationSchema,
      onSubmit: (values, { setSubmitting, resetForm }) => {
        const formTitle = formFields[0]?.field_label || "";
        const formValues = formik.values;
        saveRecord({
          values: formValues,
          id: actionId,
          setSubmitting,
          formTitle,
          resetForm,
          setServiceUrl,
          clearAlert,
          setAlert,
          t,
          navigate,
          formFields,
          tenantId,
          setConfirmationDialog,
          setFormUrl,
        });
      },
    });

    useEffect(() => {
      const getYupSchemaObj = getYupSchema({
        formFields,
        formikValues: { ...formFlatMap, ...formik.values },
        formname,
      });
      clearTimeout(timeoutForSchema);
      timeoutForSchema = setTimeout(() => {
        setValidationSchema(Yup.object().shape(getYupSchemaObj));
      }, 500);
      // eslint-disable-next-line
    }, [formFields, formikValues]);

    useEffect(() => {
      clearTimeout(timeoutForValidate);
      timeoutForValidate = setTimeout(() => {
        formik.validateForm();
      }, 500);
      // eslint-disable-next-line
    }, [validationSchema]);

    useEffect(() => {
      // Cleanup on un-mount
      return () => {
        Object.keys(formik.errors).forEach((key) => delete formik.errors[key]);
        Object.keys(formFlatMap).forEach((key) => delete formFlatMap[key]);
        Object.keys(formik.values).forEach((key) => delete formik.values[key]);
        formikValues = {};
        globalValueObj = {};
      };
      // eslint-disable-next-line
    }, []);

    // TODO: This is always false, formik's dirty flag does not work for our needs
    // if (setIsFormDirty) setIsFormDirty(formik.dirty);

    formikValues = formik.values;

    return (
      <form onSubmit={formik.handleSubmit} noValidate autoComplete="off">
        <MuiBox mt={3} mb={2} pb={3}>
          <MuiBackdrop
            open={isLoading || formik.isSubmitting}
            invisible={true}
            sx={{ zIndex: 1201 }}
            children={<MuiCircularProgress />}
          />
          <FormWrapper
            formik={formik}
            actionId={actionId}
            formFields={formFields}
            formFlatMap={formFlatMap}
            user={user}
            orgCode={orgCode}
            setOrgCode={setOrgCode}
            navigate={navigate}
            setFieldHandler={setFieldHandler}
            pageType={pageType}
            setConfirmationDialog={setConfirmationDialog}
            allFields={allFields}
            summaryList={summaryList}
            setSummaryList={setSummaryList}
            summaryState={summaryState}
            setSummaryState={setSummaryState}
            summaryFields={summaryFields}
            setServiceUrl={setServiceUrl}
            clearAlert={clearAlert}
            setAlert={setAlert}
            tenantId={tenantId}
            setScrollDialog={setScrollDialog}
            render={render}
            setRender={setRender}
            setInfoDialog={setInfoDialog}
            yup={Yup}
            setFormUrl={setFormUrl}
            duplicateIds={duplicateIds}
            labelMap={labelMap}
          />
          <ConfirmationDialog
            show={confirmationDialog.show}
            setShow={setConfirmationDialog}
            content={confirmationDialog.content}
            secondaryLabel={confirmationDialog.secondaryLabel}
            primaryLabel={confirmationDialog.primaryLabel}
            primaryAction={confirmationDialog.primaryAction}
            formik={formik}
            request={confirmationDialog.request}
            redirectUrl={confirmationDialog.redirectUrl}
            serviceUrl={confirmationDialog.serviceUrl}
          />
          <ScrollDialog params={scrollDialog} setParams={setScrollDialog} />
          <InfoDialog params={infoDialog} setParams={setInfoDialog} />
        </MuiBox>
      </form>
    );
  }
);

const FormWrapper = memo(
  ({
    formik,
    actionId,
    formFields,
    pageType,
    formFlatMap,
    user,
    orgCode,
    setOrgCode,
    navigate,
    setFieldHandler,
    setConfirmationDialog,
    allFields,
    summaryList,
    setSummaryList,
    summaryState,
    setSummaryState,
    summaryFields,
    setServiceUrl,
    clearAlert,
    setAlert,
    tenantId,
    setScrollDialog,
    render,
    setRender,
    setInfoDialog,
    yup,
    setFormUrl,
    duplicateIds,
    labelMap,
  }) => {
    const { t } = useTranslation();
    const FIELDS = formFields;

    return (
      <MuiGrid container justifyContent="flex-end" spacing={2}>
        {FIELDS.map((field, index) => (
          <Fragment key={index}>
            <FormElement
              element={field}
              formik={formik}
              actionId={actionId}
              formFields={FIELDS}
              pageType={pageType}
              formFlatMap={formFlatMap}
              user={user}
              orgCode={orgCode}
              setOrgCode={setOrgCode}
              navigate={navigate}
              setFieldHandler={setFieldHandler}
              setConfirmationDialog={setConfirmationDialog}
              allFields={allFields}
              summaryList={summaryList}
              setSummaryList={setSummaryList}
              summaryState={summaryState}
              setSummaryState={setSummaryState}
              summaryFields={summaryFields}
              renderedExp={field.field_rendered}
              t={t}
              globalValueObj={globalValueObj}
              setServiceUrl={setServiceUrl}
              clearAlert={clearAlert}
              setAlert={setAlert}
              tenantId={tenantId}
              setScrollDialog={setScrollDialog}
              render={render}
              setRender={setRender}
              setInfoDialog={setInfoDialog}
              yup={yup}
              setFormUrl={setFormUrl}
              duplicateIds={duplicateIds}
              labelMap={labelMap}
            />
          </Fragment>
        ))}
      </MuiGrid>
    );
  }
);

const getYupSchema = ({ formFields, formikValues: values, formname }) => {
  let validationSchema = {};
  formFields.forEach((field) => {
    if (formname === field["form_id"]) {
      const obj = field["field_id"];
      const type = field["field_type"];
      const required = replaceFieldCalculationValues(
        field["field_required"],
        values
      );
      const validation = field["field_validation"];
      const fieldRendered = field["field_rendered"];

      if (
        // eslint-disable-next-line no-eval
        eval(required) &&
        [
          "text",
          "number",
          "money",
          "percent",
          "radio",
          "dropdown",
          "checkbox",
        ].includes(type)
      ) {
        if (fieldRendered) {
          const fieldRenderedSchema = getFieldRenderedSchema(
            fieldRendered,
            values
          );
          if (fieldRenderedSchema) validationSchema[obj] = fieldRenderedSchema;
        } else {
          validationSchema[obj] = getNonFieldRenderedSchema({
            obj,
            type,
            validation,
            validationSchema,
          });
        }
      }
    }
  });
  return validationSchema;
};

const getFieldRenderedSchema = (branchingLogic, values) => {
  const fieldRenderedValue = replaceFieldCalculationValues(
    branchingLogic,
    values
  );

  // eslint-disable-next-line no-eval
  if (eval(fieldRenderedValue)) return Yup.mixed().required();

  return false;
};

const getNonFieldRenderedSchema = ({
  obj,
  type,
  validation,
  validationSchema,
}) => {
  if (validation === "email") {
    validationSchema[obj] = Yup.string().email().trim();
  } else {
    validationSchema[obj] = Yup.mixed();
  }
  return validationSchema[obj].required();
};
