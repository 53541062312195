import { AuthProvider } from "common";
import { PortalAuthProvider } from "app/portal/portalAuthService";
import { useApp } from "app/appService";
import { BundleIds } from "app/shared/constants";

export const AuthProviderSelector = ({ children }) => {
  const { bundleId } = useApp();

  if (bundleId === BundleIds.MEDONBOARD_PORTAL) {
    return <PortalAuthProvider>{children}</PortalAuthProvider>;
  } else {
    return <AuthProvider>{children}</AuthProvider>;
  }
};
