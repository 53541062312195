import React from "react";
import { Chip as MuiChip } from "@mui/material";
import { useForms } from "app/forms/formsService";
import { CandidatePortal } from "app/shared/constants";
import { useTranslation } from "react-i18next";

const StartHereChip = ({ classes }) => {
  const { showStartHere } = useForms();
  const { t } = useTranslation();

  return showStartHere ? (
    <MuiChip
      label={t(CandidatePortal["START"])}
      sx={classes}
      size="small"
      variant="outlined"
    />
  ) : null;
};

export default StartHereChip;
