export const paperFullWidth = {
  "& .MuiPaper-root": {
    width: "100%",
    maxWidth: "532px",
  },
};
export const backdrop = {
  zIndex: 99,
};
export const title = {
  fontWeight: 500,
  fontSize: "18.75px",
  padding: "16px 24px 0px",
};
export const doneIcon = {
  verticalAlign: "text-bottom",
};
export const errorOutlineIcon = {
  fontSize: "22.5px",
  verticalAlign: "middle",
  marginBottom: "4px",
};
export const actionDialogRoot = {
  marginRight: "8px",
};
export const autocompleteStyles = {
  "& .MuiAutocomplete-endAdornment": {
    display: "block",
  },
  "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
    height: "auto",
    width: "auto",
  },
  "& .MuiAutocomplete-clearIndicator .MuiSvgIcon-root": {
    fontSize: "18.75px",
  },
  "& .MuiAutocomplete-popupIndicator .MuiSvgIcon-root": {
    fontSize: "22.5px",
  },
  "& .MuiInputLabel-root, .MuiInputBase-root": {
    fontSize: "15px",
  },
  "& .MuiInputBase-root": {
    paddingTop: "9px",
    paddingLeft: "6px",
    paddingBottom: "9px",
    "& .MuiAutocomplete-input": {
      paddingTop: "9.5px",
      paddingLeft: "6px",
      paddingBottom: "9.5px",
      height: "1.1876em",
    },
  },
};
