import { LogoContainer } from "./Logo.styles";
import StanfordMedicineSVG from "common/images/logo-stanfordmedicine.svg";

export const Logo = ({ logoTitle, logoSubtitle }) => {
  return (
    <LogoContainer>
      <div className="brand">
        <a
          href="https://med.stanford.edu/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={StanfordMedicineSVG}
            className="brand-logo"
            alt="Stanford Medicine"
          />
        </a>
        <div className="brand-sitename">
          <span className="wrap">
            <span className="brand-sitename-title">
              {logoTitle && <span>{logoTitle}</span>}
              {!logoTitle && <span>Site Name not specified</span>}
            </span>
            {logoSubtitle && (
              <span className="brand-sitename-subtitle">{logoSubtitle}</span>
            )}
          </span>
        </div>
        {/* <!-- Brand and toggle get grouped for better mobile display --> */}
        <button type="button" className="navbar-toggle">
          <span className="sr-only">Site Nav</span>
          <span className="navbar-toggle-icon" />
        </button>
      </div>
    </LogoContainer>
  );
};
