import styled from "@emotion/styled";
import { Theme } from "common/components/Theme/Theme";

export const LogoContainer = styled.div`
  .brand {
    float: left;
    height: 45px;
    margin: 0px;
    position: relative;
    width: auto;
    display: flex;

    @media (max-width: 991px) {
      line-height: 85%;
    }

    @media (max-width: 767px) {
      padding-right: 0;
      display: table-row;
    }

    &::before {
      content: " ";
      display: table;
    }

    &::after {
      content: " "
      display: table;
      clear: both;
    }

    .brand-logo {
      color: ${Theme.palette.text.primary};
      display: block;
      float: left;
      height: 45px;
      margin-right: 1px;
      margin-left: -2px;
      overflow-wrap: break-word;
      position: relative;
      text-decoration-line: none;
      text-indent: -9999px;
      width: 177px;
      z-index: 10;
      transform: scale(1.109) translateY(-0.5px) translateX(-0.1px);
    }

    .brand-sitename {
      padding-left: 11px;
      display: table-cell;
      font-size: 20px;
      height: 100%;
      line-height: 110%;
      position: relative;
      width: auto;
      z-index: 9;
      margin-top: -1px;
      padding-top: 1px;

      @media (max-width: 767px) {
        font-size: 17px;
        padding-left: 0px;
        background-color: white;
        position: absolute;
        z-index: 11;
        margin-left: 45px;
      }

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 18px;
      }

      @media (min-width: 992px) {
        margin-right: 168px;
        font-size: 20px;
      }

      .wrap {
        display: table-cell;
        height: 41px;
        vertical-align: middle;

        .brand-sitename-title {
          display: block;
          line-height: 21px;
          margin-top: 1px;

          @media (max-width: 991) {
            line-height: 85%;
          }
        }

        .brand-sitename-subtitle {
          display: block;
          font-size: 14px;
          font-style: italic;
          line-height: 13px;
          margin-top: 3px;
          white-space: normal;

          @media (min-width: 479px) {
            white-space: nowrap;
          }
        }

        @media (min-width: 768px) {
          vertical-align: bottom;
          height: 46px;
        }

        .separator {
          display: none !important;
        }
      }
    }

    .brand-sitename::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 0;
      width: 1px;
      height: 100%;
      background-color: ${Theme.palette.text.primary};

      @media (max-width: 767px) {
        display: none;
      }
    }

    .navbar-toggle {
      background-color: ${Theme.palette.common.white};
      background-image: none;
      border-radius: 4px;
      border: 1px solid transparent;
      float: right;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      margin: 8px 15px 8px 0px;
      padding: 9px 10px;
      position: relative;
      top: -5px;

      &:hover {
        background-color: ${Theme.palette.grey[400]};
      }

      &:focus {
        background-color: ${Theme.palette.grey[400]};
        outline: none;
      }

      .sr-only {
        border-color: rgb(0,0,0);
        border-style: none;
        border-width: 0px;
        border-image-outset: 0;
        border-image-repeat: stretch;
        border-image-slice: 100%;
        border-image-source: none;
        border-image-width: 1;
        clip: rect( 0px, 0px, 0px, 0px);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0px;
        position: absolute;
        width: 1px;
      }

      .icon-bar {
        display: block;
        width: 22px;
        height: 2px;
        border-radius: 1px;
        margin-top: 4px;
      }

      .navbar-toggle-icon {
        color: ${Theme.palette.text.primary};
        font-size: 32px;

        &::before {
          display: inline-block;
          font: normal normal normal 14px/1 FontAwesome;
          font-size: inherit;
          text-rendering: auto;
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          content: "\f0c9";
        }
      }

      @media (max-width: 479px) {
        display: none;
      }

      @media (min-width: 479px) {
        display: none;
      }

      @media (min-width: 768px) {
        display: none;
      }
    }
  }
`;
