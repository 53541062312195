import styled from "@emotion/styled";

// main tag should be moved to a main component
const BodyContainer = styled.div`
  background-color: #f0f4f5;
  flex-basis: "auto";
  flex-grow: 1;
  flex-shrink: 0;
  -webkit-box-flex: 0;
`;

export const Body = (props) => {
  return <BodyContainer>{props.children}</BodyContainer>;
};
