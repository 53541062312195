import styled from "@emotion/styled";
import { Theme } from "common/components/Theme/Theme";

export const ImpersonateContainer = styled.div`
  .impersonateInputName {
    .MuiOutlinedInput-root {
      background: white;
    }
  }

  .inputSunetId {
    padding: 4px 4px;
  }

  .viewAsLabelStyle {
    display: flex;
    align-items: center;
    padding-top: 4px;
    font-weight: 400;
  }

  .gridContainerStyle {
    display: grid;
    grid-gap: 5px;
    grid-auto-flow: column;
    width: 100%;
    justify-content: end;
  }

  .impersonateContainer {
    position: relative;
    text-align: right;
    z-index: 100;
    background-color: #ddd;
  }

  .impersonating {
    position: relative;
    text-align: right;
    z-index: 100;
    background-color: #ddd;
    color: white;
    background-color: #8c1515;
  }

  .impersonateEdit {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 15px;
    padding-left: 15px;

    .MuiIconButton-root {
      align-items: center;
      font-family: ${Theme.typography.fontFamily};
      font-size: 22.5px;
      height: auto;
      line-height: 20px;
      margin: 0;
      padding: 6px 3px;
      width: auto;
    }
  }

  .impersonateShow {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 15px;
    padding-left: 15px;

    .MuiIconButton-root {
      color: ${Theme.palette.common.white};
    }
  }
`;
