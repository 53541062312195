import { Theme } from "common";
import merge from "lodash/merge";

const ROOT_BACKGROUND_COLOR = "#F6F6F6";
const STANFORD_ACCENT_BLUE = "#009ABB";
const STANFORD_TEXT_GRAY = "#333333";
const WHITE = "#FFFFFF";

const MedActionsOverrides = {
  palette: {
    background: {
      default: ROOT_BACKGROUND_COLOR,
    },
  },
  MuiButton: {
    root: {
      fontSize: "17px",
    },
    containedPrimary: {
      color: WHITE,
      "&:hover": {
        backgroundColor: STANFORD_ACCENT_BLUE,
      },
    },
  },
  MuiTypography: {
    subtitle1: {
      color: STANFORD_TEXT_GRAY,
      fontWeight: 500,
    },
    body1: {
      fontWeight: 600,
    },
  },
  MuiIcon: {
    colorPrimary: STANFORD_ACCENT_BLUE,
  },
  MuiFormLabel: {
    root: {
      color: STANFORD_TEXT_GRAY,
    },
  },
};

const mergeOverrides = {
  overrides: merge(Theme && Theme.overrides, MedActionsOverrides),
};

export const MedActionsTheme = { ...Theme, mergeOverrides };
