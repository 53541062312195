import { useState, useEffect } from "react";
import {
  Autocomplete as MuiAutocomplete,
  TextField as MuiTextField,
  Typography as MuiTypography,
  Tooltip as MuiTooltip,
  FormHelperText as MuiFormHelperText,
  Grid as MuiGrid,
} from "@mui/material";
import { StyleApp } from "./ui.styles";
import { History as HistoryIcon, Info as InfoIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  replaceFieldCalculationValues,
  shouldRenderElement,
  addFieldValidation,
} from "app/shared/utils";
import isJSON from "is-json";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { useDirtyFlag } from "app/portal/DirtyFlagProvider";
import { Theme } from "common";

let timeout;

export const Select = (props) => {
  const {
    name,
    label,
    options,
    required,
    readOnly,
    helpertext,
    setScrollDialog,
    links,
    updated,
    formik,
    element,
    formFlatMap,
    globalValueObj,
    setInfoDialog,
    render,
    setRender,
    fieldHandler,
    validation,
    yup,
    valueType,
    duplicateIds,
    fieldAttr,
  } = props;
  const [changedValue, setChangedValue] = useState(formFlatMap?.[name] || null);
  const [inputValue, setInputValue] = useState("");
  const [exp, setExp] = useState("");
  const [inputEvent, setInputEvent] = useState();
  const [fieldRenderedExp, setFieldRenderedExp] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);

  const [fieldHelper, setFieldHelper] = useState(helpertext);
  const [touched, setTouched] = useState(false);
  const [fieldValidation, setFieldValidation] = useState(false);

  const { setIsDirty } = useDirtyFlag();

  const {
    type: validationType,
    test: validationScheme,
    message: validationMessage,
  } = isJSON(validation) ? JSON.parse(validation) : "";

  const { defaultValue = "" } = isJSON(fieldAttr) ? JSON.parse(fieldAttr) : {};

  const { t } = useTranslation();

  const iconSize = Boolean(updated) ? "37.5px" : "18.75px";
  const iconColor = Boolean(updated) ? "error" : "inherit";

  const ERROR =
    (!readOnly && touched && Boolean(formik.errors[name])) || fieldValidation;

  const FIELD_TEXT_COLOR =
    !ERROR && !fieldValidation ? Theme.palette.text.primary : undefined;

  const HELPER_TEXT_COLOR =
    (!readOnly && touched && Boolean(formik.errors[name])) || fieldValidation
      ? "error"
      : Theme.palette.text.secondary;

  useEffect(() => {
    if (fieldHandler) {
      // text attributes
      formik.values[fieldHandler] = inputValue;
      globalValueObj[fieldHandler] = inputValue;
      formFlatMap[fieldHandler] = inputValue;

      if (timeout) window.clearTimeout();
      timeout = window.setTimeout(() => {
        setRender((value) => value + 1);
      }, 100);
    }
  }, [
    inputValue,
    fieldHandler,
    formFlatMap,
    formik.values,
    globalValueObj,
    setRender,
  ]);

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation
    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formFlatMap,
          ...formik.values,
          ...globalValueObj,
        },
        element.field_editable
      )
    );
  }, [
    element.field_id,
    element.field_rendered,
    element.field_editable,
    globalValueObj,
    formFlatMap,
    formik?.values,
    element.field_value_type,
    render,
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp); // SYNC and ASYNC

      addFieldValidation({
        name,
        evaledExp,
        required,
        touched,
        helpertext,
        validationType,
        validationScheme,
        validationMessage,
        t,
        formik,
        setFieldValidation,
        setFieldHelper,
        formFlatMap,
        globalValueObj,
        yup,
        element,
        duplicateIds,
      });

      // ToDo
      if (!evaledExp && !readOnly) {
        setChangedValue("");
      }

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRenderedExp, name, render]);

  useEffect(() => {
    if (inputEvent) {
      let value = changedValue;

      // id attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_id`] = value?.["id"];
      globalValueObj[`${name}_id`] = value?.["id"];
      formFlatMap[`${name}_id`] = value?.["id"];

      // text attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_text`] = value?.["text"];
      globalValueObj[`${name}_text`] = value?.["text"];
      formFlatMap[`${name}_text`] = value?.["text"];

      // id attributes
      // As per new specification
      formik.values[`${name}.id`] = value?.["id"];
      globalValueObj[`${name}.id`] = value?.["id"];
      formFlatMap[`${name}.id`] = value?.["id"];

      // text attributes
      // As per new specification
      formik.values[`${name}.text`] = value?.["text"];
      globalValueObj[`${name}.text`] = value?.["text"];
      formFlatMap[`${name}.text`] = value?.["text"];

      formik.values[name] = value;
      globalValueObj[name] = value;
      formFlatMap[name] = value;

      setInputEvent(null);
      if (value) {
        delete formik.errors[name];
      }

      setRender((value) => value + 1);
    } else {
      let value = formFlatMap?.[name];

      // id attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_id`] = value?.["id"];
      globalValueObj[`${name}_id`] = value?.["id"];
      formFlatMap[`${name}_id`] = value?.["id"];

      // text attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_text`] = value?.["text"];
      globalValueObj[`${name}_text`] = value?.["text"];
      formFlatMap[`${name}_text`] = value?.["text"];

      // id attributes
      // As per new specification
      formik.values[`${name}.id`] = value?.["id"];
      globalValueObj[`${name}.id`] = value?.["id"];
      formFlatMap[`${name}.id`] = value?.["id"];

      // text attributes
      // As per new specification
      formik.values[`${name}.text`] = value?.["text"];
      globalValueObj[`${name}.text`] = value?.["text"];
      formFlatMap[`${name}.text`] = value?.["text"];

      formik.values[name] = value;
      globalValueObj[name] = value;
      formFlatMap[name] = value;

      if (defaultValue) setChangedValue(value);

      if (value) {
        delete formik.errors[name];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    changedValue,
    formFlatMap,
    formik.errors,
    formik.values,
    globalValueObj,
    inputEvent,
    name,
    setRender,
  ]);

  useEffect(() => {
    if (formik && element) {
      setExp(
        getCalculatedExp(
          element.field_calculation,
          {
            ...formFlatMap,
            ...globalValueObj,
            ...formik.values,
          },
          element.field_value_type,
          element.field_editable
        )
      );
    }
  }, [formik, globalValueObj, element, formFlatMap]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(exp);
      const fieldDefaultValue =
        shouldRender && String(formFlatMap?.[name]).trim() === ""
          ? getOptionsDefaultValueMap(options, defaultValue)
          : "";
      const fieldValue = shouldRender
        ? evaledExp || fieldDefaultValue || formFlatMap?.[name]
        : formFlatMap?.[name];

      if (exp || String(fieldValue)) {
        globalValueObj[name] = fieldValue;
        formik.values[name] = fieldValue;

        // ** ADDING WHILE PERFORMING SALARY SCALE FIXES
        formFlatMap[name] = fieldValue;

        // ** ADDING WHILE PERFORMING SALARY SCALE FIXES
        setChangedValue(fieldValue);
        if (timeout) window.clearTimeout();
        timeout = window.setTimeout(() => {
          setRender((value) => value + 1);
        }, 100);
      }
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exp, name, formik.values, globalValueObj, formFlatMap, defaultValue]);

  useEffect(() => {
    if (
      Boolean(changedValue?.id) &&
      options.length >= 0 &&
      !options.some((item) => item.id === changedValue.id) &&
      element.field_value_type !== "person" &&
      element.field_calculation !== ""
    ) {
      globalValueObj[name] = "";
      formik.values[name] = "";
      formFlatMap[name] = "";
      setInputValue("");
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length, changedValue?.id]);

  const shrinkProps = readOnly && { shrink: true };

  if (!shouldRender) return null;

  return (
    <MuiGrid item xs={12} id={`field-${name}`}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <MuiAutocomplete
          id={name}
          options={options}
          filterOptions={
            valueType === "person" ? (option, state) => option : undefined
          }
          isOptionEqualToValue={(option, optionValue) =>
            option?.id === optionValue?.id
          }
          getOptionLabel={(option) => option?.text || ""}
          disabled={readOnly}
          forcePopupIcon={!readOnly}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              error={ERROR}
              label={label}
              required={required}
              variant={readOnly ? "standard" : "outlined"}
              onBlur={() => {
                setTouched(true);
                return required && setRender((value) => value + 1);
              }}
              InputLabelProps={{
                sx: { color: FIELD_TEXT_COLOR },
                ...shrinkProps,
              }}
              sx={StyleApp.disabled}
            />
          )}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          fullWidth
          value={changedValue ? changedValue : null}
          onChange={(event, changeValue) => {
            if (typeof defaultValue !== "undefined") {
              setChangedValue(
                changeValue
                  ? changeValue
                  : getOptionsDefaultValueMap(options, defaultValue)
              );
            } else {
              setChangedValue(changeValue);
            }
            setInputEvent(event);
            setIsDirty(true);
          }}
          renderOption={(props, option) => (
            <div style={{ fontSize: "15px" }} {...props}>
              {`${option?.text}`}
            </div>
          )}
          sx={{
            "& .MuiAutocomplete-endAdornment .MuiIconButton-root": {
              // Move dropdown arrow and clear X up to horizontal center
              height: "auto",
            },
            "& .MuiInputLabel-root, .MuiInputBase-input": { fontSize: "15px" },
          }}
        />
        {element && Boolean(element.field_info.trim()) ? (
          <MuiTooltip title={t("globals.fieldInfo.tooltip")}>
            <InfoIcon
              onClick={() =>
                setInfoDialog({
                  show: true,
                  fieldLabel: label,
                  fieldContent: element.field_info,
                })
              }
              sx={{
                cursor: "pointer",
                fontSize: "18.75px",
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "21px" }}></span>
        )}
        {Boolean(links) ? (
          <MuiTooltip title={t("globals.fieldHistory.tooltip")}>
            <HistoryIcon
              color={iconColor}
              onClick={() =>
                setScrollDialog({
                  show: true,
                  links: links,
                  fieldLabel: label,
                })
              }
              sx={{
                marginLeft: "5px",
                fontSize: iconSize,
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "1.5em" }}></span>
        )}
      </div>
      {fieldHelper && (
        <MuiFormHelperText component="div">
          <MuiTypography
            color={HELPER_TEXT_COLOR}
            variant="subtitle2"
            component="span"
            dangerouslySetInnerHTML={{
              __html: sanitize(fieldHelper, {
                ALLOWED_TAGS: AllowedTags,
              }),
            }}
            sx={StyleApp.links}
            style={{ fontSize: "13.125px" }}
          ></MuiTypography>
        </MuiFormHelperText>
      )}
    </MuiGrid>
  );
};

const getCalculatedExp = (
  fieldCalculation,
  formValueObj,
  valueType,
  fieldEditable
) => {
  return replaceFieldCalculationValues(
    fieldCalculation,
    formValueObj,
    valueType,
    fieldEditable
  );
};

const getOptionsDefaultValueMap = (options, defaultValue) =>
  Array.isArray(options)
    ? options.find((item) => item.id === defaultValue)
    : "";
