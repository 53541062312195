import { useState, useEffect } from "react";
import { NumericFormat as NumberFormat } from "react-number-format";
import {
  TextField as MuiTextField,
  InputAdornment as MuiInputAdornment,
  Tooltip as MuiTooltip,
  FormHelperText as MuiFormHelperText,
  Typography as MuiTypography,
  Grid as MuiGrid,
} from "@mui/material";
import {
  replaceFieldCalculationValues,
  shouldRenderElement,
  addFieldValidation,
} from "app/shared/utils";
import { StyleApp } from "app/shared/ui/ui.styles";
import { History as HistoryIcon, Info as InfoIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import isJSON from "is-json";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { useDirtyFlag } from "app/portal/DirtyFlagProvider";
import { Theme } from "common";

let clearTimeout;

export const Numeral = (props) => {
  const {
    name,
    label,
    value,
    readOnly,
    required,
    onChange,
    element,
    fieldType,
    formFlatMap,
    formik,
    globalValueObj,
    fieldAttr,
    setScrollDialog,
    links,
    updated,
    helpertext,
    render,
    setRender,
    setInfoDialog,
    validation,
    yup,
    duplicateIds,
  } = props;

  const [inputValue, setInputValue] = useState(formFlatMap[name] || "");
  const [inputEvent, setInputEvent] = useState(null);
  const [fieldRenderedExp, setFieldRenderedExp] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const [fieldHelper, setFieldHelper] = useState(helpertext);
  const [touched, setTouched] = useState(false);
  const [fieldValidation, setFieldValidation] = useState(false);

  const { setIsDirty } = useDirtyFlag();

  const {
    type: validationType,
    test: validationScheme,
    message: validationMessage,
  } = isJSON(validation) ? JSON.parse(validation) : "";

  const shrinkProps = readOnly && { shrink: true };
  const [exp, setExp] = useState("");
  const { t } = useTranslation();

  const {
    decimalPlaces,
    maxValue,
    allowNegative,
    defaultValue = "",
  } = isJSON(fieldAttr) ? JSON.parse(fieldAttr) : {};

  const iconSize = Boolean(updated) ? "37.5px" : "18.75px";
  const iconColor = Boolean(updated) ? "error" : "inherit";

  const ERROR =
    (!readOnly && touched && Boolean(formik.errors[name])) || fieldValidation;

  const FIELD_TEXT_COLOR =
    !ERROR && !fieldValidation ? Theme.palette.text.primary : undefined;

  const HELPER_TEXT_COLOR =
    (!readOnly && touched && Boolean(formik.errors[name])) || fieldValidation
      ? "error"
      : Theme.palette.text.secondary;

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation
    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_editable
      )
    );
  }, [
    element.field_rendered,
    element.field_editable,
    globalValueObj,
    formFlatMap,
    formik,
    element.field_value_type,
    render,
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp);

      addFieldValidation({
        name,
        evaledExp,
        required,
        touched,
        helpertext,
        validationType,
        validationScheme,
        validationMessage,
        t,
        formik,
        setFieldValidation,
        setFieldHelper,
        formFlatMap,
        globalValueObj,
        yup,
        element,
        duplicateIds,
      });

      // ToDo
      if (!evaledExp && !readOnly) {
        setInputValue("");
      }

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRenderedExp, name, render]);

  useEffect(() => {
    if (formik && element) {
      const expression = getCalculatedExp(
        element.field_calculation,
        {
          ...formFlatMap,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_value_type,
        element.field_editable
      );
      setExp(expression);
    }
  }, [formik, element, globalValueObj, formik.values, formFlatMap]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(exp); // SYNC and ASYNC
      const fieldDefaultValue =
        shouldRender && String(formFlatMap?.[name]).trim() === ""
          ? String(defaultValue)
          : "";
      let fieldValue = formFlatMap?.[name]; // setting default to the value of formFlatMap
      if (shouldRender) {
        if (exp) {
          fieldValue = evaledExp;
        } else if (fieldDefaultValue) {
          fieldValue = fieldDefaultValue;
        }
      }

      if (exp || String(fieldValue)) {
        globalValueObj[name] = fieldValue;
        formik.values[name] = fieldValue;
        formFlatMap[name] = fieldValue;
        setInputValue(fieldValue);
        if (clearTimeout) window.clearTimeout(clearTimeout);
        clearTimeout = window.setTimeout(() => {
          setRender((value) => value + 1);
        }, 1000);
      }
    } catch (err) {
      console.log(name, err);
    }
  }, [
    exp,
    name,
    formik.values,
    formFlatMap,
    globalValueObj,
    readOnly,
    setRender,
    shouldRender,
    defaultValue,
  ]);

  useEffect(() => {
    if (inputEvent) {
      formik.values[name] = inputValue;
      globalValueObj[name] = inputValue;
      formFlatMap[name] = inputValue;

      if (inputValue) {
        delete formik.errors[name];
      }
      if (clearTimeout) window.clearTimeout(clearTimeout);
      clearTimeout = window.setTimeout(() => {
        setRender((value) => value + 1);
      }, 1000);
    } else {
      const value = formFlatMap?.[name];
      setInputValue(value);
      formik.values[name] = value;
      globalValueObj[name] = value;
      formFlatMap[name] = value;
    }

    // Why does onChange need to be in this dependency array?
    // Why is onChange never used in this entire component?

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue, onChange, inputEvent, value, formik.values[name]]);

  if (!formFlatMap) return null;

  if (!shouldRender) return null;

  if (readOnly)
    return (
      <MuiGrid item xs={12} id={`field-${name}`}>
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <MuiTextField
            id={name}
            name={name}
            error={ERROR}
            label={label}
            required={required}
            disabled={readOnly}
            value={getFormattedValue(
              inputValue,
              readOnly,
              fieldType,
              decimalPlaces
            )}
            fullWidth
            InputLabelProps={{ ...shrinkProps }}
            sx={StyleApp.disabled}
          ></MuiTextField>
          {element && Boolean(element.field_info.trim()) ? (
            <MuiTooltip title={t("globals.fieldInfo.tooltip")}>
              <InfoIcon
                fontSize="small"
                onClick={() =>
                  setInfoDialog({
                    show: true,
                    fieldLabel: label,
                    fieldContent: element.field_info,
                  })
                }
                sx={{
                  cursor: "pointer",
                  fontSize: "18.75px",
                }}
              />
            </MuiTooltip>
          ) : (
            <span style={{ width: "21px" }}></span>
          )}
          {Boolean(links) ? (
            <MuiTooltip title={t("globals.fieldHistory.tooltip")}>
              <HistoryIcon
                color={iconColor}
                onClick={() =>
                  setScrollDialog({
                    show: true,
                    links: links,
                    fieldLabel: label,
                    fieldType,
                  })
                }
                sx={{
                  marginLeft: "5px",
                  fontSize: iconSize,
                }}
              />
            </MuiTooltip>
          ) : (
            <span style={{ width: "1.5em" }}></span>
          )}
        </div>
        {fieldHelper && (
          <MuiFormHelperText component="div">
            <MuiTypography
              color={HELPER_TEXT_COLOR}
              variant="subtitle2"
              component="span"
              dangerouslySetInnerHTML={{
                __html: sanitize(fieldHelper, {
                  ALLOWED_TAGS: AllowedTags,
                }),
              }}
              sx={StyleApp.links}
              style={{ fontSize: "13.125px" }}
            ></MuiTypography>
          </MuiFormHelperText>
        )}
      </MuiGrid>
    );

  return (
    <MuiGrid item xs={12} id={`field-${name}`}>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <NumberFormat
          error={ERROR}
          id={name}
          label={label}
          thousandSeparator={fieldType !== "percent"}
          decimalScale={decimalPlaces}
          customInput={MuiTextField}
          isAllowed={(args) => {
            const allowedValue = args.value;
            if (allowedValue !== 0 && !allowedValue) return true;
            if (fieldType === "percent")
              return (
                allowedValue >= 0 &&
                (maxValue ? allowedValue <= maxValue : allowedValue <= 100)
              );
            else if (
              maxValue &&
              (fieldType === "number" || fieldType === "money")
            ) {
              return parseFloat(allowedValue) <= parseFloat(maxValue);
            }
            return true;
          }}
          allowNegative={allowNegative}
          InputProps={getInputAdornment(fieldType, readOnly)}
          value={inputValue}
          onValueChange={(values, e) => {
            if (typeof defaultValue !== "undefined") {
              setInputValue(values?.value ?? defaultValue);
            } else {
              setInputValue(values?.value ?? "");
            }
            setInputEvent(e.event);
            setIsDirty(true);
          }}
          onBlur={() => {
            setTouched(true);
            setInputEvent(null);
            return required && setRender((value) => value + 1);
          }}
          variant={"outlined"}
          autoComplete="off"
          fullWidth={true}
          required={required}
          InputLabelProps={{
            sx: { color: FIELD_TEXT_COLOR, fontSize: "15px" },
            ...shrinkProps,
          }}
          inputProps={{ style: { fontSize: "15px" } }}
          sx={StyleApp.disabled}
        />
        {element && Boolean(element.field_info.trim()) ? (
          <MuiTooltip title={t("globals.fieldInfo.tooltip")}>
            <InfoIcon
              fontSize="small"
              onClick={() =>
                setInfoDialog({
                  show: true,
                  fieldLabel: label,
                  fieldContent: element.field_info,
                })
              }
              sx={{
                cursor: "pointer",
                fontSize: "18.75px",
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "1.7em" }}></span>
        )}
        {Boolean(links) ? (
          <MuiTooltip title={t("globals.fieldHistory.tooltip")}>
            <HistoryIcon
              color={iconColor}
              onClick={() =>
                setScrollDialog({
                  show: true,
                  links: links,
                  fieldLabel: label,
                  fieldType,
                })
              }
              sx={{
                marginLeft: "5px",
                fontSize: iconSize,
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "1.5em" }}></span>
        )}
      </div>
      {fieldHelper && (
        <MuiFormHelperText component="div">
          <MuiTypography
            color={HELPER_TEXT_COLOR}
            variant="subtitle2"
            component="span"
            dangerouslySetInnerHTML={{
              __html: sanitize(fieldHelper, {
                ALLOWED_TAGS: AllowedTags,
              }),
            }}
            sx={StyleApp.links}
            style={{ fontSize: "13.125px" }}
          ></MuiTypography>
        </MuiFormHelperText>
      )}
    </MuiGrid>
  );
};

const getCalculatedExp = (
  fieldCalculation,
  formValueObj,
  valueType,
  fieldEditable
) => {
  return replaceFieldCalculationValues(
    fieldCalculation,
    formValueObj,
    valueType,
    fieldEditable
  );
};

const getInputAdornment = (fieldType, readOnly) => {
  if (!readOnly) {
    if (fieldType === "money") {
      return {
        startAdornment: (
          <MuiInputAdornment
            position="start"
            sx={{ "& .MuiTypography-root": { fontWeight: 600 } }}
          >
            $
          </MuiInputAdornment>
        ),
      };
    } else if (fieldType === "percent") {
      return {
        endAdornment: (
          <MuiInputAdornment
            position="start"
            sx={{ "& .MuiTypography-root": { fontWeight: 600 } }}
          >
            %
          </MuiInputAdornment>
        ),
      };
    }
  }
};

const getFormattedValue = (
  inputValue,
  readOnly,
  fieldType,
  decimalPlaces = ""
) => {
  if (!readOnly)
    return getFormattedNumber(inputValue, decimalPlaces, fieldType);
  if (fieldType === "percent" && String(inputValue).trim() !== "")
    return `${getFormattedNumber(inputValue, decimalPlaces, fieldType)}%`;
  else if (fieldType === "money" && String(inputValue).trim() !== "") {
    const MONEY_AMOUNT = getFormattedNumber(
      inputValue,
      decimalPlaces,
      fieldType
    );
    return `$${Intl.NumberFormat("en-US", {
      minimumFractionDigits:
        decimalPlaces !== "" && MONEY_AMOUNT % 1 !== 0
          ? decimalPlaces
          : undefined,
    }).format(String(MONEY_AMOUNT))}`;
  }
  return inputValue;
};

const getFormattedNumber = (num, decimalPlaces, fieldType) => {
  if (!decimalPlaces) return num;
  if (String(num).trim() === "" || isNaN(Number(num))) return "";
  if (fieldType === "money") return Number(num).toFixed(decimalPlaces);
  else if (fieldType === "number" || fieldType === "percent")
    return parseFloat(Number(num).toFixed(decimalPlaces));
};
