import { useEffect } from "react";
import { useApi } from "app/forms/apiService";
import { replaceFieldCalculationValues } from "app/shared/utils";
import isJSON from "is-json";

export const Hidden = ({
  name,
  element,
  formFlatMap,
  formik,
  setfieldvalue,
  globalValueObj,
  render,
  setRender,
  required,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { callApi, callApiDebounce, callApiSync, clearField } = useApi(
    formik,
    globalValueObj,
    formFlatMap,
    setRender,
    element
  );

  const attributes =
    element.field_attributes && isJSON(element.field_attributes)
      ? JSON.parse(element.field_attributes)
      : null;
  const fieldType = attributes?.field_type;

  const calculationExp = getCalculatedExp(
    element.field_calculation,
    {
      ...formFlatMap,
      ...globalValueObj,
      ...formik.values,
    },
    element.field_value_type,
    element.field_editable
  );

  useEffect(() => {
    try {
      (async () => {
        const isAwait = calculationExp.includes(" await ");
        const evaledExp = isAwait
          ? // eslint-disable-next-line no-eval
            await eval(calculationExp)
          : // eslint-disable-next-line no-eval
            eval(calculationExp);
        if (calculationExp) {
          globalValueObj[name] = evaledExp;
          formik.values[name] = evaledExp;
          formFlatMap[name] = evaledExp;

          if (isAwait || required) setRender((value) => value + 1);
        }
      })();
    } catch (err) {
      // console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, formFlatMap, formik.values, globalValueObj, calculationExp]);

  useEffect(() => {
    if (["radio", "dropdown"].includes(fieldType)) {
      formik.values[name] = formFlatMap[name];
      globalValueObj[name] = formFlatMap[name];
      // id attributes
      formik.values[`${name}.id`] = formFlatMap[name]?.["id"];
      globalValueObj[`${name}.id`] = formFlatMap[name]?.["id"];

      // text attributes
      formik.values[`${name}.text`] = formFlatMap[name]?.["text"];
      globalValueObj[`${name}.text`] = formFlatMap[name]?.["text"];
    } else if (["checkbox"].includes(fieldType)) {
      const idList = Array.isArray(formFlatMap[name])
        ? formFlatMap[name].map((item) => item?.id ?? item)
        : formFlatMap[name]?.["id"];
      // id list attributes
      formik.values[`${name}.ids`] = idList;
      globalValueObj[`${name}.ids`] = idList;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldType, name, render]);

  return null;
};

const getCalculatedExp = (
  fieldCalculation,
  formValueObj,
  valueType,
  fieldEditable
) => {
  return replaceFieldCalculationValues(
    fieldCalculation,
    formValueObj,
    valueType,
    fieldEditable
  );
};
