export const paperFullWidth = {
  "& .MuiPaper-root": {
    width: "100%",
    maxWidth: "532px",
  },
};
export const backdrop = {
  zIndex: 99,
};
export const title = {
  fontWeight: 500,
  fontSize: "18.75px",
  padding: "16px 24px",
};
