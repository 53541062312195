import { useCallback } from "react";
import axios from "axios";
import { useAlerts } from "common";
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from "app/shared/constants";
import { getStoreValue, setStoreValue } from "app/shared/utils";

let timeoutId = [];

export const useApi = (formik, globalValueObj, formFlatMap, setRender) => {
  const { clearAlert, setAlert } = useAlerts();

  const callApi = useCallback(
    async (url, id) => {
      try {
        if (!url.includes("{}")) {
          clearAlert();
          const URL = url.replace(/['"]+/g, "");
          const response = await axios.get(URL);
          const data = (response.data?.values ?? response.data) || [];
          globalValueObj[id] = data;
          formik.values[id] = data;
          formFlatMap[id] = data;
          setRender((value) => value + 1);
        }
      } catch (error) {
        console.log(id, error);
        setAlert("error", error.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearAlert, setAlert]
  );

  const callApiSync = useCallback(
    async (url) => {
      try {
        if (!url.includes("{}")) {
          clearAlert();
          const URL = url.replace(/['"]+/g, "");
          return axios.get(URL);
        }
      } catch (error) {
        console.log(error);
        setAlert("error", error.message);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearAlert, setAlert]
  );

  const clearField = (id) => {
    if (!getStoreValue("clearField").initialCall) {
      globalValueObj[id] = "";
      formik.values[id] = "";
      formFlatMap[id] = "";
    } else {
      setStoreValue("clearField", { initialCall: false });
    }
  };

  // callApiDebounce - would be used for the calls where user needs to get data dynamically
  // on the basis of searched keyword
  // This function is adding a debounce in calling the API in order to prevent multiple calls
  const callApiDebounce = useCallback(
    async (url, id) => {
      clearTimeout(timeoutId[id]);
      timeoutId[id] = setTimeout(async () => {
        try {
          if (!url.includes("{}")) {
            const URL = url.replace(/['"]+/g, "");
            const response = await axios.get(URL);
            const data = (response.data?.values ?? response.data) || [];
            globalValueObj[id] = data;
            formik.values[id] = data;
            formFlatMap[id] = data;
            setRender((value) => value + 1);
          }
        } catch (error) {
          console.log(id, error);
          clearAlert();
          setAlert("error", error.message);
        }
      }, AUTOCOMPLETE_DEBOUNCE_DELAY);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clearAlert, setAlert]
  );

  return { callApi, callApiDebounce, callApiSync, clearField };
};
