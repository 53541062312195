export const BundleIds = {
  MEDONBOARD_ADMIN: "onboarding",
  MEDONBOARD_PORTAL: "candidate-portal",
  MEDACT_ADMIN: "act",
  MEDLEAVE_ADMIN: "leave",
  RELEASE_MANAGER_ADMIN: "release",
};

export const TenantIds = {
  MEDLEAVE: "medleave",
  MEDONBOARD: "medonboard",
  MEDACT: "medact",
  RELMAN: "relman",
  UNKNOWN: "unknown",
};

export const FormStatus = {
  INITIATED: "formStatus.initiated",
  IN_PROGRESS: "formStatus.inProgress",
  RETURNED: "formStatus.returned",
  WITHDRAWN: "formStatus.withdrawn",
  APPROVED: "formStatus.approved",
  COMPLETED: "formStatus.completed",
  DECLINED: "formStatus.declined",
  DRAFT: "formStatus.draft",
};

// List of routes
// It could be generated automatically using an API under which
// we can get all of the form URLs.

export const FormList = {
  medonboard: [
    "onboarding_request_form",
    "onboarding_request_review_form",
    "onboarding_information_form",
    "candidate_intake_form",
    "candidate_questionnaire_form",
    "onboarding_release_form",
    "candidate_todo_list_form",
    "onboarding_summary_form",
    "onboarding_resolution_form",
    "onboarding_ssn_intro_form",
    "onboarding_ssn_access_form",
    "onboarding_candidate_invitation_form",
    "onboarding_mssd_summary_form",
    "onboarding_hr_summary_form",
    "onboarding_vs_summary_form",
    "onboarding_rmg_summary_form",
    "onboarding_fyi_summary_form",
    "onboarding_offsite1_summary_form",
    "onboarding_offsite2_summary_form",
    "onboarding_offsite3_summary_form",
    "onboarding_offsite4_summary_form",
    "onboarding_offsite5_summary_form",
    "onboarding_offsite6_summary_form",
    "onboarding_offsite7_summary_form",
    "onboarding_clinic1_summary_form",
    "onboarding_clinic2_summary_form",
    "onboarding_clinic3_summary_form",
    "onboarding_clinic4_summary_form",
    "onboarding_clinic5_summary_form",
    "onboarding_clinic6_summary_form",
    "onboarding_clinic7_summary_form",
    "onboarding_request_confirmation_form",
  ],
  medact: [
    "intake_information_form",
    "intake_information_school_reviewer_form",
    "action_resolution_form",
  ],
  medleave: [
    "admin_leave_request_form",
    "data_security_attestation_form",
    "faculty_approval_form",
    "leave_approval_summary_form",
    "leave_request_approval_form",
    "leave_request_review_form",
    "leave_resolution_form",
    "faculty_leave_request_form",
    "leave_request_confirmation_form",
    "leave_accrual_calculator_form",
  ],
  "candidate-portal": ["intake_form", "questionnaire_form", "todolist_form"],
  relman: [
    "release_request_form",
    "release_request_reviewer_form",
    "release_resolution_form",
  ],
};

export const CandidatePortal = {
  INTAKE: "portal.portalFormURL.intakeURL",
  QUESTIONNAIRE: "portal.portalFormURL.questionnaireURL",
  TODOLIST: "portal.portalFormURL.todolistURL",
  START: "Start Here",
};

export const OnboardCandidateStatus = {
  OPENED: "Opened",
  INVITED: "Invited",
  INTAKE_SUBMITTED: "Intake Submitted",
  QUESTIONNAIRE_SUBMITTED: "Questionnaire Submitted",
  SUBMITTED: "Submitted",
  RELEASED: "Released",
};

export const AUTOCOMPLETE_DEBOUNCE_DELAY = 500;

export const Buttons = {
  CANCEL: "CANCEL",
  SAVE: "SAVE",
  SUBMIT: "SUBMIT",
  CLOSE: "CLOSE",
  SAVE_FOR_LATER: "SAVE_FOR_LATER",
  BACK: "BACK",
  DELETE_SSN: "DELETE_SSN",
};

export const EventTypes = {
  action_change: "actionChange",
  action_data_change: "fieldChange",
  email_notification: "emailNotification",
  document_generated: "documentGenerated",
  document_deleted: "documentDeleted",
  document_renamed: "documentRenamed",
  document_replaced: "documentReplaced",
  document_uploaded: "documentUploaded",
  document_not_current: "documentMarkedObsolete",
  pubsub_notification: "pubsubNotification",
};

export const DeliveryStatus = {
  SENT: "deliveryStatus.sent",
  FAILED: "deliveryStatus.failed",
};

export const FileTypes = {
  CV: "fileTypes.cv",
  EXCEPTION_APPROVAL: "fileTypes.exceptionApproval",
  LETTER_OF_INTEREST: "fileTypes.letterOfInterest",
  CHAIR_MEMO: "fileTypes.chairMemo",
  OTHER: "fileTypes.other",
  TERM_SHEET: "fileTypes.termSheet",
  PRACTICE_POLICY_EXEMPTION: "fileTypes.practicePolicyExemption",
  TEACHING_COURSES_EXCEPTION_QUESTIONNAIRE:
    "fileTypes.teachingCoursesExceptionQuestionnaire",
  CONTINUANCE_OF_DUTIES_EXCEPTION: "fileTypes.continuanceOfDutiesException",
  LEAVE_REQUEST_WITH_PAYROLL_DOCUMENT:
    "fileTypes.leaveRequestWithPayrollDocument",
  LEAVE_REQUEST: "fileTypes.leaveRequest",
};

export const REGEX = {
  phone:
    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
};

export const DashboardColumnsWidth = {
  medonboard: {
    id: 95,
    candidateName: 170,
    hiringDepartment: 220,
    hiringDivision: 210,
    startDate: 153,
    status: 95,
    candidateStatus: 180,
    // To calculate width of links, multiply the amount of icons by 22.5, then add 20px
    links: 290,
  },
  medact: {
    id: 110,
    candidateName: 175,
    hiringDepartment: 225,
    hiringDivision: 205,
    candidateLine: 220,
    startDate: 155,
    termSheetStatus: 180,
    // To calculate width of links, multiply the amount of icons by 22.5, then add 20px
    links: 132.5,
  },
  medleave: {
    id: 90,
    facultyName: 150,
    department: 135,
    division: 130,
    leaveType: 80,
    leaveStartDate: 130,
    leaveEndDate: 120,
    status: 130,
    requestStatus: 220,
    // To calculate width of links, multiply the amount of icons by 22.5, then add 20px
    links: 270,
  },
  relman: {
    id: 120,
    releaseProject: 175,
    releaseTag: 80,
    releaseRolling: 180,
    releaseHighProfile: 145,
    releaseRequiresCoordination: 225,
    releaseRequestDate: 160,
    releaseStatus: 180,
    // To calculate width of links, multiply the amount of icons by 22.5, then add 20px
    links: 132.5,
  },
};

export const DashboardTableWidth = {
  medonboard: 1768,
  medact: 1755,
  medleave: 1820,
  relman: 1780,
};

export const Permissions = {
  IMPERSONATE_USER: "IMPERSONATE_USER",
};

export const StartForms = {
  medonboard: ["onboarding_request_form"],
  medact: ["intake_information_form"],
  medleave: ["admin_leave_request_form", "faculty_leave_request_form"],
  relman: ["release_request_form"],
};

export const AllowedTags = [
  "a",
  "b",
  "i",
  "p",
  "u",
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "ul",
  "li",
  "br",
  "span",
  "strong",
  "div",
  "hr",
  "wbr",
];

//Defining properties for additional buttons/forms
export const AdditionalButtons = {
  medleave: [
    {
      formName: "leave_accrual_calculator_form",
      tooltipTitle: "accrualCalculatorTooltipTitle",
      title: "accrualCalculatorTitle",
      icon: { name: "ExposureRoundedIcon" },
      style: { marginLeft: "16px" },
      iconOnly: false,
    },
  ]
};

// UPLOAD_ATTACHMENT_MAX_SIZE is in byte
export const UPLOAD_ATTACHMENT_MAX_SIZE = 10485760; // 10MB
