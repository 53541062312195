import { useTranslation } from "react-i18next";
import {
  Button as MuiButton,
  IconButton as MuiIconButton,
  Tooltip as MuiTooltip,
} from "@mui/material";
import {
  AddCircle as AddCircleIcon,
  ExposureRounded as ExposureRoundedIcon,
} from "@mui/icons-material";
import { StartForms, AdditionalButtons } from "app/shared/constants";
import { useApp } from "app/appService";
import { useRoutes } from "app/routes/routesService";
import { startActionContainer } from "./Actions.styles";

//MaterialIcon is for dynamic icon rendering
const MaterialIcon = (icon) => {
  switch (icon.name) {
    case "ExposureRoundedIcon":
      return (
        <ExposureRoundedIcon
          fontSize={icon?.size || "default"}
          color={icon?.color || "inherit"}
        />
      );
    default:
      return null;
  }
};

export const StartAction = ({ actionLinks }) => {
  const { tenantId } = useApp();
  const { t } = useTranslation();
  const { setServiceUrl } = useRoutes();

  // showActionButton is to check if form name matches or not
  const showActionButton = (links, formName) => {
    let startFormExist = Object.keys(links).some(
      (formLink) => formLink === formName
    );
    return startFormExist;
  };

  // formName is to get the name of the form
  const formName = StartForms[tenantId].map(
    (formName) => showActionButton(actionLinks, formName) && formName
  );

  // filterFormName to filter out falsy values from the array
  const filterFormName = (nameArray) => {
    return nameArray.filter((item) => item);
  };

  // formLink is to get the href/url from the response for the formName
  const formLink = formName && {
    apiUrl: actionLinks?.[filterFormName(formName)[0]]?.href,
  };

  // Creating new additionalButtons array with updated formlink
  const additionalButtons = [];

  AdditionalButtons[tenantId]?.forEach((button) => {
    if (showActionButton(actionLinks, button.formName)) {
      const additionalFormName = button.formName;
      button.url = { apiUrl: actionLinks?.[additionalFormName]?.href };
      additionalButtons.push(button);
    }
  });

  return (
    <div style={startActionContainer}>
      {formLink?.apiUrl ? (
        <MuiButton
          variant="contained"
          color="primary"
          startIcon={<AddCircleIcon />}
          onClick={() => setServiceUrl(formLink)}
          sx={{ fontSize: "17px" }}
        >
          {t(`dashboard.action.create.${tenantId}.createButtonText`)}
        </MuiButton>
      ) : null}
      {additionalButtons.map((item, index) => {
        return (
          <MuiTooltip
            title={t(
              `dashboard.action.create.${tenantId}.${item.tooltipTitle}`
            )}
            key={index}
          >
            {item.iconOnly ? (
              <MuiIconButton
                style={item.style}
                onClick={() => setServiceUrl(item.url)}
                sx={{ fontSize: "17px" }}
              >
                {MaterialIcon(item.icon)}
              </MuiIconButton>
            ) : (
              <MuiButton
                variant="contained"
                color="primary"
                startIcon={MaterialIcon(item.icon)}
                onClick={() => setServiceUrl(item.url)}
                style={item.style}
                sx={{ fontSize: "17px" }}
              >
                {item.title
                  ? t(`dashboard.action.create.${tenantId}.${item.title}`)
                  : null}
              </MuiButton>
            )}
          </MuiTooltip>
        );
      })}
    </div>
  );
};
