import React, { createContext, useContext, useState } from "react";

const DirtyFlagContext = createContext();

/**
 * This is a TEMPORARY context provider to help get around the issues MedOnboard
 * is experiencing with the dirty flag in the Portal forms and that the "unsaved changes"
 * warning dialog was always showing even when the form was not dirty.
 *
 * The purpose of this context is to provide a universal location to find a dirty
 * flag that any of the dynamically generated components can utilize to ensure
 * the dirty state of the Portal forms is tracked correctly.
 *
 * REPLACE THIS CONTEXT AT YOUR EARLIEST CONVENIENCE.
 *
 * i.e. when a major refactor of the codebase is available, ideally merge this logic
 * into the main FormsProvider and ensure the logic remains intact throughout the
 * rest of the codebase. Or find an entirely different and better solution.
 *
 * @param {element} param0 A React component
 * @returns A React Context Provider
 */
export const DirtyFlagProvider = ({ children }) => {
  const [isDirty, setIsDirty] = useState(false);

  return (
    <DirtyFlagContext.Provider value={{ isDirty, setIsDirty }}>
      {children}
    </DirtyFlagContext.Provider>
  );
};

export const useDirtyFlag = () => {
  const context = useContext(DirtyFlagContext);
  if (!context) {
    throw new Error(
      "useDirtyFlag must be used within an DirtyFlagContext. Wrap a parent component in <DirtyFlagContext> to fix this error."
    );
  }

  return context;
};
