import { Box as MuiBox } from "@mui/material";
import {
  ButtonCollection,
  Checkbox,
  Instructions,
  Label,
  Radio,
  Section,
  Hidden,
  DatePicker,
  Select,
  TextField,
  Numeral,
} from "app/shared/ui";
import { FormStatus, Buttons } from "app/shared/constants";
import { FormSummary } from "app/forms/FormSummary";
import { FormSummaryView } from "app/forms/FormSummaryView";
import {
  replaceFieldCalculationValues,
  isReadonly,
  getFormattedDate,
  generateFormServiceUrl,
} from "app/shared/utils";
import { saveAction } from "app/actions/actionsService";
import isJSON from "is-json";
import { ErrorList } from "app/shared/ErrorList";
import { useApp } from "app/appService";

export const FormElement = ({
  element,
  formik,
  actionId,
  pageType,
  formFlatMap,
  navigate,
  setFieldHandler,
  setConfirmationDialog,
  allFields,
  summaryState,
  summaryFields,
  t,
  globalValueObj,
  setServiceUrl,
  clearAlert,
  setAlert,
  tenantId,
  setScrollDialog,
  render,
  setRender,
  setInfoDialog,
  yup,
  setFormUrl,
  duplicateIds,
  labelMap,
}) => {
  const elementProps = {};
  const { bundleId } = useApp();

  const setFieldValue = !["primary_button", "secondary_button"].includes(
    element.field_type
  )
    ? formik.setFieldValue
    : undefined;

  const fieldRequired = isFieldRequired(element.field_required, {
    ...formFlatMap,
    ...formik.values,
    ...globalValueObj,
  });

  // Props - Form Element to pass further
  elementProps["name"] = element.field_id.trim();
  elementProps["label"] = element.field_label.trim();
  elementProps["options"] = element.field_options;
  elementProps["value"] = formik.values[element.field_id] || null;
  elementProps["required"] = fieldRequired;
  elementProps["actionid"] = actionId;
  elementProps["setfieldvalue"] = setFieldValue;
  elementProps["formspec"] = allFields;
  elementProps["duplicateIds"] = duplicateIds;
  elementProps["helpertext"] = element.field_helper.trim();
  elementProps["fieldType"] = element.field_type.trim();
  elementProps["fieldAttr"] = element.field_attributes.trim();
  elementProps["valueType"] = element.field_value_type;
  elementProps["fieldHandler"] = element.field_handler;
  elementProps["setScrollDialog"] = setScrollDialog;
  elementProps["setInfoDialog"] = setInfoDialog;
  elementProps["links"] = element?._links?.self?.href;
  elementProps["updated"] = element?.updated;
  elementProps["validation"] = element?.field_validation;
  elementProps["yup"] = yup;

  if (
    pageType.toLowerCase() === "view" ||
    isReadonly(element) ||
    String(element.field_editable) === "false"
  ) {
    elementProps["readOnly"] = true;
  }

  const annotation = element?.["field_format"]?.split(" ");

  // eslint-disable-next-line no-unused-vars
  const generateSummary = (fieldIds, showEmptyFields = false) => {
    const fields = fieldIds.map((item) => getFieldObject(item, allFields));
    return (
      <FormSummary
        fields={fields}
        formValues={formFlatMap}
        formFields={allFields}
        showEmptyFields={showEmptyFields}
      />
    );
  };

  const handleFormSubmit = (request, redirectUrl, serviceUrl) => {
    const formTitle = allFields[0]?.field_label || "";
    saveAction({
      values: formik.values,
      id: actionId,
      setSubmitting: formik.setSubmitting,
      formTitle,
      resetForm: formik.resetForm,
      setServiceUrl: setServiceUrl,
      clearAlert,
      setAlert,
      t,
      navigate,
      formFields: allFields,
      tenantId,
      formik,
      setConfirmationDialog,
      request,
      redirectUrl,
      serviceUrl,
      setFormUrl,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const generateMultipleSummary = (fieldIds, showEmptyFields = false) => {
    if (!element["field_value"]) return null;

    const fields = fieldIds.map((item) => getFieldObject(item, allFields));
    return element["field_value"].map((item) => (
      <MuiBox m={2} sx={{ border: "1px solid grey" }}>
        <FormSummary
          fields={fields}
          formValues={{
            ...formFlatMap,
            ...item.values,
            action_id: item.id,
            action_status: t(FormStatus[item.status]),
          }}
          formFields={allFields}
          showEmptyFields={showEmptyFields}
        />
      </MuiBox>
    ));
  };

  /**
   * Helper method to dynamically generate a button based exclusively on configurations
   * specified in the FormSpec spreadsheet.
   *
   * This method is only ever called via the FormSpec field_handler column configuration.
   * The only way the app knows to call this function is via an eventual eval() in the
   * element['field_type'] switch-case further down this file. Specifically the "button" case.
   *
   * @param {object} param0 Button configurations
   * @returns A configured MUI ButtonCollection component
   * @see medactions-api FormSpec XLSX file for examples
   */
  // eslint-disable-next-line no-unused-vars
  const handleSubmit = ({ type, confirmation, request, redirectUrl }) => {
    const { title, okButton, cancelButton, primaryAction } = confirmation
      ? confirmation
      : {
          title: "",
          okButton: "",
          cancelButton: "",
          primaryAction: "",
        };

    const buttonLabel = element.field_label;

    switch (type) {
      case Buttons.CLOSE:
      case Buttons.CANCEL: {
        const clickHandler = () => {
          const formServiceUrl =
            redirectUrl && generateFormServiceUrl(redirectUrl, tenantId);
          if (title && cancelButton && okButton && !pageType) {
            showConfirmation(title, cancelButton, okButton);
          } else {
            formServiceUrl
              ? setServiceUrl({ apiUrl: formServiceUrl })
              : navigate("/");
          }
        };
        return (
          <ButtonCollection
            {...elementProps}
            label={pageType ? Buttons.CLOSE : buttonLabel}
            globalValueObj={globalValueObj}
            formik={formik}
            formFlatMap={formFlatMap}
            element={element}
            render={render}
            navigate={navigate}
            onClick={clickHandler}
            type={pageType ? Buttons.CLOSE : type}
          />
        );
      }
      case Buttons.SUBMIT:
      case Buttons.SAVE_FOR_LATER:
      case Buttons.SAVE:
        if (!pageType) {
          const clickHandler = () => {
            formik.values["_request_type"] = type;
            const serviceUrl =
              redirectUrl && generateFormServiceUrl(redirectUrl, tenantId);
            if (title && cancelButton && okButton) {
              showConfirmation(
                title,
                cancelButton,
                okButton,
                primaryAction,
                request,
                redirectUrl,
                serviceUrl
              );
            } else {
              handleFormSubmit(request, redirectUrl, serviceUrl);
            }
          };
          return (
            <ButtonCollection
              {...elementProps}
              label={buttonLabel}
              value={element["field_value"]}
              globalValueObj={globalValueObj}
              formik={formik}
              formFlatMap={formFlatMap}
              element={element}
              render={render}
              setRender={setRender}
              navigate={navigate}
              onClick={clickHandler}
              type={type}
            />
          );
        }
        break;
      default:
        break;
    }
    return null;
  };

  const showConfirmation = (
    content,
    secondaryLabel,
    primaryLabel,
    primaryAction,
    request,
    redirectUrl,
    serviceUrl
  ) => {
    // if (!formik.dirty) history({ pathname: "/" });
    const dialogProps = {
      show: true,
      content,
      secondaryLabel,
      primaryLabel,
    };

    if (primaryAction) {
      dialogProps["request"] = request;
      dialogProps["redirectUrl"] = redirectUrl;
      dialogProps["serviceUrl"] = serviceUrl;
      dialogProps["primaryAction"] = handleFormSubmit;
    } else dialogProps["primaryAction"] = navigate;

    setConfirmationDialog(dialogProps);
  };

  // eslint-disable-next-line no-unused-vars
  const formatCommentHistory = (content) => {
    let html = "";
    if (Array.isArray(content)) {
      content.forEach((item) => {
        html += getHistoryItem(item);
      });
    }
    elementProps["content"] = html;
  };

  // eslint-disable-next-line no-unused-vars
  const getBundleId = () => bundleId;

  switch (element["field_type"]) {
    case "text":
      const content = element?.["field_content"]?.trim();
      if (content) {
        // eslint-disable-next-line no-eval
        eval(
          replaceFieldCalculationValues(content, {
            ...formFlatMap,
            ...formik.values,
          })
        );
      }
      return (
        <FormTextField
          element={element}
          annotation={annotation}
          formik={formik}
          elementProps={elementProps}
          formFlatMap={formFlatMap}
          globalValueObj={globalValueObj}
          content={elementProps["content"]}
          render={render}
          setRender={setRender}
        />
      );
    case "money":
    case "percent":
    case "number":
      return (
        <Numeral
          // TODO: What is formik.handleChange? Why are we passing this here?
          // handleChange does not appear to have a value when looking in the debugger
          // it is also never used inside of <Numeral /> at all.
          onChange={formik.handleChange}
          {...elementProps}
          value={element["field_value"]}
          globalValueObj={globalValueObj}
          formik={formik}
          formFlatMap={formFlatMap}
          element={element}
          render={render}
          setRender={setRender}
        />
      );
    case "section":
      return (
        <Section
          label={element["field_label"]}
          formValues={formFlatMap}
          {...elementProps}
          formik={formik}
          formFlatMap={formFlatMap}
          globalValueObj={globalValueObj}
          element={element}
          render={render}
          setRender={setRender}
        />
      );
    case "dropdown":
      return (
        <FormDropDown
          element={element}
          formik={formik}
          formFlatMap={formFlatMap}
          globalValueObj={globalValueObj}
          elementProps={elementProps}
          render={render}
          setRender={setRender}
        />
      );
    case "descriptive":
      return (
        <Label
          {...elementProps}
          element={element}
          formik={formik}
          formFlatMap={formFlatMap}
          globalValueObj={globalValueObj}
          formValues={{ ...formFlatMap, ...formik.values }}
          render={render}
          setRender={setRender}
        />
      );
    case "radio":
      const optionsArray = replaceFieldCalculationValues(
        element["field_options"],
        {
          ...formFlatMap,
          ...formik.values,
        }
      ).replace(/(^"|"$)/g, "");

      return isJSON(optionsArray) && Object.keys(formFlatMap).length > 0 ? (
        <Radio
          {...elementProps}
          options={JSON.parse(optionsArray)}
          formValues={formFlatMap}
          formik={formik}
          globalValueObj={globalValueObj}
          setRender={setRender}
          element={element}
        />
      ) : null;
    case "instructions":
      return (
        <Instructions
          {...elementProps}
          formValues={formFlatMap}
          render={render}
        />
      );
    case "checkbox":
      const options = replaceFieldCalculationValues(element["field_options"], {
        ...formFlatMap,
        ...formik.values,
      }).replace(/(^"|"$)/g, "");
      return isJSON(options) ? (
        <Checkbox
          {...elementProps}
          options={JSON.parse(options)}
          formValues={formFlatMap}
          formFlatMap={formFlatMap}
          element={element}
          formik={formik}
          globalValueObj={globalValueObj}
          setRender={setRender}
        />
      ) : null;
    case "button":
      let handler = replaceFieldCalculationValues(element["field_handler"], {
        ...formFlatMap,
        ...formik.values,
      }).replace(/(^"|"$)/g, "");

      // eslint-disable-next-line no-eval
      return handler ? eval(handler) : null;
    case "summary":
      if (element?.["field_content"]) {
        // eslint-disable-next-line no-eval
        return eval(element?.["field_content"]);
      }
      if (!summaryState) {
        return (
          <FormSummaryView
            summaryList={summaryFields}
            formik={formik}
            formFlatMap={formFlatMap}
            globalValueObj={globalValueObj}
            element={element}
            render={render}
            setRender={setRender}
            getFieldObject={getFieldObject}
            allFields={allFields}
            t={t}
            setScrollDialog={setScrollDialog}
          />
        );
      }
      break;
    case "tab":
      // Setting field handler for Portal App
      if (setFieldHandler) {
        setFieldHandler(element["field_handler"].trim());
      }
      break;
    case "date":
      return (
        <DatePicker
          {...elementProps}
          element={element}
          formFlatMap={formFlatMap}
          globalValueObj={globalValueObj}
          formik={formik}
          render={render}
          setRender={setRender}
        />
      );
    case "errors":
      if (pageType.toLowerCase() === "view") return "";
      return (
        <ErrorList
          errors={formik.errors}
          formFlatMap={formFlatMap}
          labelMap={labelMap}
          {...elementProps}
        />
      );
    case "hidden":
    default:
      return (
        <Hidden
          {...elementProps}
          formik={formik}
          globalValueObj={globalValueObj}
          formFlatMap={formFlatMap}
          element={element}
          render={render}
          setRender={setRender}
        />
      );
  }
  return null;
};

const FormTextField = ({
  element,
  formik,
  elementProps,
  formFlatMap,
  globalValueObj,
  content,
  render,
  setRender,
}) => {
  if (isReadonly(element)) {
    elementProps["value"] =
      formik.values[element["field_id"]] || element["field_value"];
    return (
      <TextField
        readOnly={true}
        element={element}
        formFlatMap={formFlatMap}
        {...elementProps}
        globalValueObj={globalValueObj}
        formik={formik}
        setRender={setRender}
      />
    );
  } else {
    return (
      <TextField
        element={element}
        {...elementProps}
        onChange={formik.handleChange}
        formFlatMap={formFlatMap}
        globalValueObj={globalValueObj}
        formik={formik}
        content={content}
        render={render}
        setRender={setRender}
      />
    );
  }
};

const FormDropDown = ({
  element,
  formik,
  formFlatMap,
  globalValueObj,
  elementProps,
  render,
  setRender,
}) => {
  if (element["field_options"].includes("${", 0)) {
    const optionsArray = replaceFieldCalculationValues(
      element["field_options"],
      {
        ...formFlatMap,
        ...formik.values,
      }
    ).replace(/(^"|"$)/g, "");

    let options = [];
    if (isJSON(optionsArray)) {
      const parsedArray = JSON.parse(optionsArray);
      options = Object.keys(parsedArray).length ? parsedArray : [];
    }

    if (Object.keys(formFlatMap).length === 0) return "";
    return (
      <Select
        element={element}
        {...elementProps}
        options={options}
        formik={formik}
        formFlatMap={formFlatMap}
        globalValueObj={globalValueObj}
        render={render}
        setRender={setRender}
      />
    );
  }
  return "";
};

const getFieldObject = (key, fields) =>
  fields.find((item) => item.field_id === key && item.field_type);

const isFieldRequired = (fieldRequiredExp, formValueObj) => {
  // eslint-disable-next-line no-eval
  return eval(replaceFieldCalculationValues(fieldRequiredExp, formValueObj));
};

const getHistoryItem = (item) => {
  if (!Boolean(String(item.changeValue).trim())) return "";
  if (item.changeValue) {
    return `${getFormattedDate(item.modified)} by ${item.modifiedBy.text}\n${
      item.changeValue ? item.changeValue + "\n" : ""
    }\n`;
  }
};
