import {
  Button as MuiButton,
  Dialog as MuiDialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogContentText as MuiDialogContentText,
  Typography as MuiTypography,
  CircularProgress as MuiCircularProgress,
  Backdrop as MuiBackdrop,
} from "@mui/material";

const backdrop = {
  zIndex: 1201,
};

export const ConfirmationDialog = ({
  show,
  setShow,
  content,
  secondaryLabel = "No",
  primaryLabel = "Yes",
  primaryAction,
  formik,
  request,
  redirectUrl,
  serviceUrl,
}) => {
  const handleCancel = () => {
    setShow({ ...show, show: false });
  };
  const handleOk = () =>
    request || redirectUrl || serviceUrl
      ? primaryAction(request, redirectUrl, serviceUrl)
      : primaryAction("/");

  return (
    <MuiDialog
      open={show}
      onClose={handleCancel}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <MuiDialogContent>
        <MuiBackdrop
          open={formik ? formik.isSubmitting : false}
          invisible={true}
          sx={backdrop}
          children={<MuiCircularProgress />}
        />
        <MuiDialogContentText id="confirmation-dialog-description">
          <MuiTypography
            variant="body1"
            component="span"
            sx={{ fontSize: "17px", fontWeight: 600 }}
          >
            {content}
          </MuiTypography>
        </MuiDialogContentText>
      </MuiDialogContent>
      <MuiDialogActions>
        <MuiButton
          onClick={handleCancel}
          color="primary"
          sx={{ fontSize: "17px" }}
        >
          {secondaryLabel}
        </MuiButton>
        <MuiButton
          onClick={handleOk}
          color="primary"
          autoFocus
          sx={{ fontSize: "17px" }}
        >
          {primaryLabel}
        </MuiButton>
      </MuiDialogActions>
    </MuiDialog>
  );
};
