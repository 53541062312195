import { Typography as MuiTypography } from "@mui/material";
import { GridOverlay as MuiGridOverlay } from "@mui/x-data-grid-pro";

export const NoRowsGridOverlay = ({ message }) => {
  return (
    <MuiGridOverlay>
      <MuiTypography variant="body2">{message}</MuiTypography>
    </MuiGridOverlay>
  );
};
