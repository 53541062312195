// Authentication service handling candidate authentication, authentication state management,
// token refresh, and the axios authorization header. Exports a provider and a custom hook used by
// other common code. The application never needs to use this service as authentication is handled in
// the background, allowing the application to assume the current user is authenticated (though not
// necessarily authorized, which the application checks using the users service).

import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useAlerts } from "common";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

const setAuthorizationHeader = (token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const PortalAuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [candidateActionId, setCandidateActionId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const loginKey = new URLSearchParams(window.location.search).get("key") || "";

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const response = await axios({
          method: "post",
          url: "auth/login",
          data: { secretCode: loginKey },
        });

        setAuthLocalStorage(response);

        setAuthorizationHeader(response.data.bearerToken);
        setCandidateActionId(response.data.actionId);
        setIsAuthenticated(true);
      } catch (error) {
        // Checking with localStorage
        if (
          getAuthLocalStorage("bearerToken") &&
          getAuthLocalStorage("actionId")
        ) {
          setAuthorizationHeader(getAuthLocalStorage("bearerToken"));
          setCandidateActionId(getAuthLocalStorage("actionId"));
          setIsAuthenticated(true);
          // TODO: Add error state to PortalAuthProvider which PortalApp can use to display error with setAlert,
          //       or alternatively add generic text to PortalLink that eliminates the need for an error message
          // } else {
          //   loginKey && setAlert("error", error.message);
        } else {
          setIsError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [loginKey]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, candidateActionId, isError, isLoading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook
export const usePortalAuth = () => {
  const { clearAlert, setAlert } = useAlerts();
  const { t } = useTranslation();

  const postLoginRequest = async ({
    values,
    setSubmitting,
    setSuccessMessage,
  }) => {
    try {
      clearAlert();
      setSubmitting(true);

      await axios({
        method: "post",
        url: "auth/token",
        data: values,
        // Removing Authorization header for Login API.
        headers: {
          Authorization: undefined,
        },
      });
      setSuccessMessage(true);
    } catch (error) {
      let errorMessage =
        (error && error.message) || t("portal.portalLogin.notification.error");

      setAlert("error", errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  const postLogoutRequest = async () => {
    try {
      await axios({
        method: "post",
        url: "auth/logout",
      });
      clearAuthStorage();
      window.location.href = "/";
    } catch (err) {
      clearAuthStorage();
      console.log(err.message);
    }
  };

  return {
    isAuthenticated: useContext(AuthContext).isAuthenticated,
    candidateActionId: useContext(AuthContext).candidateActionId,
    isLoading: useContext(AuthContext).isLoading,
    isError: useContext(AuthContext).isError,
    postLoginRequest,
    postLogoutRequest,
  };
};

const setAuthLocalStorage = (response) => {
  // setting auth information in localStorage
  window.localStorage.setItem("bearerToken", response.data.bearerToken);
  window.localStorage.setItem("actionId", response.data.actionId);
};

const getAuthLocalStorage = (key) => {
  // getting auth information from localStorage
  return window.localStorage.getItem(key);
};

export const clearAuthStorage = () => {
  window.localStorage.removeItem("bearerToken");
  window.localStorage.removeItem("actionId");
};

// to goal of this portal service to make the auth for portal in silo.
// independent of other code of admin
