export const dropZoneStyles = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  minHeight: "79px",
  border: "1px dashed #c6c6c6",
  borderRadius: "5px",
  "& .MuiSvgIcon-root": {
    display: "none",
  },
  "& .MuiTypography-root": {
    fontSize: "14px",
    whiteSpace: "pre-line",
  },
  "& .MuiSvgIcon-root ~ .MuiTypography-root": {
    whiteSpace: "nowrap",
  },
  "& .MuiBox-root": {
    minHeight: "0px",
    border: "none",
  },
};
