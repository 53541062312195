import { useState, useEffect, memo } from "react";
import {
  Typography as MuiTypography,
  RadioGroup as MuiRadioGroup,
  FormControlLabel as MuiFormControlLabel,
  Radio as MuiRadio,
  FormHelperText as MuiFormHelperText,
  Tooltip as MuiTooltip,
  Box as MuiBox,
  Grid as MuiGrid,
  FormControl as MuiFormControl,
} from "@mui/material";
import { StyleApp } from "app/shared/ui/ui.styles";
import { History as HistoryIcon, Info as InfoIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  setFieldsInText,
  getCalculatedExp,
  shouldRenderElement,
  addFieldValidation,
} from "app/shared/utils";
import isJSON from "is-json";
import { sanitize } from "dompurify";
import { AllowedTags } from "app/shared/constants";
import { useDirtyFlag } from "app/portal/DirtyFlagProvider";
import { Theme } from "common";

let timeout;

export const Radio = memo((props) => {
  const [text, setText] = useState();

  let { formValues } = props;
  const {
    label,
    name,
    options,
    required,
    setfieldvalue,
    value,
    readOnly,
    actionid,
    formspec,
    helpertext,
    setScrollDialog,
    links,
    updated,
    formik,
    element,
    globalValueObj,
    formFlatMap,
    setRender,
    setInfoDialog,
    render,
    validation,
    yup,
    duplicateIds,
    fieldAttr,
  } = props;

  const [inputValue, setInputValue] = useState(formValues?.[name] || "");
  const [inputEvent, setInputEvent] = useState();
  const [exp, setExp] = useState("");
  const [fieldRenderedExp, setFieldRenderedExp] = useState(true);
  const [shouldRender, setShouldRender] = useState(false);
  const [fieldHelper, setFieldHelper] = useState(helpertext);
  const [fieldValidation, setFieldValidation] = useState(false);

  const { setIsDirty } = useDirtyFlag();

  const {
    type: validationType,
    test: validationScheme,
    message: validationMessage,
  } = isJSON(validation) ? JSON.parse(validation) : "";

  const { defaultValue = "" } = isJSON(fieldAttr) ? JSON.parse(fieldAttr) : {};

  const { t } = useTranslation();

  const iconSize = Boolean(updated) ? "37.5px" : "18.75px";
  const iconColor = Boolean(updated) ? "error" : "inherit";

  const ERROR = fieldValidation;
  const FIELD_TEXT_COLOR = !fieldValidation
    ? Theme.palette.text.primary
    : undefined;
  const HELPER_TEXT_COLOR = fieldValidation
    ? "error"
    : Theme.palette.text.secondary;

  useEffect(() => {
    // Field Rendered evaulation - Should render this component or not, will depend on field rendered evaluation
    setFieldRenderedExp(
      shouldRenderElement(
        element.field_rendered,
        element.field_value_type,
        {
          ...formValues,
          ...globalValueObj,
          ...formik.values,
        },
        element.field_editable
      )
    );
  }, [
    element.field_id,
    element.field_rendered,
    element.field_editable,
    globalValueObj,
    formFlatMap,
    formik,
    element.field_value_type,
    formValues,
    render,
  ]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(fieldRenderedExp); // SYNC and ASYNC

      addFieldValidation({
        name,
        evaledExp,
        required,
        touched: false,
        helpertext,
        validationType,
        validationScheme,
        validationMessage,
        t,
        formik,
        setFieldValidation,
        setFieldHelper,
        formFlatMap: formValues,
        globalValueObj,
        yup,
        element,
        duplicateIds,
      });

      // ToDo
      if (!evaledExp && !readOnly) {
        setInputValue("");
      }

      setShouldRender(evaledExp);
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldRenderedExp, name, render, formik.errors]);

  useEffect(() => {
    if (inputEvent) {
      if (timeout) window.clearTimeout(timeout);

      const fieldValue = inputValue;

      // main element
      formik.values[name] = fieldValue;
      globalValueObj[name] = fieldValue;

      // id attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_id`] = fieldValue?.["id"];
      globalValueObj[`${name}_id`] = fieldValue?.["id"];

      // text attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_text`] = fieldValue?.["text"];
      globalValueObj[`${name}_text`] = fieldValue?.["text"];

      // id attributes
      formik.values[`${name}.id`] = fieldValue?.["id"];
      globalValueObj[`${name}.id`] = fieldValue?.["id"];

      // text attributes
      formik.values[`${name}.text`] = fieldValue?.["text"];
      globalValueObj[`${name}.text`] = fieldValue?.["text"];

      if (fieldValue) {
        delete formik.errors[name];
      }

      timeout = window.setTimeout(() => {
        setRender((value) => value + 1);
      }, 300);
    } else {
      setInputValue(formValues?.[name]);

      const fieldValue = inputValue;

      formik.values[name] = fieldValue;
      globalValueObj[name] = fieldValue;

      // id attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_id`] = fieldValue?.["id"];
      globalValueObj[`${name}_id`] = fieldValue?.["id"];

      // text attributes
      // As per old specifications - this code should get removed once we remove the support from the formspes of MedOnboard and MedLeave
      formik.values[`${name}_text`] = fieldValue?.["text"];
      globalValueObj[`${name}_text`] = fieldValue?.["text"];

      // id attributes
      // As per new specification
      formik.values[`${name}.id`] = fieldValue?.["id"];
      globalValueObj[`${name}.id`] = fieldValue?.["id"];

      // text attributes
      // As per new specification
      formik.values[`${name}.text`] = fieldValue?.["text"];
      globalValueObj[`${name}.text`] = fieldValue?.["text"];

      if (fieldValue) {
        delete formik.errors[name];
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, inputValue, setfieldvalue, inputEvent, value]);

  useEffect(() => {
    setFieldsInText(
      label,
      formValues,
      {
        actionId: actionid,
        formSpec: formspec,
      },
      setText
    );
  }, [label, formValues, actionid, formspec]);

  useEffect(() => {
    if (formik && element) {
      setExp(
        getCalculatedExp(
          element.field_calculation,
          {
            ...formFlatMap,
            ...globalValueObj,
            ...formik.values,
          },
          element.field_value_type
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values, globalValueObj]);

  useEffect(() => {
    try {
      // eslint-disable-next-line no-eval
      const evaledExp = eval(exp);
      const fieldDefaultValue =
        shouldRender && String(formFlatMap?.[name]).trim() === ""
          ? getOptionsDefaultValueMap(options, defaultValue)
          : "";
      const fieldValue = shouldRender
        ? evaledExp || fieldDefaultValue || formFlatMap?.[name]
        : formFlatMap?.[name];

      if (exp || String(fieldValue)) {
        globalValueObj[name] = fieldValue;
        formik.values[name] = fieldValue;
      }
    } catch (err) {
      console.log(name, err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exp, name]);

  useEffect(() => {
    setRender((value) => value + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [required, formik?.values]);

  if (!options.length) return "";

  if (!shouldRender) return null;

  return (
    <MuiGrid item xs={12} id={`field-${name}`}>
      <MuiTypography
        component="span"
        dangerouslySetInnerHTML={{
          __html: sanitize(`${text} ${required ? "*" : ""}`, {
            ALLOWED_TAGS: AllowedTags,
          }),
        }}
        variant="body2"
        sx={StyleApp.links}
      ></MuiTypography>
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <MuiBox
          sx={{
            width: "100%",
          }}
        >
          <MuiFormControl component="fieldset" error={ERROR}>
            <MuiRadioGroup
              aria-label={name}
              id={name}
              name={name}
              onChange={(event, changedValue) => {
                if (typeof defaultValue !== "undefined") {
                  setInputValue(
                    changedValue
                      ? JSON.parse(changedValue)
                      : getOptionsDefaultValueMap(options, defaultValue)
                  );
                } else {
                  setInputValue(JSON.parse(changedValue));
                }
                setInputEvent(event);
                setIsDirty(true);
              }}
            >
              {options.map((item, index) => {
                return (
                  <MuiFormControlLabel
                    control={
                      <MuiRadio
                        id={`form-radio-${index}`}
                        value={JSON.stringify({ id: item.id, text: item.text })}
                        color="primary"
                        checked={Boolean(
                          inputValue && inputValue.id === item.id
                        )}
                        disabled={readOnly}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: "22.5px" } }}
                      />
                    }
                    sx={StyleApp.disabledRadio}
                    label={
                      <MuiTypography
                        variant="body2"
                        component="span"
                        color={FIELD_TEXT_COLOR}
                      >
                        {item.text}
                      </MuiTypography>
                    }
                    key={index}
                  />
                );
              })}
            </MuiRadioGroup>
          </MuiFormControl>
        </MuiBox>
        {element && Boolean(element.field_info.trim()) ? (
          <MuiTooltip title={t("globals.fieldInfo.tooltip")}>
            <InfoIcon
              onClick={() =>
                setInfoDialog({
                  show: true,
                  fieldLabel: label,
                  fieldContent: element.field_info,
                })
              }
              sx={{
                cursor: "pointer",
                fontSize: "18.75px",
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "21px" }}></span>
        )}
        {Boolean(links) ? (
          <MuiTooltip title={t("globals.fieldHistory.tooltip")}>
            <HistoryIcon
              color={iconColor}
              onClick={() =>
                setScrollDialog({
                  show: true,
                  links: links,
                  fieldLabel: label,
                })
              }
              sx={{
                marginLeft: "5px",
                fontSize: iconSize,
              }}
            />
          </MuiTooltip>
        ) : (
          <span style={{ width: "1.5em" }}></span>
        )}
      </div>
      {fieldHelper && (
        <MuiFormHelperText component="div">
          <MuiTypography
            variant="subtitle2"
            component="span"
            color={HELPER_TEXT_COLOR}
            sx={{ fontSize: "13.125px" }}
          >
            {fieldHelper}
          </MuiTypography>
        </MuiFormHelperText>
      )}
    </MuiGrid>
  );
});

const getOptionsDefaultValueMap = (options, defaultValue) =>
  Array.isArray(options)
    ? options.find((item) => item.id === defaultValue)
    : "";
